import React from 'react';
import { Outlet } from 'react-router-dom';

import Loading from '../../components/Loading';
import RedirectToPath from '../../components/Navigation/redirect-to-dashboard';
import { DASHBOARD } from '../../utils/internal-routes.const';

import useAuth from './useAuth';

const PublicRoutes: React.FC = () => {
  const { isLoggedInFromApi, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return isLoggedInFromApi ? <RedirectToPath path={DASHBOARD} /> : <Outlet />;
};

export default PublicRoutes;
