import React, { ReactNode } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface CommonButtonProps extends ButtonProps {
  children: ReactNode;
}

export const KeyOpsPrimaryButton: React.FC<CommonButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      variant={'solid'}
      colorScheme={'blue'}
      backgroundColor={'keyops.blue'}
      color={'white'}
      _hover={{
        bgColor: '#285ea7',
        color: 'white',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const KeyOpsSecondaryButton: React.FC<CommonButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      variant={'outline'}
      colorScheme={'blue'}
      color="keyops.blue"
      borderColor="keyops.blue"
      _hover={{ bgColor: 'keyops.blue', color: 'white' }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const KeyOpsLinkButton: React.FC<CommonButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      variant={'link'}
      colorScheme={'blue'}
      color={'keyops.blue'}
      {...props}
    >
      {children}
    </Button>
  );
};

export const KeyOpsGhostButton: React.FC<CommonButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      variant={'ghost'}
      colorScheme={'blue'}
      color={'keyops.blue'}
      _hover={{ backgroundColor: 'gray.100' }}
      {...props}
    >
      {children}
    </Button>
  );
};
