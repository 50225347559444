import { Chart, ChartDataset, ChartOptions } from 'chart.js';
import { Plugin } from 'chart.js/auto';

import { CHART_COLORS } from '../../../utils/constants/visualization-constants';
import { Counts, distributionAverage } from '../../../utils/functions/stats';

import { ChartData } from '.';

interface DataPoint {
  x: number;
  y: number;
  tooltipPosition(useFinalPosition?: boolean): { x: number; y: number };
}
export const doughnutOutsideLabels = (data: Array<ChartData>) => {
  return {
    id: 'doughnutOutsideLabels',
    afterDraw(chart: Chart, args: unknown, options: ChartOptions) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;
      type position = { x: number; y: number };
      const textPositions: position[] = [];
      chart.data.datasets.forEach((dataset: ChartDataset, i: number) => {
        chart
          .getDatasetMeta(i)
          .data.forEach((dataPoint: DataPoint, index: number) => {
            const { x, y } = dataPoint.tooltipPosition(true);
            ctx.fillStyle = CHART_COLORS[index];
            ctx.fillRect(x, y, 2, 2);
            // draw line
            const halfWidth = width / 2 + 20; //we have to add 20 here to account for padding.
            const halfHeight = height / 2;
            const xLine = x >= halfWidth ? x + 20 : x - 20;
            const extraLine = x >= halfWidth ? 20 : -20;

            const yLine = (() => {
              const initialYLine = y >= halfHeight ? y + 10 : y - 10;

              const conflictingPosition = textPositions.find(
                (position) =>
                  Math.abs(position.x - xLine) < 30 &&
                  Math.abs(position.y - initialYLine) < 30
              );
              if (!conflictingPosition) return initialYLine;
              if (conflictingPosition.y < initialYLine) {
                return initialYLine + 12;
              }
              return initialYLine - 12;
            })();

            // line
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(xLine, yLine);
            ctx.lineTo(xLine + extraLine, yLine);
            ctx.strokeStyle = CHART_COLORS[index];
            ctx.stroke();
            // text
            ctx.font = '12px arial';
            const textAlign = x >= halfWidth ? 'left' : 'right';
            const extraSpace = x >= halfWidth ? 25 : -25;
            ctx.textAlign = textAlign;
            ctx.textBaseline = 'middle';
            ctx.fillStyle = CHART_COLORS[index];
            ctx.fillText(
              Math.round(data[index].value) + '%',
              xLine + extraSpace,
              yLine
            );
            ctx.font = '9px arial';
            ctx.fillStyle = '#4A5568';
            ctx.fillText(data[index].label, xLine + extraSpace, yLine + 11);
            textPositions.push({ x: xLine, y: yLine });
          });
      });
    },
  };
};

export type MarkerPluginType = Plugin<'bar'> & {
  beforeDatasetsDraw?: (chart: Chart<'bar'>) => void;
};

const drawMarker = (
  ctx: CanvasRenderingContext2D,
  xValue: number,
  top: number
) => {
  const lineWidth = 10;
  const lineX = xValue - lineWidth / 2;
  const lineY = top - 20;
  ctx.strokeStyle = 'grey';
  ctx.lineWidth = 1.2;
  ctx.beginPath();
  ctx.moveTo(lineX, lineY);
  ctx.lineTo(lineX, lineY + lineWidth);
  ctx.stroke();
};

const addTextWithTooltip = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  xValue: number,
  top: number,
  avgType: 'CURRENT' | 'PREVIOUS',
  text: string,
  prevAvgDescription: string | null
) => {
  const label = avgType === 'CURRENT' ? 'Avg' : 'Prev. avg';
  const displayText = `${label}: ${text}`;

  // Define text position
  const textX = xValue - (avgType === 'CURRENT' ? 25 : 40);
  const textY = top - (avgType === 'CURRENT' ? 30 : 46);

  // Draw text
  ctx.font = '11px sans-serif';
  ctx.fillStyle = 'grey';
  ctx.fillText(displayText, textX, textY, 1000);

  // Function to check if mouse is over text
  const isMouseOverText = (event: MouseEvent) => {
    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    return (
      mouseX >= textX &&
      mouseX <= textX + ctx.measureText(displayText).width &&
      mouseY >= textY - 12 &&
      mouseY <= textY + 4
    );
  };

  // Event listeners to show tooltip on hover
  canvas.addEventListener('mousemove', (event) => {
    const tooltipElement = document.getElementById('tooltip');
    if (
      avgType !== 'CURRENT' &&
      isMouseOverText(event) &&
      tooltipElement?.style.display !== 'block'
    ) {
      // Show tooltip
      showTooltip(text, event.clientX, event.clientY, prevAvgDescription);
    }
  });

  canvas.addEventListener('mouseleave', () => {
    // Hide tooltip when mouse leaves text area
    hideTooltip();
  });
};

// Function to show tooltip
const showTooltip = (
  text: string,
  x: number,
  y: number,
  prevAvgDescription: string | null
) => {
  let tooltipElement = document.getElementById('tooltip');
  const survey = String(prevAvgDescription)?.split('\n')[0]?.split(':')[1];
  const date = new Date(
    String(prevAvgDescription)?.split('\n')[1]?.split(':')[1]
  ).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
  });

  if (!tooltipElement) {
    // Create tooltip container
    tooltipElement = document.createElement('div');
    tooltipElement.id = 'tooltip';
    tooltipElement.style.position = 'fixed';
    tooltipElement.style.display = 'none';
    tooltipElement.style.backgroundColor = '#3C3A3AD9';
    tooltipElement.style.font = '12px sans-serif';
    tooltipElement.style.color = 'white';
    tooltipElement.style.padding = '8px';
    tooltipElement.style.borderRadius = '4px';
    tooltipElement.style.zIndex = '9999';
    const htmlElement = document.createElement('div');
    htmlElement.innerHTML = `<p>
      Previous average: ${text}
      <br />
      ${survey ? 'Survey: ' + survey : ''}
      <br />
      ${date ? 'Date: ' + date : ''}
    </p>`;
    tooltipElement.appendChild(htmlElement);

    document.body.appendChild(tooltipElement);
  }
  tooltipElement.style.display = 'block';
  tooltipElement.style.top = `${y}px`;
  tooltipElement.style.left = `${x}px`;
};

// Function to hide tooltip
const hideTooltip = () => {
  const tooltipElement = document.getElementById('tooltip');
  if (tooltipElement) {
    tooltipElement.style.display = 'none';
  }
};

const createMarkerPlugin = (
  avgType: 'CURRENT' | 'PREVIOUS',
  value: number,
  prevAvgDescription: string | null,
  rangeValue?: number
): MarkerPluginType => {
  const label = avgType === 'CURRENT' ? 'Avg' : 'Prev. avg';
  return {
    id: label,
    beforeDatasetsDraw(chart: Chart<'bar'>) {
      const {
        canvas,
        ctx,
        chartArea: { top },
        scales: { x },
      } = chart;
      ctx.save();

      drawMarker(
        ctx,
        x.getPixelForValue(rangeValue ? value / rangeValue - 0.5 : value),
        top
      );
      addTextWithTooltip(
        canvas,
        ctx,
        x.getPixelForValue(rangeValue ? value / rangeValue - 0.5 : value),
        top,
        avgType,
        value.toFixed(2),
        prevAvgDescription
      );

      ctx.restore();
    },
  };
};

export const prevAverageMarkerPlugin = (
  prevAvgValue: string | number | boolean,
  prevAvgDescription: string,
  rangeValue?: number
): MarkerPluginType => {
  const parsedPrevAvgValue = () => {
    if (typeof prevAvgValue === 'string') return parseFloat(prevAvgValue);
    else if (typeof prevAvgValue === 'boolean') return undefined;
    else return prevAvgValue;
  };
  const prevAvg = parsedPrevAvgValue();

  if (prevAvg === undefined || isNaN(prevAvg) || prevAvg === 0) {
    // don't draw average marker if there is no avgValue
    return { id: 'prevAverageMarker' };
  }
  return createMarkerPlugin(
    'PREVIOUS',
    prevAvg,
    prevAvgDescription,
    rangeValue
  );
};

export const averageMarkerPlugin = (
  counts: Counts,
  rangeValue?: number
): MarkerPluginType => {
  const totalAnswered = Object.values(counts).reduce((a, b) => a + b, 0);
  if (totalAnswered === 0) {
    // don't draw average marker if there are no answers
    return { id: 'averageMarker' };
  }
  const avgValue = distributionAverage(counts);
  return createMarkerPlugin('CURRENT', avgValue, null, rangeValue);
};
