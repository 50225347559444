import { createContext } from 'react';

type AuthContextTypes = {
  isLoggedInFromApi: boolean;
  isAzureAuthenticated: boolean;
  loading: boolean;
  handleAzureLogin: () => void;
  handleAzureSignup: (signupToken: string) => void;
  handleAzureLogout: () => void;
  logoutFromApi: () => void;
  handleGoogleLogin: (idToken?: string) => void;
  handleEmailPasswordSignup: (signupToken: string, password: string) => void;
  handleEmailPasswordLogin: (email: string, password: string) => void;
  isGoogleAuthenticated: boolean;
};

const AuthContext = createContext<AuthContextTypes>({
  isLoggedInFromApi: false,
  isAzureAuthenticated: false,
  loading: true,
  handleAzureLogin: () => undefined,
  handleAzureSignup: (signupToken: string) => undefined,
  handleAzureLogout: () => undefined,
  logoutFromApi: () => undefined,
  handleGoogleLogin: (idToken?: string) => undefined,
  handleEmailPasswordSignup: (signupToken: string, password: string) =>
    undefined,
  handleEmailPasswordLogin: (email: string, password: string) => undefined,
  isGoogleAuthenticated: false,
});

export default AuthContext;
