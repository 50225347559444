import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';

export const useEngagements = () => {
  return useQuery({
    queryKey: ['use-engagements'],
    queryFn: async () => {
      const response =
        await apiClient.engagementsApi.engagementControllerFindAll();
      return response.data;
    },
  });
};
