export const CHART_COLORS = [
  '#38B2AC',
  '#302B70',
  '#3182CE',
  '#606680',
  '#00B5D8',
  '#805AD5',
  '#ED8936',
  '#ECC94B',
  '#48BB78',
  '#D53F8C',
  '#2F855A',
  '#DD6B20',
  '#F6E05E',
  '#B7791F',
  '#4FD1C5',
  '#CBD5E0',
  '#9F7AEA',
];

export const CHART_COLORS_WITH_ALPHA = [
  '#38B2AC80',
  '#302B7080',
  '#3182CE80',
  '#60668080',
  '#00B5D880',
  '#805AD580',
  '#ED893680',
  '#ECC94B80',
  '#48BB7880',
  '#D53F8C80',
  '#2F855A80',
  '#DD6B2080',
  '#F6E05E80',
  '#B7791F80',
  '#4FD1C580',
  '#CBD5E080',
  '#9F7AEA80',
];

// TODO, we will want this shared between the api and client
export const VISUALIZATION_TYPES = {
  MATRIX: 'Matrix',
  HISTOGRAM: 'Histogram',
  HORIZONTAL_HISTOGRAM: 'HorizontalHistogram',
  LIST_OF_RESPONSES: 'ListOfResponses',
  TEXT_GRID: 'TextGrid',
  DISTRIBUTION: 'Distribution',
  AVERAGE_RANK_FOR_EACH: 'AverageRankForEach',
  HORIZONTAL_BAR_GRAPH_OF_AVERAGES: 'HorizontalBarGraphOfAverages',
  CHARTJSBAR: 'chartJSBar',
};

export const DEMOGRAPHIC_NAMES = {
  practiceSetting: 'Practice Setting',
  specialty: 'Specialty',
  provinceOfPractice: 'Region',
  licenseYear: 'Practice License Year',
  segment: 'Segment',
};
