import React, { lazy, Suspense, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FeatureFlagNameEnum } from '@keyops-cep/api-client';

import Loading from '../../components/Loading';
import useFilterProvider from '../../HOCs/Filter/useFilterProvider';
import useBEFeatureFlag from '../../hooks/useBEFeatureFlag';

import EngagementTitle from './components/EngagementTitle';

const EngagementDetailsTabs = lazy(() => import('./EngagementDetailsTabs'));
const EngagementDetailsResults = lazy(() => import('./pages/Results'));

const EngagementDetailsHome = () => {
  const { engagementDetails } = useFilterProvider();
  const { data: isComparisonFlagActive, isLoading: isFlagDataLoading } =
    useBEFeatureFlag(
      engagementDetails.tenantId,
      FeatureFlagNameEnum.CustomerShowComparison
    );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const container = document.getElementById('container');
    const originalMaxWidth = container?.style.maxWidth ?? '';
    if (container) {
      container.style.maxWidth = '1600px';
    }

    //return function for unhooking
    return () => {
      if (container) {
        container.style.maxWidth = originalMaxWidth;
      }
    };
  }, []);

  return (
    <Box id="engagement-details-home">
      <EngagementTitle />
      <Suspense fallback={<Loading />}>
        {isFlagDataLoading && <Loading />}
        {isComparisonFlagActive && !isFlagDataLoading ? (
          <EngagementDetailsTabs />
        ) : (
          <Box my={8}>
            <EngagementDetailsResults />
          </Box>
        )}
      </Suspense>
    </Box>
  );
};

export default EngagementDetailsHome;
