import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';

export const useComparableQuestions = (
  comparableEngagementsId: string | undefined
) => {
  const {
    isLoading,
    isError,
    data: comparableQuestions,
  } = useQuery({
    queryKey: [
      'comparableQuestionsByComparableEngagementsId',
      comparableEngagementsId,
    ],
    queryFn: async () => {
      if (comparableEngagementsId) {
        const response =
          await apiClient.comparableEngagementsApi.comparableEngagementsControllerGetComparableQuestions(
            comparableEngagementsId
          );
        return response.data;
      }
    },
  });

  return { comparableQuestions, isLoading, isError };
};
