import React from 'react';
import { IconType } from 'react-icons';
import { NavLink, useMatch } from 'react-router-dom';
import {
  Box,
  Center,
  Flex,
  FlexProps,
  Icon,
  Link,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

interface NavItemProps extends FlexProps {
  name: string;
  icon: IconType;
  path: string;
  hidden?: boolean;
  isOpen: boolean;
}
const NavItem = ({ icon, name, path, hidden, isOpen }: NavItemProps) => {
  const match = useMatch(`${path}/*`);
  const selectedLinkStyles = {
    bg: useColorModeValue('gray.100', 'gray.700'),
    fontWeight: 'semibold',
  };

  const linkColor = useColorModeValue('blue.700', 'white');

  if (hidden) return null;

  return (
    <Link
      as={NavLink}
      to={path ?? '#'}
      color={linkColor}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Tooltip
        hasArrow
        aria-label={name}
        label={name}
        placement="right"
        isDisabled={isOpen}
      >
        <Flex
          py={2.5}
          my={3}
          borderRadius={'lg'}
          role={'group'}
          cursor={'pointer'}
          _hover={{
            bg: 'gray.200',
          }}
          sx={match ? selectedLinkStyles : {}}
        >
          <Center w={isOpen ? '25%' : '100%'}>
            {icon && <Icon fontSize={18} as={icon} />}
          </Center>

          {isOpen && (
            <Box w={'75%'} ml={3}>
              {name}
            </Box>
          )}
        </Flex>
      </Tooltip>
    </Link>
  );
};

export default NavItem;
