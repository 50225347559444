import { Box, Heading, Text, VStack } from '@chakra-ui/react';

import TargetSvg from '../../../../../assets/images/TargetSvg';
import i18n from '../../../../../utils/i18n';

const NoSelectedComparableEng = ({
  currentEngagementName,
}: {
  currentEngagementName: string;
}) => {
  return (
    <VStack spacing={4} mt={8} textAlign={'center'}>
      <TargetSvg />
      <Box>
        <Heading as={'h2'} size={'md'} fontWeight={500}>
          {i18n.t(
            'engagementDetails.comparativeAnalysis.noSelectedComparableEng.emptyStateTitle'
          )}
        </Heading>
        <Text mt={2}>
          {i18n.t(
            'engagementDetails.comparativeAnalysis.noSelectedComparableEng.emptyStateSubTitle',
            {
              engagementName: currentEngagementName,
            }
          )}
        </Text>
      </Box>
    </VStack>
  );
};

export default NoSelectedComparableEng;
