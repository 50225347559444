import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

import Sidebar from './components/Sidebar';

const Layout = () => {
  const defaultDrawerOpenState = useBreakpointValue(
    {
      base: false,
      lg: true,
    },
    { ssr: false }
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
    !!defaultDrawerOpenState
  );
  const toggleSidebar = () => setIsSidebarOpen((prevValue) => !prevValue);
  return (
    <Box
      data-testid={'layout-component'}
      minH={'100vh'}
      bg={useColorModeValue('white', 'gray.900')}
    >
      <Box id="container">
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <Box
          ml={isSidebarOpen ? 60 : 20}
          pl={6}
          pr={14}
          py={12}
          transition={'all 0.2s ease'}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
