import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Center,
  CircularProgress,
  Heading,
  HStack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import ThemeButton from '../../components/ThemeButton';
import useAuth from '../../HOCs/auth/useAuth';
import useComponentFlag from '../../hooks/useComponentFlag';
import { COMPONENTS } from '../../utils/componentDisplayFlags';
import { TEST_PAGE } from '../../utils/internal-routes.const';
import Engagements from '../Engagements';

const Dashboard: React.FC = () => {
  const { logoutFromApi } = useAuth();
  const navigate = useNavigate();

  const toTestComp = () => {
    navigate(TEST_PAGE);
  };

  const showDashboard = useComponentFlag(COMPONENTS.DASHBOARD);

  if (!showDashboard) return <Engagements />;

  return (
    <>
      <Heading as={'h4'} size="lg">
        Engagements Dashboard
      </Heading>
      <Card variant={'elevated'} mt={10} px={10} py={5}>
        <HStack spacing={10}>
          <Box>
            <CircularProgress color="teal" value={50} size="120px" />
          </Box>
          <Box flexGrow={1}>
            <HStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              flex={1}
            >
              <VStack flexGrow={1}>
                <Text lineHeight={'24px'} fontSize={'20px'} fontWeight={700}>
                  1
                </Text>
                <Text lineHeight={'20px'} fontSize={'14px'}>
                  In progress
                </Text>
              </VStack>
              <VStack flexGrow={1}>
                <Text lineHeight={'24px'} fontSize={'20px'} fontWeight={700}>
                  1
                </Text>
                <Text lineHeight={'20px'} fontSize={'14px'}>
                  Complete
                </Text>
              </VStack>
              <VStack flexGrow={1}>
                <Text lineHeight={'24px'} fontSize={'20px'} fontWeight={700}>
                  1
                </Text>
                <Text lineHeight={'20px'} fontSize={'14px'}>
                  Not started
                </Text>
              </VStack>
            </HStack>
          </Box>
        </HStack>
      </Card>
      <br />
      <br />
      <Center>
        <HStack>
          <ThemeButton />
          <Button colorScheme="blue" onClick={toTestComp}>
            To Test Page
          </Button>
          <Button colorScheme="blue" onClick={logoutFromApi}>
            Log out
          </Button>
        </HStack>
      </Center>
    </>
  );
};

export default Dashboard;
