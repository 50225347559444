import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ComparableEngagements } from '@keyops-cep/api-client';

import useFilterProvider from '../../../../../HOCs/Filter/useFilterProvider';
import { ANALYTICS_EVENT_NAME } from '../../../../../utils/constants/analytics-constants';
import { getUrl } from '../../../../../utils/functions/common-utils';
import i18n from '../../../../../utils/i18n';
import { ENGAGEMENT_COMPARATIVE_ANALYSIS_OVERVIEW } from '../../../../../utils/internal-routes.const';

const SelectComparableEngDropdown = ({
  comparableEngagementsList,
  selectedEngagementId,
}: {
  comparableEngagementsList: Array<ComparableEngagements>;
  selectedEngagementId: string | undefined;
}) => {
  const { engagementDetails } = useFilterProvider();
  const navigate = useNavigate();

  const selectedComparableEngagement = comparableEngagementsList
    .find((com_eng) => com_eng.id === selectedEngagementId)
    ?.engagements.find((eng) => eng.id !== engagementDetails.id)?.name;

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const target = e.currentTarget;
    const selectedId = target.getAttribute('value');
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGEMENTS_COMPARISON
        .COMPARABLE_ENGAGEMENTS_DROPDOWN,
      { comparableEngagementsId: selectedId }
    );
    if (selectedId) {
      navigate(
        getUrl(ENGAGEMENT_COMPARATIVE_ANALYSIS_OVERVIEW, {
          comparableEngagementsId: selectedId,
        })
      );
    }
  };

  return (
    <Flex alignItems={'center'} gap={1}>
      <Text fontSize={'2xl'} display={'inline'}>
        {i18n.t(
          'engagementDetails.comparativeAnalysis.selectComparableEngDropdown.compareTo',
          {
            engagementName: engagementDetails.name,
          }
        )}
      </Text>
      <Menu>
        <MenuButton
          data-testid={'comparable-engagement-list'}
          as={Button}
          variant={'ghost'}
          px={1}
          rightIcon={<ChevronDownIcon fontSize={24} />}
        >
          <Text fontSize={'2xl'} fontWeight={'normal'}>
            {selectedComparableEngagement ??
              i18n.t(
                'engagementDetails.comparativeAnalysis.selectComparableEngDropdown.selectEngagement'
              )}
          </Text>
        </MenuButton>
        <MenuList>
          {comparableEngagementsList.map((com_eng) => {
            const engagement = com_eng.engagements.find(
              (eng) => eng.id !== engagementDetails.id
            );
            return (
              engagement && (
                <MenuItem
                  key={com_eng.id}
                  data-testid={`comparison-dropdown-menuitem-${com_eng.id}`}
                  display={'block'}
                  value={com_eng.id}
                  onClick={onClick}
                >
                  <Text>{engagement.name}</Text>
                  <Text fontSize={'xs'}>{engagement.displayTitle}</Text>
                </MenuItem>
              )
            );
          })}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default SelectComparableEngDropdown;
