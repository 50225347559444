import { Trans } from 'react-i18next';
import { FiCheckCircle } from 'react-icons/fi';
import {
  Box,
  Container,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';

import BulbSvg from '../../../../../assets/images/BulbSvg';
import useFilterProvider from '../../../../../HOCs/Filter/useFilterProvider';
import { ANALYTICS_EVENT_NAME } from '../../../../../utils/constants/analytics-constants';
import { surveyInquiryEmail } from '../../../../../utils/constants/common-constants';
import i18n from '../../../../../utils/i18n';

const NoComparableEng = () => {
  const { engagementDetails } = useFilterProvider();
  const emailSubjectText = encodeURIComponent(
    i18n.t(
      'engagementDetails.comparativeAnalysis.noComparableEng.emailSubject',
      { engagementDetailsName: engagementDetails.name }
    )
  );
  const emailBodyText = encodeURIComponent(
    i18n.t('engagementDetails.comparativeAnalysis.noComparableEng.emailBody', {
      engagementDetailsName: engagementDetails.name,
    })
  );

  return (
    <Box
      border={'1px'}
      borderTopRadius={8}
      borderColor={'gray.200'}
      mt={'70px'}
      pb={10}
    >
      <VStack spacing={8} mt={'-40px'}>
        <Container
          maxW={'4xl'}
          p={6}
          bg={'blue.50'}
          borderTopRightRadius={16}
          borderBottomLeftRadius={16}
          centerContent
          textAlign={'center'}
        >
          <VStack maxW="lg" spacing={4}>
            <BulbSvg />
            <Heading
              as={'h2'}
              fontSize={32}
              lineHeight={10}
              data-testid="title"
            >
              {i18n.t(
                'engagementDetails.comparativeAnalysis.noComparableEng.title'
              )}
            </Heading>
            <Text maxW={'md'} data-testid="subTitle">
              {i18n.t(
                'engagementDetails.comparativeAnalysis.noComparableEng.subTitle'
              )}
            </Text>
          </VStack>
        </Container>
        <Container maxW={'4xl'} px={8}>
          <Box>
            <VStack spacing={8}>
              <Text textAlign={'center'} data-testid="bulletPointsTitle">
                {i18n.t(
                  'engagementDetails.comparativeAnalysis.noComparableEng.bulletPointsTitle'
                )}
              </Text>
              <List maxW={'md'} spacing={6} data-testid="bulletPointsList">
                {i18n
                  .t(
                    'engagementDetails.comparativeAnalysis.noComparableEng.bulletPoints',
                    { returnObjects: true }
                  )
                  .map((point, i) => (
                    <ListItem key={`point-${i}`} display={'flex'}>
                      <ListIcon
                        data-testid={`bullet-point-${i}-icon`}
                        fontSize={24}
                        as={FiCheckCircle}
                        color={'blue.700'}
                      />
                      {point}
                    </ListItem>
                  ))}
              </List>
            </VStack>
          </Box>
          <Text textAlign={'center'} mt={12} data-testid="askUs">
            <Trans
              defaults={i18n.t(
                'engagementDetails.comparativeAnalysis.noComparableEng.askUs'
              )}
              components={{
                linkTag: (
                  <Link
                    href={`mailto:${surveyInquiryEmail}?subject=${emailSubjectText}&body=${emailBodyText}`} // add event here
                    onClick={() =>
                      window.analytics.track(
                        ANALYTICS_EVENT_NAME.ENGAGEMENTS_COMPARISON
                          .NO_COMPARABLE_ENGAGEMENTS
                      )
                    }
                    color={'keyops.blue'}
                    _hover={{ textDecoration: 'none' }}
                  />
                ),
              }}
            />
          </Text>
        </Container>
      </VStack>
    </Box>
  );
};

export default NoComparableEng;
