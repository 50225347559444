import { useQuery } from '@tanstack/react-query';

import { getUserProfile } from '../api/user.functions';

const useUserProfileData = () => {
  return useQuery({
    queryKey: ['user-data'],
    queryFn: async () => await getUserProfile(),
  });
};

export default useUserProfileData;
