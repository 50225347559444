import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';

import { forgotPasswordApiCall } from '../../api/auth.functions';
import { customerSupportEmail } from '../../utils/constants/common-constants';
import i18n from '../../utils/i18n';
import { LOGIN } from '../../utils/internal-routes.const';

const ForgotPassword: React.FC = () => {
  const [localEmail, setLocalEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalEmail(event.target.value);
  };

  const handleForgotPassword = (email: string) => {
    forgotPasswordApiCall({
      email,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setEmailSubmitted(true);
    handleForgotPassword(localEmail);
  };

  return (
    <Box>
      <Text fontSize="xl" mt={6}>
        {i18n.t('forgotPassword.title')}
      </Text>
      <Text fontSize="md" mt={6}>
        {!emailSubmitted
          ? i18n.t('forgotPassword.info')
          : i18n.t('forgotPassword.emailSubmitted.info')}
      </Text>
      <Text fontSize="md" mt={4}>
        {i18n.t('forgotPassword.info2')}
        <Link
          href={`mailto:${customerSupportEmail}`}
          color="keyops.blue"
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18n.t('publicRouteLayout.contactUs')}
        </Link>
        .
      </Text>
      {!emailSubmitted && (
        <Box
          mt={4}
          w={{ base: '100%', lg: '50%' }}
          as="form"
          onSubmit={handleSubmit}
        >
          <FormControl>
            <FormLabel>{i18n.t('forgotPassword.emailLabel')}</FormLabel>
            <Input
              aria-label="email-label"
              size={'sm'}
              type="email"
              value={localEmail}
              onChange={handleEmailChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" mt={4}>
            {i18n.t('forgotPassword.resetPassword')}
          </Button>
        </Box>
      )}
      <Box mt={10}>
        <Link href={LOGIN} color="keyops.blue" rel="noopener noreferrer">
          {i18n.t('forgotPassword.backToLogin')}
        </Link>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
