// External Dependencies
import React from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Heading, Stack, Text } from '@chakra-ui/react';

import i18n from '../../../../utils/i18n';

// Internal Dependencies

export const ChatHeaderFAQ = ({
  handleReturnToMessages,
  scrollPosition,
}: {
  handleReturnToMessages: () => void;
  scrollPosition: number;
}) => {
  return (
    <Stack
      data-testid="engage-ai-chat-header-faq"
      direction={'column'}
      justify={'space-between'}
      align={'flex-start'}
      textAlign={'left'}
      background={'white'}
      borderRadius="8px 8px 0px 0px"
    >
      {/* Display different headers depending on where the customer scrolls */}
      {scrollPosition < 10 ? (
        <Stack p="16px" pb="0">
          <ChevronLeftIcon
            color={'keyops.blue'}
            w={'24px'}
            h={'24px'}
            onClick={handleReturnToMessages}
            data-testid="engage-ai-chat-header-faq-chevron-back"
          />
          <Stack padding="0px 20px;">
            <Heading
              variant={'h2'}
              color="#1A242B"
              fontSize={'20px'}
              fontWeight={500}
              lineHeight={'32px'}
            >
              {i18n.t('engagementDetails.engageAi.faq.title')}
            </Heading>

            {
              <Text pb={'24px'} fontSize={'14px'}>
                {i18n.t('engagementDetails.engageAi.faq.subtitle')}
              </Text>
            }
          </Stack>
        </Stack>
      ) : (
        <Stack
          justify={'flex-start'}
          align={'center'}
          direction={'row'}
          p={'24px 24px 16px 24px '}
          w={'100%'}
          borderBottom="1px solid  #EDF2F7"
        >
          <ChevronLeftIcon
            color={'keyops.blue'}
            w={'24px'}
            h={'24px'}
            onClick={handleReturnToMessages}
          />
          <Heading
            variant={'h2'}
            color="#1A242B"
            fontSize={'16px'}
            fontWeight={400}
            lineHeight={'24px'}
          >
            {i18n.t('engagementDetails.engageAi.faq.title')}
          </Heading>
        </Stack>
      )}
    </Stack>
  );
};
