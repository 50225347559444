import React, { memo, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  CloseButton,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Link,
  Text,
  useColorModeValue,
  Wrap,
} from '@chakra-ui/react';
// TODO: why do we need lodash?
import { isEqual } from 'lodash';

import {
  KeyOpsPrimaryButton,
  KeyOpsSecondaryButton,
} from '../../../components/ButtonComponents';
import {
  FilterGroupsType,
  ResponseFilters,
} from '../../../HOCs/Filter/FilterProvider';
import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import i18n from '../../../utils/i18n';

import FilterSection from './FilterSection';

const formatLocalFilters = (localFilters: string[]) =>
  localFilters.map((filter: string) => ({
    type: filter.split('#')[0],
    questionId: filter.split('#')[1],
    option: filter.split('#')[2],
    checked: true,
  }));

interface FiltersDrawerProps {
  isFilterDrawerOpen: boolean;
  toggleFilterDrawer: () => void;
  localFilterGroups: FilterGroupsType[];
  setLocalFilterGroups: (filterGroups: FilterGroupsType[]) => void;
}

const FiltersDrawer = ({
  isFilterDrawerOpen,
  toggleFilterDrawer,
  localFilterGroups,
  setLocalFilterGroups,
}: FiltersDrawerProps) => {
  const {
    filterGroups,
    selectedFilters,
    handleBulkSelectedFilters,
    removeQuestionFilter,
    DEFAULT_FILTER_GROUPS,
  } = useFilterProvider();

  const [localFilters, setLocalFilters] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const initialLocalFilters = selectedFilters.map(
      (filter) => `${filter.type}#${filter.questionId}#${filter.option}`
    );
    setLocalFilters(initialLocalFilters);
    setLocalFilterGroups(filterGroups);
  }, [selectedFilters, isFilterDrawerOpen, setLocalFilterGroups, filterGroups]);

  const handleLocalFilters = (filter: string) => {
    const index = localFilters.indexOf(filter);
    if (index > -1) {
      setLocalFilters(localFilters.filter((_, i) => i !== index));
    } else {
      setLocalFilters([...localFilters, filter]);
    }
  };

  const applyFilters = async () => {
    setLoading(true);
    if (!isEqual(localFilterGroups, filterGroups)) {
      removeQuestionFilter();
    }
    const formattedFilters: ResponseFilters[] =
      formatLocalFilters(localFilters);
    await handleBulkSelectedFilters(formattedFilters);
    setLoading(false);
    toggleFilterDrawer();
  };

  const removeAllFilters = async () => {
    setLocalFilters([]);
  };

  const removeLocalQuestionFilter = () => {
    setLocalFilterGroups(DEFAULT_FILTER_GROUPS);
    if (isEqual(DEFAULT_FILTER_GROUPS, filterGroups)) {
      return;
    }
    const currentFilterGroups = DEFAULT_FILTER_GROUPS.map(
      (filterGroup) => filterGroup.type
    );
    const hasExtraLocalFilter =
      localFilters.filter(
        (filter) => currentFilterGroups.indexOf(filter.split('#')[0]) === -1
      ).length > 0;
    if (hasExtraLocalFilter) {
      const newFilterList = localFilters.filter(
        (filter) => currentFilterGroups.indexOf(filter.split('#')[0]) !== -1
      );
      setLocalFilters(newFilterList);
    } else {
      // TODO: make sure we want to remove global question filter group
      // TODO: when no filter is applied from question filter
      removeQuestionFilter();
    }
  };

  const filterTagBgColors = useColorModeValue('blackAlpha.100', 'gray.700');
  const filterTextColors = useColorModeValue('gray.700', 'white');

  const isApplyBtnDisabled = isEqual(
    formatLocalFilters(localFilters),
    selectedFilters
  );

  return (
    <Drawer
      isOpen={isFilterDrawerOpen}
      placement={'right'}
      size={'sm'}
      onClose={toggleFilterDrawer}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {i18n.t('engagementDetails.filter.filterDrawer.title')}
        </DrawerHeader>
        <DrawerBody>
          <Collapse in={localFilters.length > 0} animateOpacity>
            <Accordion defaultIndex={[0]}>
              <AccordionItem border={'none'}>
                <AccordionButton
                  justifyContent={'space-between'}
                  cursor={'default'}
                  _hover={{
                    backgroundColor: 'none',
                  }}
                >
                  <Text fontWeight={600}>
                    {i18n.t(
                      'engagementDetails.filter.filterDrawer.activeFilters'
                    )}
                  </Text>
                  <Link
                    color={'keyops.blue'}
                    fontWeight={'semibold'}
                    onClick={removeAllFilters}
                  >
                    {i18n.t(
                      'engagementDetails.filter.filterDrawer.clearFilters'
                    )}
                  </Link>
                </AccordionButton>
                <AccordionPanel>
                  <Wrap spacing={2}>
                    {localFilters.map((filter) => (
                      <Box
                        key={filter}
                        display="inline-flex"
                        alignItems="center"
                        borderRadius={'lg'}
                        pl={2}
                        py={1.5}
                        color={filterTextColors}
                        bg={filterTagBgColors}
                        fontSize="md"
                      >
                        <Box ml={2}>{filter.split('#')[2]}</Box>
                        <CloseButton
                          ml={4}
                          onClick={() => handleLocalFilters(filter)}
                        />
                      </Box>
                    ))}
                  </Wrap>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Divider borderColor={'gray.400'} mb={2} />
          </Collapse>
          {localFilterGroups.length === 0 ? (
            <Text color={'gray.600'}>
              {i18n.t('engagementDetails.filter.filterDrawer.noFiltersMessage')}
            </Text>
          ) : (
            <Accordion
              defaultIndex={[...localFilterGroups.map((_, i) => i)]}
              allowMultiple
            >
              {localFilterGroups.map((filter, i) => {
                return (
                  <FilterSection
                    key={`${filter.type}-${i}`}
                    filterType={filter.type}
                    questionId={filter.questionId}
                    filterOptions={filter.options}
                    localFilters={localFilters}
                    handleLocalFilters={handleLocalFilters}
                    removeLocalQuestionFilter={removeLocalQuestionFilter}
                  />
                );
              })}
            </Accordion>
          )}
        </DrawerBody>

        <DrawerFooter>
          <KeyOpsSecondaryButton mr={3} onClick={toggleFilterDrawer}>
            {i18n.t('common.cancel')}
          </KeyOpsSecondaryButton>
          <KeyOpsPrimaryButton
            isLoading={loading}
            isDisabled={isApplyBtnDisabled}
            onClick={applyFilters}
          >
            {i18n.t('engagementDetails.filter.filterDrawer.applyFilters')}
          </KeyOpsPrimaryButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default memo(FiltersDrawer);
