import React, { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

type PasswordFieldProps = {
  label: string;
  value: string;
  helpText?: string;
  errorMessage?: string;
  handleValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PasswordField = ({
  label,
  value,
  helpText,
  errorMessage,
  handleValueChange,
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormControl mt={4}>
      <FormLabel aria-label={`${label}-label`}>{label}</FormLabel>
      <InputGroup size={'sm'}>
        <Input
          aria-label={`${label}-input`}
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={handleValueChange}
          borderColor={errorMessage ? 'crimson' : undefined}
        />
        <InputRightElement>
          <IconButton
            aria-label="hide-password"
            size={'xs'}
            icon={<FiEye />}
            onClick={handleShowPassword}
          />
        </InputRightElement>
      </InputGroup>
      <FormHelperText>{helpText}</FormHelperText>
      <FormHelperText color={'red.500'}>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default PasswordField;
