import React from 'react';
import { useParams } from 'react-router-dom';

import Loading from '../../../../components/Loading';
import MarkdownDisplay from '../../../../components/MarkdownDisplay';
import { useComparableEngagements } from '../../../../hooks/useComparableEngagements';
import i18n from '../../../../utils/i18n';
import NotFound from '../../../NotFound';

const REPORT_NOT_GENERATED = i18n.t(
  'engagementDetails.overview.noReportGenerated'
);

const ComparisonOverview: React.FC = () => {
  const { engagementId, comparableEngagementsId } = useParams();

  const { comparableEngagements, isLoading, isError } =
    useComparableEngagements(comparableEngagementsId);

  if (!engagementId || !comparableEngagementsId || isLoading)
    return <Loading h={'40vh'} />;

  if (isError)
    return (
      <div data-testid="not-found">
        <NotFound />
      </div>
    );

  if (comparableEngagements?.report === null)
    // check if report is null
    return <div data-testid="report-not-generated">{REPORT_NOT_GENERATED}</div>;
  return (
    <div data-testid="report-display">
      <MarkdownDisplay source={comparableEngagements?.report as string} />
    </div>
  );
};
export default ComparisonOverview;
