import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';

export const useComparableEngagements = (
  comparableEngagementsId: string | undefined
) => {
  const {
    isLoading,
    isError,
    data: comparableEngagements,
  } = useQuery({
    queryKey: ['comparableEngagementsById', comparableEngagementsId],
    queryFn: async () => {
      if (comparableEngagementsId) {
        const response =
          await apiClient.comparableEngagementsApi.comparableEngagementsControllerFindOne(
            comparableEngagementsId
          );
        return response.data;
      }
    },
    enabled: !!comparableEngagementsId,
  });

  return {
    comparableEngagements,
    isLoading: comparableEngagementsId ? isLoading : false,
    isError,
  };
};
