import { FiFilter } from 'react-icons/fi';
import { Icon, Text, VStack } from '@chakra-ui/react';

import i18n from '../../utils/i18n';

// Component displayed when the selected filter(s) don't match any questionAnswers
export const NoResultVisualization = () => {
  const [firstPart, secondPart] = i18n
    .t('visualization.noResponses')
    .split('. ');

  return (
    <VStack
      h={'-webkit-fill-available'}
      minH={'30vh'}
      justify={'center'}
      align={'center'}
      data-testid="visualization-no-result"
    >
      <Icon
        sx={{ width: '69px', height: '69px' }}
        aria-label="filter-icon"
        color={'keyops.visualizationBlue'}
        as={FiFilter}
      />
      <Text textAlign={'center'}>
        {firstPart}.<br />
        {secondPart}
      </Text>
    </VStack>
  );
};
