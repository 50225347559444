export const ENGAGEMENT_STATUSES = {
  DRAFT: 'Draft',
  IN_PROGRESS: 'In progress',
  PROCESSING_RESULTS: 'Processing results',
  RAW_DATA_AVAILABLE: 'Raw data available',
  INSIGHTS_AVAILABLE: 'Insights available',
  ADMIN_ONLY: 'Admin only',
} as const;

export const ENGAGEMENT_BADGE_COLOR_SCHEME = {
  [ENGAGEMENT_STATUSES.DRAFT]: 'gray',
  [ENGAGEMENT_STATUSES.IN_PROGRESS]: 'blue',
  [ENGAGEMENT_STATUSES.PROCESSING_RESULTS]: 'orange',
  [ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE]: 'green',
  [ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE]: 'green',
  [ENGAGEMENT_STATUSES.ADMIN_ONLY]: 'red',
} as const;

export type EngagementStatusValues =
  (typeof ENGAGEMENT_STATUSES)[keyof typeof ENGAGEMENT_STATUSES];
