import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { Center, Flex, Heading, Icon, Link, Text } from '@chakra-ui/react';

import { customerSupportEmail } from '../../utils/constants/common-constants';
import i18n from '../../utils/i18n';

const ErrorComponent = () => {
  return (
    <Center aria-label="error-component" h="calc(100vh - 96px)">
      <Flex direction={'column'} align={'center'} maxW={'450px'}>
        <Icon
          data-testid="error-icon"
          as={FiAlertCircle}
          boxSize={16}
          color={'blue.200'}
        />
        <Heading aria-label={'something-went-wrong'} as={'h1'} size="md" mt={4}>
          {i18n.t('errorComponent.title')}
        </Heading>
        <Text aria-label={'try-again'} fontSize={'md'} mt={2}>
          {i18n.t('errorComponent.subTitle')}{' '}
          <Link
            href={`mailto:${customerSupportEmail}`}
            color="keyops.blue"
            target="_blank"
            rel="noopener noreferrer"
          >
            {customerSupportEmail}
          </Link>
          .
        </Text>
      </Flex>
    </Center>
  );
};

export default ErrorComponent;
