import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, Heading, Text } from '@chakra-ui/react';

import i18n from '../../utils/i18n';
import { DASHBOARD } from '../../utils/internal-routes.const';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Card variant="filled" m={4}>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          {i18n.t('notFound.errorCode')}
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          {i18n.t('notFound.title')}
        </Text>
        <Text color={'gray.500'} mb={6}>
          {i18n.t('notFound.subTitle')}
        </Text>
        <Button
          colorScheme="teal"
          bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
          color="white"
          variant="solid"
          onClick={() => navigate(DASHBOARD)}
        >
          {i18n.t('common.goToHome')}
        </Button>
      </Box>
    </Card>
  );
};

export default NotFound;
