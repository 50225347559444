// External Dependencies
import React, { useRef } from 'react';
import { Card, CardBody, Flex, useDisclosure } from '@chakra-ui/react';
import { AggregateQuestion, IndividualQuestion } from '@keyops-cep/api-client';

import useFilterProvider from '../../HOCs/Filter/useFilterProvider';
// Internal Dependencies
import { AnswerDataWithDemographic } from '../../pages/EngagementDetails/types';
import { VISUALIZATION_TYPES } from '../../utils/constants/visualization-constants';

import ChartJS from './ChartJS/';
import AverageRankForEach from './AverageRankForEach';
import Distribution from './Distribution';
import { ExpandedViewModal } from './ExpandedViewModal';
import Histogram from './Histogram';
import HorizontalBarGraph from './HorizontalBarGraph';
import HorizontalHistogram from './HorizontalHistogram';
import ListOfResponses from './ListOfResponses';
import Matrix from './Matrix';
import { NoResultVisualization } from './NoResultVisualization';
import TextGrid from './TextGrid';
import VisualizationCardOptions from './VisualizationCardOptions';

type VisualizationProps = {
  question: IndividualQuestion | AggregateQuestion;
  questionAnswers: AnswerDataWithDemographic[];
  displayPrevAvg?: boolean;
  togglePrevAvgDisplay?: () => void;
  hideFilterBar?: boolean;
  height?: string;
};

export interface VisualizationChartRef {
  onDownload: (name: string) => void;
  onCopyToClipboard: () => void;
}

export function getVisualizationComponent(
  question: IndividualQuestion | AggregateQuestion,
  questionAnswers: AnswerDataWithDemographic[],
  ref: React.RefObject<VisualizationChartRef>,
  maintainAspectRatio?: boolean,
  isExpanded?: boolean,
  displayPrevAvg?: boolean
): { component: JSX.Element } {
  let VisualizationComponent = <></>;

  // When the selected filters have no related questionAnswers
  if (questionAnswers.length === 0) {
    return {
      component: <NoResultVisualization />,
    };
  }

  switch (question.visualization) {
    case VISUALIZATION_TYPES.MATRIX:
      VisualizationComponent = (
        <Matrix
          ref={ref}
          question={question as AggregateQuestion}
          questionAnswers={questionAnswers}
        />
      );
      break;
    case VISUALIZATION_TYPES.HISTOGRAM:
      VisualizationComponent = (
        <Histogram
          ref={ref}
          question={question as IndividualQuestion}
          questionAnswers={questionAnswers}
          displayPrevAvg={displayPrevAvg}
          maintainAspectRatio={maintainAspectRatio}
          isExpanded={isExpanded}
        />
      );
      break;
    case VISUALIZATION_TYPES.HORIZONTAL_HISTOGRAM:
      VisualizationComponent = (
        <HorizontalHistogram
          ref={ref}
          question={question as AggregateQuestion}
          questionAnswers={questionAnswers}
          maintainAspectRatio={maintainAspectRatio}
          isExpanded={isExpanded}
        />
      );
      break;
    case VISUALIZATION_TYPES.DISTRIBUTION:
      VisualizationComponent = (
        <Distribution
          ref={ref}
          question={question as IndividualQuestion}
          questionAnswers={questionAnswers}
          displayPrevAvg={displayPrevAvg}
          maintainAspectRatio={maintainAspectRatio}
          isExpanded={isExpanded}
        />
      );
      break;
    case VISUALIZATION_TYPES.HORIZONTAL_BAR_GRAPH_OF_AVERAGES:
      VisualizationComponent = (
        <HorizontalBarGraph
          ref={ref}
          question={question as AggregateQuestion}
          questionAnswers={questionAnswers}
          maintainAspectRatio={maintainAspectRatio}
          isExpanded={isExpanded}
        />
      );
      break;
    case VISUALIZATION_TYPES.LIST_OF_RESPONSES:
      VisualizationComponent = (
        <ListOfResponses ref={ref} questionAnswers={questionAnswers} />
      );
      break;
    case VISUALIZATION_TYPES.TEXT_GRID:
      VisualizationComponent = (
        <TextGrid
          ref={ref}
          question={question as AggregateQuestion}
          questionAnswers={questionAnswers}
        />
      );
      break;
    case VISUALIZATION_TYPES.AVERAGE_RANK_FOR_EACH:
      VisualizationComponent = (
        <AverageRankForEach ref={ref} questionAnswers={questionAnswers} />
      );
      break;
    //TODO: there is no chartJsBar visualization, but still keeping it here. Will remove it later
    case VISUALIZATION_TYPES.CHARTJSBAR:
      VisualizationComponent = (
        <ChartJS ref={ref} type={question.visualization} />
      );
      break;
    default:
      VisualizationComponent = (
        <ListOfResponses questionAnswers={questionAnswers} />
      );
      break;
  }
  return {
    component: VisualizationComponent,
  };
}

const Visualization = ({
  question,
  questionAnswers,
  displayPrevAvg,
  togglePrevAvgDisplay,
  hideFilterBar,
  height = '100%',
}: VisualizationProps): JSX.Element => {
  const cardVisualizationRef = useRef<VisualizationChartRef>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setBreakoutDemographic } = useFilterProvider();
  const closeButtonRef = useRef(null);

  const onModalClose = () => {
    onClose();
    setBreakoutDemographic(undefined);
  };

  const { component: CardVisualizationComponent } = getVisualizationComponent(
    question,
    questionAnswers,
    cardVisualizationRef,
    false,
    false,
    displayPrevAvg
  );

  return (
    <>
      <Flex h={height}>
        <Card flexGrow={1}>
          <CardBody overflowY={'hidden'}>{CardVisualizationComponent}</CardBody>
        </Card>
        <VisualizationCardOptions
          visualizationRef={cardVisualizationRef}
          question={question}
          onExpand={onOpen}
        />
      </Flex>

      <ExpandedViewModal
        isOpen={isOpen}
        onClose={onModalClose}
        closeButtonRef={closeButtonRef}
        question={question}
        questionAnswers={questionAnswers}
        displayPrevAvg={displayPrevAvg}
        togglePrevAvgDisplay={togglePrevAvgDisplay}
        hideFilterBar={hideFilterBar}
      />
    </>
  );
};

export default Visualization;
