import React from 'react';
import { Outlet } from 'react-router-dom';

import Loading from '../../components/Loading';
import RedirectToPath from '../../components/Navigation/redirect-to-dashboard';
import { LOGIN } from '../../utils/internal-routes.const';

import useAuth from './useAuth';

const PrivateRoutes: React.FC = () => {
  const { isLoggedInFromApi, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return isLoggedInFromApi ? <Outlet /> : <RedirectToPath path={LOGIN} />;
};

export default PrivateRoutes;
