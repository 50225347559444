import React, { useEffect, useState } from 'react';

import { getEnvironmentVariables } from '../../../utils/functions/env-utils';
import useAuth from '../useAuth';

const { REACT_APP_GOOGLE_CLIENT_ID } = getEnvironmentVariables();

declare global {
  interface Window {
    google: typeof google.accounts;
  }
}

// TODO copy paste from admin ui
const GoogleButton: React.FunctionComponent = () => {
  const { handleGoogleLogin } = useAuth();
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const handleCredentialResponse = async (
    response: google.accounts.id.CredentialResponse
  ) => {
    document.cookie =
      'g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    if (response?.credential) {
      handleGoogleLogin(response.credential);
    }
  };

  useEffect(() => {
    const loadGoogle = async () => {
      const script = await document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      script.addEventListener('load', () => setGoogleLoaded(true));
    };
    if (!window.google || !googleLoaded) {
      loadGoogle();
    }
    if (window.google && googleLoaded) {
      window.google.accounts.id.initialize({
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        // eslint-disable-next-line
        document.getElementById('buttonDiv')!,
        {
          shape: 'circle',
          type: 'standard',
          theme: 'filled_blue',
          size: 'large',
          text: 'signin_with',
          width: 400,
        } // customization attributes
      );
    }
  });

  return <div id="buttonDiv"></div>;
};

export default GoogleButton;
