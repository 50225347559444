import { AggregateQuestion, IndividualQuestion } from '@keyops-cep/api-client';
import { ChartDataset } from 'chart.js';

import { horizontalBarGraphCounts } from '../../components/Visualization/HorizontalBarGraph';
import { FilterGroupsType } from '../../HOCs/Filter/FilterProvider';
import { AnswerDataWithDemographic } from '../../pages/EngagementDetails/types';
import {
  CHART_COLORS,
  CHART_COLORS_WITH_ALPHA,
} from '../constants/visualization-constants';

import {
  compressArrayDataSet,
  compressCountsDataSet,
  convertToCountsObject,
  convertToPercentageCountArray,
  Counts,
} from './stats';

const cutOffPoint = 6;

export function getQuestionAnswersForDemographic(
  questionAnswers: AnswerDataWithDemographic[],
  demographicName: string,
  demographicValue: string
) {
  return questionAnswers.filter((questionAnswer) =>
    questionAnswer.demographics.some(
      (demographic) =>
        demographic.name === demographicName &&
        demographic.value === demographicValue
    )
  );
}

export function horizontalBarGraphDatasets(
  breakoutDemographic: string | undefined,
  filterGroups: FilterGroupsType[],
  questionAnswers: AnswerDataWithDemographic[],
  question: AggregateQuestion,
  answerLabels: string[]
) {
  if (!breakoutDemographic) {
    return [];
  }
  const demographicFilterGroup = filterGroups.filter(
    (val) => val.type === breakoutDemographic
  )[0];
  const uniqueDemographicValues = demographicFilterGroup.options;
  let colorIndex = 0;
  const datasets = [];
  for (const demographic of uniqueDemographicValues) {
    const questionAnswersForThisDemographic = getQuestionAnswersForDemographic(
      questionAnswers,
      breakoutDemographic,
      demographic
    );
    // we need to pass in the comprehensive list of answer labels
    // if the subset for the demographic is missing some, the others
    // values could end up mis-assigned
    // order of these also needs to be maintained
    const counts = horizontalBarGraphCounts(
      question,
      questionAnswersForThisDemographic,
      answerLabels
    );

    const dataset = {
      label: demographic,
      data: Object.values(counts),
      backgroundColor: CHART_COLORS_WITH_ALPHA[colorIndex],
      borderColor: CHART_COLORS[colorIndex],
      borderWidth: 2,
    };

    datasets.push(dataset);
    colorIndex++;
  }

  const compressed = compressArrayDataSet(datasets, cutOffPoint);
  return compressed as ChartDataset<'bar', number[]>[];
}

export function getBreakoutDemographicsDatasets(
  breakoutDemographic: string | undefined,
  filterGroups: FilterGroupsType[],
  questionAnswers: AnswerDataWithDemographic[],
  question: IndividualQuestion,
  rangeValue?: number
) {
  if (!breakoutDemographic) {
    return [];
  }
  const demographicFilterGroup = filterGroups.filter(
    (val) => val.type === breakoutDemographic
  )[0];
  const uniqueDemographicValues = demographicFilterGroup.options;
  const datasets = [];
  let colorIndex = 0;
  for (const demographic of uniqueDemographicValues) {
    const questionAnswersForThisDemographic = getQuestionAnswersForDemographic(
      questionAnswers,
      breakoutDemographic,
      demographic
    );
    const { counts: countsForThisDemographic } = convertToCountsObject(
      questionAnswersForThisDemographic,
      question.type,
      rangeValue
    );
    const dataset = {
      label: demographic,
      data: countsForThisDemographic,
      backgroundColor: CHART_COLORS_WITH_ALPHA[colorIndex],
      borderColor: CHART_COLORS[colorIndex],
      borderWidth: 2,
    };
    datasets.push(dataset);
    colorIndex++;
  }
  return compressCountsDataSet(datasets, cutOffPoint) as ChartDataset<
    'bar',
    Counts
  >[];
}

export function getBreakoutDemographicsDatasetsPercentages(
  breakoutDemographic: string | undefined,
  filterGroups: FilterGroupsType[],
  questionAnswers: AnswerDataWithDemographic[],
  question: AggregateQuestion
) {
  if (!breakoutDemographic) {
    return [];
  }
  const demographicFilterGroup = filterGroups.filter(
    (val) => val.type === breakoutDemographic
  )[0];
  const uniqueDemographicValues = demographicFilterGroup.options;
  const datasets = [];
  let colorIndex = 0;
  for (const demographic of uniqueDemographicValues) {
    const questionAnswersForThisDemographic = getQuestionAnswersForDemographic(
      questionAnswers,
      breakoutDemographic,
      demographic
    );
    const countsForThisDemographic = convertToPercentageCountArray(
      questionAnswersForThisDemographic,
      question.choices,
      questionAnswers.length
    );
    const dataset = {
      label: demographic,
      data: countsForThisDemographic.map((count) => count.value),
      backgroundColor: CHART_COLORS_WITH_ALPHA[colorIndex],
      borderColor: CHART_COLORS[colorIndex],
      borderWidth: 2,
    };
    datasets.push(dataset);
    colorIndex++;
  }
  return compressArrayDataSet(datasets, cutOffPoint) as ChartDataset<
    'bar',
    number[]
  >[];
}
