import { useState } from 'react';
import { MessageDto } from '@keyops-cep/api-client';

import { apiClient } from '../api/swagger-codegen-api-config';
import { LoadingThreeDots } from '../pages/EngagementDetails/components/chat/LoadingThreeDots';
import {
  ChatStatus,
  initialAssistantMessages,
  Role,
} from '../utils/constants/engage-ai';
import { isChatStatusError } from '../utils/functions/engage-ai.utils';
import i18n from '../utils/i18n';

export type UseEngageAI = {
  engagementId: string;
};

export const useEngageAI = ({ engagementId }: UseEngageAI) => {
  const [messages, setMessages] = useState<MessageDto[]>(
    initialAssistantMessages
  );
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  /*
   * Start chat session when the page is opened
   * Useful to display or not initial information in the chat
   */
  //   TODO: use isLoading in the chat to inform we fetch the pre-exisiting session
  const [hasInteractedYet, setHasInteractedYet] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const startChatSession = async () => {
    try {
      setIsLoading(true);
      const result =
        await apiClient.engagementsApi.engagementControllerGetEngageAIMessages(
          engagementId
        );
      if (result?.data.length > 0) {
        setHasInteractedYet(true);
        setMessages([
          ...initialAssistantMessages,
          ...result.data,
        ] as MessageDto[]);
      }

      setIsLoading(false);
    } catch (error) {
      setErrorMessage(i18n.t('engagementDetails.engageAi.apiError'));
    } finally {
      setIsLoading(false);
    }
  };

  /*
   * SendEngageAIMessage() sends a message to ChatGPT,
   * retrieve an openai run id, and use it to retrieve the assistant's answer
   */
  const sendEngageAIMessage = async (currentMessage: MessageDto) => {
    if (isSendingMessage) return;
    try {
      setIsSendingMessage(true);
      setErrorMessage(undefined); // Remove the error message every time a new message is sent
      setMessages([
        ...messages,
        currentMessage,
        {
          role: Role.assistant,
          content: <LoadingThreeDots />,
          createdAt: new Date().toDateString(),
        },
      ]);

      const result =
        await apiClient.engagementsApi.engagementControllerSendEngageAIMessage(
          { messageContent: currentMessage.content },
          engagementId
        );

      if (result) {
        setIsCheckingStatus(true);
        setHasInteractedYet(true);

        checkRunStatusAndFetchMessages(engagementId, result?.data);
      }
    } catch (error) {
      console.error(error);
      //  Remove the loading message in case of error
      const messagesWithoutLoading = messages.filter(
        (message) => typeof message.content === 'string'
      );
      setMessages([...messagesWithoutLoading, currentMessage]);
      setErrorMessage(i18n.t('engagementDetails.engageAi.apiError'));
    } finally {
      setIsSendingMessage(false);
    }
  };

  // Check the run status every 2 second and fetch messages when the status is `completed`
  const checkRunStatusAndFetchMessages = async (
    engagementId: string,
    runId: string
  ) => {
    try {
      const checkRunStatusResult =
        await apiClient.engageAiSessionApi.engageAISessionControllerGetRunStatus(
          runId,
          engagementId
        );
      const currentStatus = checkRunStatusResult?.data;

      if (
        currentStatus === ChatStatus.inProgress ||
        currentStatus === ChatStatus.queued
      ) {
        setTimeout(
          () => checkRunStatusAndFetchMessages(engagementId, runId),
          2000
        );
        return;
      }

      if (currentStatus === ChatStatus.completed) {
        setIsCheckingStatus(false);

        const getMessagesResult =
          await apiClient.engagementsApi.engagementControllerGetEngageAIMessages(
            engagementId
          );
        setMessages([
          ...initialAssistantMessages,
          ...getMessagesResult.data,
        ] as MessageDto[]);
      }
      if (isChatStatusError(currentStatus as ChatStatus)) {
        setIsCheckingStatus(false);

        setErrorMessage(i18n.t('engagementDetails.engageAi.chatgptError'));

        //  Remove the loading message in case of error
        const messagesWithoutLoading = messages.filter(
          (message) => typeof message.content === 'string'
        );
        setMessages([...messagesWithoutLoading]);
      }
    } catch (error) {
      setIsCheckingStatus(false);

      setErrorMessage(i18n.t('engagementDetails.engageAi.apiError'));
      const messagesWithoutLoading = messages.filter(
        (message) => typeof message.content === 'string'
      );
      setMessages([...messagesWithoutLoading]);
    }
  };

  return {
    messages,
    hasInteractedYet,
    isLoading,
    isSendingMessage,
    isCheckingStatus,
    errorMessage,
    startChatSession,
    sendEngageAIMessage,
  };
};
