import { Chart } from 'chart.js';

const getOrCreateLegendList = (chart: Chart, id: string) => {
  const legendContainer = document.getElementById(id);
  if (legendContainer) {
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'column';
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  }
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(
    chart: Chart,
    args: {
      mode:
        | 'resize'
        | 'reset'
        | 'none'
        | 'hide'
        | 'show'
        | 'default'
        | 'active';
    },
    /* eslint-disable */
    options: any
  ) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul?.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    if (
      chart.options.plugins &&
      chart.options.plugins.legend &&
      chart.options.plugins.legend.labels &&
      chart.options.plugins.legend.labels.generateLabels
    ) {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      if (items && ul) {
        items.forEach((item) => {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '0px';
          li.style.paddingBottom = '5px';

          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle + '';
          boxSpan.style.borderColor = item.strokeStyle + '';
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '14px';
          boxSpan.style.marginRight = '2px';
          boxSpan.style.width = '14px';

          // Text
          const textContainer = document.createElement('span');

          textContainer.style.overflow = 'hidden';
          textContainer.style.whiteSpace = 'nowrap';
          textContainer.style.textOverflow = 'ellipsis';
          textContainer.style.maxWidth = '150px';
          textContainer.className = 'kpiLegendText';
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      }
    }
  },
};
