import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Badge,
  Center,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { toPng } from 'html-to-image';

import { ISurveySparrowAnswerData } from '../../../pages/EngagementDetails/types';
import i18n from '../../../utils/i18n';
import ColumnHeader from '../../TableComponents/ColumnHeader';

type AverageRankForEachProps = {
  questionAnswers: (ISurveySparrowAnswerData | undefined)[];
};
type Response = string[];
interface AverageRankedChoice {
  option: string;
  rank: number;
  count: number;
}

const columnHelper = createColumnHelper<AverageRankedChoice>();

const columns = [
  columnHelper.accessor('option', {
    header: ({ column }) => (
      <ColumnHeader
        headerText={i18n.t('visualization.averageRankForEach.optionsTitle')}
        sorted={column.getIsSorted()}
      />
    ),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('rank', {
    header: ({ column }) => (
      <ColumnHeader
        headerText={i18n.t('visualization.averageRankForEach.rankTitle')}
        sorted={column.getIsSorted()}
      />
    ),
    cell: (info) => (
      <Badge variant="solid" colorScheme="blue" w={50}>
        <Center p={1}>
          <Text>{info.getValue().toFixed(1)}</Text>
        </Center>
      </Badge>
    ),
  }),
];

const calculateAverageRankedChoice = (
  responses: Response[]
): AverageRankedChoice[] => {
  const responseCount = responses.length;
  //the sum of ranks across all responses
  const choiceRankTotals: Record<string, number> = {};
  //the number of times a particular choice appeared
  //this is typically the same as `responseCount` but in some cases the responses are dynamic/conditional
  //based on prev answers, so we need to count how many times a given choice appears in an answer
  const choiceCounts: Record<string, number> = {};

  responses.forEach((response) => {
    response.forEach((choice, index) => {
      if (!choiceRankTotals[choice]) {
        choiceRankTotals[choice] = 0;
      }

      choiceRankTotals[choice] += index + 1;

      if (!choiceCounts[choice]) {
        choiceCounts[choice] = 1;
      } else {
        choiceCounts[choice]++;
      }
    });
  });

  const pipedChoices = Object.entries(choiceCounts).some(
    ([choice, count]) => count !== responseCount
  );

  const averageRankedChoices: AverageRankedChoice[] = [];
  for (const choice in choiceRankTotals) {
    const rank = choiceRankTotals[choice] / choiceCounts[choice];
    const label =
      choice + (pipedChoices ? ' (' + choiceCounts[choice] + ')' : '');
    averageRankedChoices.push({
      option: label,
      rank,
      count: choiceCounts[choice],
    });
  }

  //sort by rank, if rank is same, prefer ones with higher counts
  averageRankedChoices.sort((a, b) =>
    a.rank === b.rank ? a.count - b.count : a.rank - b.rank
  );
  return averageRankedChoices;
};

const AverageRankForEach = forwardRef(
  ({ questionAnswers }: AverageRankForEachProps, ref): JSX.Element => {
    const [data, setData] = React.useState<AverageRankedChoice[]>([]);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const tableRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => {
      const createImageFromTable = async (): Promise<string | null> => {
        if (!tableRef.current) return null;

        // Create a clone of the table
        const clone = tableRef.current.cloneNode(true) as HTMLDivElement;

        // Get computed width of the original table
        const originalTableWidth = getComputedStyle(tableRef.current).width;

        // Make the clone fully visible and hide the scrollbars
        clone.style.height = 'auto';
        clone.style.maxHeight = 'none';
        clone.style.overflowY = 'visible';
        clone.style.overflow = 'hidden'; // Hide scrollbars
        clone.style.width = originalTableWidth; // Set width of the clone to match original table

        // Append the clone to the body
        document.body.appendChild(clone);

        try {
          // Take screenshot of the clone
          const dataUrl = await toPng(clone);
          return dataUrl;
        } catch (error) {
          console.error('oops, something went wrong!', error);
        } finally {
          // Remove the clone from the body
          document.body.removeChild(clone);
        }

        return null;
      };

      return {
        onDownload: async (name: string) => {
          const dataUrl = await createImageFromTable();

          if (dataUrl) {
            // Download the screenshot
            const link = document.createElement('a');
            link.download = `${name}.png`;
            link.href = dataUrl;
            link.click();
          }
        },

        onCopyToClipboard: async () => {
          const dataUrl = await createImageFromTable();

          if (dataUrl) {
            try {
              // Convert data URL to Blob
              const response = await fetch(dataUrl);
              const blob = await response.blob();

              const item = new ClipboardItem({ 'image/png': blob });
              await navigator.clipboard.write([item]);
              console.log('Image copied to clipboard');
            } catch (error) {
              console.error('oops, something went wrong!', error);
            }
          }
        },
      };
    });

    useEffect(() => {
      const averageRankedChoices = calculateAverageRankedChoice(
        questionAnswers.map((answer) => {
          if (answer && Array.isArray(answer?.value)) return answer.value;
          return [];
        })
      );
      setData(averageRankedChoices);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionAnswers]);

    const table = useReactTable({
      data: data,
      columns: columns,
      getCoreRowModel: getCoreRowModel(),
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      state: {
        sorting,
      },
    });

    const tableTextColor = useColorModeValue('gray.700', 'white');
    const tableRowHoverBgColor = useColorModeValue('gray.200', 'gray.800');

    return (
      <TableContainer
        ref={tableRef}
        bg={useColorModeValue('white', 'gray.700')}
        borderWidth={'thin'}
        borderColor={'gray.200'}
        borderRadius={'lg'}
        maxH={'100%'}
        overflowY={'scroll'}
      >
        <Table variant="simple">
          <Thead
            position={'sticky'}
            top={0}
            zIndex={'docked'}
            bg={useColorModeValue('white', 'gray.700')}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      cursor={'pointer'}
                      fontSize={'sm'}
                      onClick={header.column.getToggleSortingHandler()}
                      textTransform={'capitalize'}
                      color={tableTextColor}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  fontSize={'sm'}
                  _hover={{ bg: tableRowHoverBgColor }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      color={tableTextColor}
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td
                  colSpan={columns.length}
                  textAlign={'center'}
                  color={'blue.700'}
                  py={20}
                >
                  {i18n.t('visualization.noData')}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
);

AverageRankForEach.displayName = 'AverageRankForEach';

export default AverageRankForEach;
