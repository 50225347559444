// External Dependencies
import React, { forwardRef, useImperativeHandle } from 'react';
import { StackDivider, Text, VStack } from '@chakra-ui/react';

// Internal Dependencies
import { ISurveySparrowAnswerData } from '../../../pages/EngagementDetails/types';
import { VisualizationChartRef } from '..';

type ListOfResponsesProps = {
  questionAnswers: (ISurveySparrowAnswerData | undefined)[];
};

const ListOfResponses = forwardRef<VisualizationChartRef, ListOfResponsesProps>(
  ({ questionAnswers }, ref) => {
    useImperativeHandle(ref, () => {
      const prepareTextData = (
        questionAnswers: (ISurveySparrowAnswerData | undefined)[]
      ): string => {
        return questionAnswers
          .filter((answer) => answer && answer.value)
          .map((answer) => answer?.value)
          .join('\n');
      };

      return {
        onDownload: (name: string) => {
          const textData = prepareTextData(questionAnswers);

          const blob = new Blob([textData], {
            type: 'text/plain;charset=utf-8',
          });

          const link = document.createElement('a');
          link.download = `${name}.txt`;
          link.href = URL.createObjectURL(blob);
          link.click();
        },

        onCopyToClipboard: () => {
          const textData = prepareTextData(questionAnswers);

          navigator.clipboard
            .writeText(textData)
            .then(() => {
              console.log('Text copied to clipboard');
            })
            .catch((err) => {
              console.error('Could not copy text: ', err);
            });
        },
      };
    });

    return (
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
        pr={4}
        maxH={'100%'}
        overflow={'scroll'}
      >
        {questionAnswers
          .filter((answer) => answer && answer.value)
          .map((answer) => (
            <Text key={answer?.id} fontSize={12}>
              {answer?.value}
            </Text>
          ))}
      </VStack>
    );
  }
);

ListOfResponses.displayName = 'ListOfResponses';

export default ListOfResponses;
