// External Dependencies
import React, { forwardRef } from 'react';
import { IndividualQuestion } from '@keyops-cep/api-client';

// Internal Dependencies
import {
  AnswerDataWithDemographic,
  ISurveySparrowAnswerData,
} from '../../../pages/EngagementDetails/types';
import i18n from '../../../utils/i18n';
import { averageMarkerPlugin } from '../ChartCard/chartPlugins';
import Histogram from '../Histogram';
import { VisualizationChartRef } from '..';

type DistributionProps = {
  question: IndividualQuestion;
  questionAnswers: AnswerDataWithDemographic[];
  displayPrevAvg?: boolean;
  maintainAspectRatio?: boolean;
  isExpanded?: boolean;
};

const getMaxValue = (
  question: IndividualQuestion,
  questionAnswers: ISurveySparrowAnswerData[]
): number | 'fromData' => {
  const maxFromData = questionAnswers.reduce((max, current) => {
    const value = parseInt(current.value + '');
    return value > max ? value : max;
  }, 0);

  if (question.type === 'OpinionScale') {
    if (maxFromData <= 5) {
      return 5;
    }
    if (maxFromData <= 7) {
      return 7;
    }
    if (maxFromData <= 10) {
      return 10;
    }
  }

  return maxFromData;
};

const Distribution = forwardRef<VisualizationChartRef, DistributionProps>(
  (
    {
      question,
      questionAnswers,
      displayPrevAvg,
      maintainAspectRatio = false,
      isExpanded = false,
    },
    ref
  ) => {
    const min =
      question.type === 'OpinionScale' &&
      !questionAnswers.find((answer) => answer.value === 0)
        ? 1
        : 0;
    const max = getMaxValue(question, questionAnswers);
    //round the numbers
    questionAnswers.forEach((questionAnswer) => {
      const numberValue =
        typeof questionAnswer.value === 'number'
          ? questionAnswer.value
          : typeof questionAnswer.value === 'string'
          ? parseFloat(questionAnswer.value)
          : undefined;
      if (numberValue && !isNaN(numberValue)) {
        questionAnswer.value = Math.round(numberValue);
      }
    });
    return (
      <Histogram
        ref={ref}
        question={question}
        questionAnswers={questionAnswers}
        scaleType="linear"
        maxValue={max}
        minValue={min}
        xAxisLabel={i18n.t('visualization.distribution.selectedAxisLabel')}
        yAxisLabel={i18n.t('visualization.distribution.countAxisLabel')}
        averageMarkerPlugin={averageMarkerPlugin}
        displayPrevAvg={displayPrevAvg}
        maintainAspectRatio={maintainAspectRatio}
        isExpanded={isExpanded}
      />
    );
  }
);

Distribution.displayName = 'Distribution';

export default Distribution;
