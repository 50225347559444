import React, { useEffect, useState } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import { useMatch } from 'react-router-dom';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';

import {
  ENGAGEMENT_DETAILS,
  ENGAGEMENT_RESULTS_TAB,
} from '../../utils/internal-routes.const';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isEngagementsDetails = useMatch(ENGAGEMENT_DETAILS);
  const isEngagementsDetailsResults = useMatch(
    `${ENGAGEMENT_DETAILS}/${ENGAGEMENT_RESULTS_TAB}`
  );

  // Show button when page is scrolled down by 300px
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  if (isEngagementsDetails || isEngagementsDetailsResults) return null;
  return (
    <Box
      position="fixed"
      bottom="32"
      right="0"
      color={'keyops.blue'}
      borderTopLeftRadius={8}
      borderBottomLeftRadius={8}
      shadow={
        '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)'
      }
    >
      {isVisible && (
        <Tooltip
          hasArrow
          label="Back to top"
          placement="left"
          bg={'keyops.blue'}
        >
          <IconButton
            aria-label="Back to top"
            icon={<FiChevronUp />}
            fontSize={28}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            bg={'white'}
            color={'keyops.blue'}
            onClick={scrollToTop}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default ScrollToTopButton;
