import React, { useState } from 'react';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';

import Loading from '../../components/Loading';
import PasswordField from '../../components/PasswordField';
import useAuth from '../../HOCs/auth/useAuth';
import i18n from '../../utils/i18n';
import { FORGOT_PASSWORD } from '../../utils/internal-routes.const';

const Login: React.FC = () => {
  const { loading, handleEmailPasswordLogin, handleAzureLogin } = useAuth();
  const [localEmail, setLocalEmail] = useState('');
  const [localPassword, setLocalPassword] = useState('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleEmailPasswordLogin(localEmail, localPassword);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      <Text fontSize="xl" mt={6}>
        {i18n.t('login.title')}
      </Text>
      <Box
        mt={4}
        w={{ base: '100%', lg: '50%' }}
        as="form"
        onSubmit={handleSubmit}
      >
        <FormControl>
          <FormLabel>{i18n.t('login.emailLabel')}</FormLabel>
          <Input
            aria-label="login-email-input"
            size={'sm'}
            type="email"
            value={localEmail}
            onChange={handleEmailChange}
          />
        </FormControl>
        <PasswordField
          label={i18n.t('login.passwordLabel')}
          value={localPassword}
          handleValueChange={handlePasswordChange}
        />
        <Button type="submit" colorScheme="blue" mt={4} px={10}>
          {i18n.t('common.login')}
        </Button>
        <Text fontSize="md" mt={6}>
          <Link
            href={FORGOT_PASSWORD}
            color="keyops.blue"
            rel="noopener noreferrer"
          >
            {i18n.t('common.forgotPassword')}
          </Link>
        </Text>
      </Box>
      <Box position="relative" py={6}>
        <Divider borderColor={'gray.400'} />
        <AbsoluteCenter bg="white" px="4" color={'keyops.gray'}>
          {i18n.t('common.or')}
        </AbsoluteCenter>
      </Box>
      <Flex mt={4} justifyContent={'center'}>
        <Button
          variant={'outline'}
          colorScheme="blue"
          onClick={(e) => {
            e.preventDefault();
            handleAzureLogin();
          }}
        >
          {i18n.t('common.loginWithAzure')}
        </Button>
      </Flex>
    </Box>
  );
};

export default Login;
