// External Dependencies
import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

// Internal Dependencies
import i18n from '../../../../utils/i18n';

export const ChatHeaderInitialContent = () => {
  return (
    <Stack
      data-testid="engage-ai-chat-header-initial-content"
      direction={'column'}
      textAlign={'center'}
    >
      <Text color="keyops.blue" fontWeight={500} fontSize={'16px'}>
        {i18n.t('engagementDetails.engageAi.title')}
      </Text>
      <Text>{i18n.t('engagementDetails.engageAi.chatHeaderIntro')}</Text>
      <Text fontSize={'12px'}>
        {i18n.t('engagementDetails.engageAi.chatHeaderSubtitle')}
      </Text>
    </Stack>
  );
};
