export const COMPONENTS = {
  // feature names goes here
  DASHBOARD: 'Dashboard',
  NEW_FILTERS: 'NewFilters',
  // This flag is to suppress display of the toggle.
  // So we can keep the toggle code, but hide the button
  // as we may want this feature at the user or engagement level at some point.
  PREV_AVG_TOGGLE: 'PreviousAverageToggle',
};

export const COMPONENT_FLAGS: {
  [key: string]: boolean;
} = {
  // feature enabled or disabled goes here
  [COMPONENTS.DASHBOARD]: false,
  [COMPONENTS.NEW_FILTERS]: false,
  [COMPONENTS.PREV_AVG_TOGGLE]: false,
};
