// External Dependencies
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Center } from '@chakra-ui/react';
import { IndividualQuestion } from '@keyops-cep/api-client';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Internal Dependencies
import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import { AnswerDataWithDemographic } from '../../../pages/EngagementDetails/types';
import {
  CHART_COLORS,
  CHART_COLORS_WITH_ALPHA,
} from '../../../utils/constants/visualization-constants';
import { getBreakoutDemographicsDatasets } from '../../../utils/functions/breakout-demographics-datasets';
import {
  calculateRange,
  convertToCountsObject,
  Counts,
} from '../../../utils/functions/stats';
import {
  copyGraph,
  downloadGraph,
} from '../../../utils/functions/visualization-actions';
import { getPrevAvgAnnotation } from '../../../utils/functions/visualization-utils';
import {
  MarkerPluginType,
  prevAverageMarkerPlugin,
} from '../ChartCard/chartPlugins';
import { VisualizationChartRef } from '..';

type HistogramProps = {
  question: IndividualQuestion;
  questionAnswers: AnswerDataWithDemographic[];
  scaleType?: 'linear';
  maxValue?: number | 'fromData';
  minValue?: number | 'fromData';
  xAxisLabel?: string;
  yAxisLabel?: string;
  averageMarkerPlugin?: (
    counts: Counts,
    rangeValue: number
  ) => MarkerPluginType;
  displayPrevAvg?: boolean;
  maintainAspectRatio?: boolean;
  isExpanded?: boolean;
};

// This is the limit of characters for the label. If the labels get too long,
// the visualization can get messed up.
const labelLimit = 15;
const dataLabelLimit = 7;

const Histogram = forwardRef<VisualizationChartRef, HistogramProps>(
  (
    {
      question,
      questionAnswers,
      scaleType,
      maxValue,
      minValue,
      xAxisLabel,
      yAxisLabel,
      averageMarkerPlugin,
      displayPrevAvg,
      maintainAspectRatio = false,
      isExpanded = false,
    },
    ref
  ) => {
    const { DEFAULT_FILTER_GROUPS, breakoutDemographic } = useFilterProvider();

    const rangeValue = useMemo(
      () => calculateRange(question.type, questionAnswers),
      [question.type, questionAnswers]
    );

    const breakoutDemographicsDatasets = useMemo(() => {
      return getBreakoutDemographicsDatasets(
        breakoutDemographic,
        DEFAULT_FILTER_GROUPS,
        questionAnswers,
        question,
        rangeValue
      );
    }, [
      questionAnswers,
      question,
      DEFAULT_FILTER_GROUPS,
      breakoutDemographic,
      rangeValue,
    ]);

    // Get the raw count data (without aggregating by rangeValue) for calculating the average
    const avgCalc = useMemo(
      () => convertToCountsObject(questionAnswers, question.type),
      [questionAnswers, question.type]
    );

    // get latest previous average from annotations, if it is available
    const prevAvgAnnotation = getPrevAvgAnnotation(question);

    const { counts, convertedToRanges } = useMemo(
      () => convertToCountsObject(questionAnswers, question.type, rangeValue),
      [questionAnswers, question.type, rangeValue]
    );
    const showLabelDetailView = Object.keys(counts).length <= dataLabelLimit;

    const { maxCount, maxX, minX, xStepSize } = useMemo(() => {
      let maxCount;
      // if breakoutDemographics we need to find the maxCount within array of demos
      if (breakoutDemographicsDatasets.length > 0) {
        let breakoutMax = 0;
        breakoutDemographicsDatasets.forEach((demo) => {
          const demoMax = Math.max(...Object.values(demo.data));
          if (demoMax > breakoutMax) breakoutMax = demoMax;
        });
        maxCount = breakoutMax;
      } else {
        maxCount = Object.keys(counts).reduce((currentMax, key) => {
          if (currentMax < counts[key]) {
            return counts[key];
          } else {
            return currentMax;
          }
        }, 0);
      }

      const maxX =
        maxValue === 'fromData'
          ? Object.keys(counts).reduce((currentMax, key) => {
              const keyAsInt = parseInt(key);
              if (!isNaN(keyAsInt) && currentMax < keyAsInt) {
                return keyAsInt;
              } else {
                return currentMax;
              }
            }, 0)
          : maxValue;
      const minX =
        minValue === 'fromData'
          ? Object.keys(counts).reduce((currentMin, key) => {
              const keyAsInt = parseInt(key);
              if (!isNaN(keyAsInt) && currentMin > keyAsInt) {
                return keyAsInt;
              } else {
                return currentMin;
              }
            }, 0)
          : minValue;
      const xStepSize = maxX
        ? maxX >= 20 && maxX < 70
          ? 5
          : maxX > 70
          ? 10
          : 1
        : 1;

      return { maxCount, maxX, minX, xStepSize };
    }, [counts, maxValue, minValue, breakoutDemographicsDatasets]);

    const chartRef: { current: Chart<'bar', Counts> | null } = useRef(null);
    const chartContainer = useRef<HTMLCanvasElement>(null);

    useImperativeHandle(ref, () => ({
      onDownload: (name: string) => {
        downloadGraph(name, chartRef);
      },

      onCopyToClipboard: async () => {
        await copyGraph(chartRef);
      },
    }));

    useEffect(() => {
      if (chartContainer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chartRef.current = new Chart<'bar', Counts>(chartContainer.current, {
          type: 'bar',
          data: {
            datasets: breakoutDemographic
              ? breakoutDemographicsDatasets
              : [
                  {
                    data: counts,
                    backgroundColor: CHART_COLORS_WITH_ALPHA[2],
                    borderColor: CHART_COLORS[2],
                    borderWidth: 2,
                  },
                ],
          },
          options: {
            responsive: true,
            maintainAspectRatio,
            layout: {
              padding: {
                top: averageMarkerPlugin
                  ? prevAvgAnnotation
                    ? 55
                    : 40
                  : showLabelDetailView
                  ? 20
                  : 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
            },
            plugins: {
              legend: {
                display: breakoutDemographic ? true : false,
                position: 'bottom',
                labels: {
                  boxWidth: Chart.defaults.font.size,
                },
                onClick: (e, legendItem, legend) => {
                  e.native?.preventDefault();
                },
              },
              datalabels: {
                align: 'end',
                anchor: 'end',
                formatter: (_, context) => {
                  const keys = Object.keys(context.dataset.data);
                  const index = keys[context.dataIndex];
                  const newValue =
                    context.dataset.data[
                      index as keyof typeof context.dataset.data
                    ];
                  if (newValue === 0) return null;
                  return newValue;
                },
              },
            },
            scales: {
              x: {
                ...(xAxisLabel && {
                  title: {
                    display: true,
                    text: xAxisLabel,
                  },
                }),
                ...(!convertedToRanges && scaleType && { type: scaleType }),
                ...(!convertedToRanges && maxX && { max: maxX }),
                ...(!convertedToRanges && minX && { min: minX }),
                ticks: {
                  stepSize: xStepSize,
                  callback: function (value) {
                    if (value || value === 0) {
                      const label: string = this.getLabelForValue(
                        parseInt(value as string)
                      );
                      return label.length > labelLimit
                        ? `${label.substring(0, labelLimit)}...`
                        : label;
                    }
                    return '';
                  },
                },
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  stepSize: Math.max(Math.round(maxCount / 5), 1),
                },
                ...(yAxisLabel && {
                  title: {
                    display: true,
                    text: yAxisLabel,
                  },
                }),
                grid: {
                  color: 'rgba(0, 0, 0, 0.05)',
                },
                grace: 0,
              },
            },
          },
          plugins: [
            ...(averageMarkerPlugin
              ? [averageMarkerPlugin(avgCalc.counts, rangeValue)]
              : []),
            ...(displayPrevAvg && !!prevAvgAnnotation
              ? [
                  prevAverageMarkerPlugin(
                    prevAvgAnnotation.value,
                    prevAvgAnnotation.description,
                    rangeValue
                  ),
                ]
              : []),
            ...(isExpanded || showLabelDetailView ? [ChartDataLabels] : []),
          ],
        });
      }

      // Clean up the chart instance when the component unmounts
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counts, displayPrevAvg]);

    return (
      <Center h={'100%'}>
        <canvas
          id={`chart-${Math.floor(Math.random() * 100)}`}
          ref={chartContainer}
        ></canvas>
      </Center>
    );
  }
);

Histogram.displayName = 'Histogram';

export default Histogram;
