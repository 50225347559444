import React from 'react';
import { Tooltip, TooltipProps } from '@chakra-ui/react';

export const KeyOpsTooltip: React.FC<TooltipProps> = ({
  children,
  ...props
}) => {
  return (
    <Tooltip
      shouldWrapChildren
      p={'4px 8px'}
      borderRadius={'4px'}
      background={'var(--gray-200, #E2E8F0);'}
      color="black"
      lineHeight={'20px'}
      textAlign={'center'}
      width="auto"
      openDelay={500}
      hasArrow
      {...props}
    >
      {children}
    </Tooltip>
  );
};
