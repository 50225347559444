import { QuestionChoice } from '@keyops-cep/api-client';
import { lowerCase, upperFirst } from 'lodash';

import { QuestionTypes } from '../../components/Visualization/types';
import { ISurveySparrowAnswerData } from '../../pages/EngagementDetails/types';
import i18n from '../i18n';

export type Counts = { [key: string]: number };
export type Count = { label: string; value: number; notes?: string[] };
export interface ArrayDataObject {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  data: number[];
  label: string;
}
export interface CountsDataObject {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  data: Counts;
  label: string;
}

const transformYesNoValues = (
  flatValues: (string | number | boolean | undefined)[]
): (string | number | boolean | undefined)[] => {
  return flatValues
    .map((value) =>
      value
        ? i18n.t('visualization.histogram.yesNo.trueLabel')
        : i18n.t('visualization.histogram.yesNo.falseLabel')
    )
    .sort((a, b) => {
      if (a && b) {
        if (a < b) return 1;
        if (a > b) return -1;
      }
      return 0;
    });
};

export const convertToCountsObject = (
  questionAnswers: ISurveySparrowAnswerData[],
  questionType?: QuestionTypes,
  range?: number
): { counts: Counts; convertedToRanges: boolean } => {
  const flatValues = getFlatValuesFromAnswers(questionAnswers);
  //apply the value transform if one is provided
  const finalFlatValues =
    questionType === 'YesNo' ? transformYesNoValues(flatValues) : flatValues;

  const counts: Counts = {};
  finalFlatValues.forEach((value) => {
    if (value || (typeof value === 'boolean' && value === false)) {
      const key = String(value).trim().toLowerCase();
      counts[key] = (counts[key] || 0) + 1;
    }
  });

  if (!range) {
    return { counts, convertedToRanges: false };
  }

  const keys = Object.keys(counts)
    .map(Number)
    .sort((a, b) => a - b);

  const results: Counts = {};
  let currentRangeStart = 1;
  let currentRangeEnd = range;
  let currentRangeSum = 0;

  keys.forEach((currentKey) => {
    while (currentKey > currentRangeEnd) {
      results[`${currentRangeStart}-${currentRangeEnd}`] = currentRangeSum;
      currentRangeSum = 0;
      currentRangeStart = currentRangeEnd + 1;
      currentRangeEnd += range;
    }

    currentRangeSum += counts[currentKey] || 0;
  });

  const lastRangeEnd = Math.ceil(currentRangeEnd / range) * range;
  results[`${currentRangeStart}-${lastRangeEnd}`] = currentRangeSum;

  return { counts: results, convertedToRanges: true };
};

const getFlatValuesFromAnswers = (
  questionAnswers: ISurveySparrowAnswerData[]
) => {
  return questionAnswers.map((answer) => answer?.value).flat(1);
};

const splitOtherValues = (
  values: (string | number | boolean | undefined)[],
  choices?: QuestionChoice[]
) => {
  if (!choices || choices.length === 0) {
    return [values, []];
  }

  const nonOtherChoices = choices
    .filter((choice) => !choice.other)
    .map((choice) => choice.label);

  const results: (string | number | boolean | undefined)[][] = [[], []];
  return values.reduce((splitValues, value) => {
    if (value === null || typeof value === 'undefined') {
      return splitValues;
    }
    if (nonOtherChoices.includes(value.toString())) {
      splitValues[0].push(value);
    } else {
      splitValues[1].push(value);
    }
    return splitValues;
  }, results);
};

/**
 * Flattens out the answers, and then counts them
 *
 * e.g. ['value1', 'value1,value2', 'value1'] becomes:
 * [{ label:'value1', value: 3},{label:'value2', value: 1 }]
 *
 * @param questionAnswers the answers to examine
 * @param choices optional, if included, any answers with different values are treated as "Others".
 * Further will ensure that all choice that are not other will have options set to 0.
 * @returns
 */
export const convertToCountArray = (
  questionAnswers: ISurveySparrowAnswerData[],
  choices?: QuestionChoice[]
): Count[] => {
  const allValues = splitOtherValues(
    getFlatValuesFromAnswers(questionAnswers),
    choices
  );

  const flatValues = allValues[0];

  const flatValuesForOthers = allValues[1];

  const counts: Count[] = [];

  flatValues.forEach((value) => {
    if (value || (typeof value === 'boolean' && value === false)) {
      const count = counts.find((count) => count.label === value);
      if (!count) {
        counts.push({ label: value + '', value: 1 });
      } else {
        count.value++;
      }
    }
  });

  choices?.forEach((choice) => {
    if (choice.other) {
      return;
    }
    if (!counts.find((count) => count.label === choice.label)) {
      counts.push({ label: choice.label, value: 0 });
    }
  });

  //We sort before we add the "other" count, as that should always be last when present
  if (choices && choices.length > 0) {
    //sort according to choices
    counts.sort((countA, countB) => {
      const choiceA = choices.find((choice) => choice.label === countA.label);
      const choiceB = choices.find((choice) => choice.label === countB.label);
      if (!choiceA || !choiceB) {
        return countA.label.localeCompare(countB.label);
      }
      return choiceA?.order - choiceB?.order;
    });
  } else {
    //sort according to labels
    counts.sort((countA, countB) => countA.label.localeCompare(countB.label));
  }

  //if we got other values, bundle them up into an "other" option
  if (flatValuesForOthers.length > 0) {
    //normalize and dedupe
    const flatValueStrings = [
      ...new Set(
        flatValuesForOthers.map((value) =>
          upperFirst(lowerCase(value?.toString().trim()))
        )
      ),
    ];
    counts.push({
      label: 'Other',
      value: flatValuesForOthers.length,
      notes: flatValueStrings,
    });
  }

  return counts;
};

/**
 * Flattens out the answers, and then counts them and converts to percentages of respondents
 *
 * e.g. ['value1', 'value1,value2', 'value1'] becomes:
 * [{ label:'value1', value: 100},{label:'value2', value: 25 }]
 *
 * @param questionAnswers the answers to examine
 * @param choices optional, if included, any answers with different values are treated as "Others".
 * Further will ensure that all choice that are not other will have options set to 0.
 * @returns
 */
export const convertToPercentageCountArray = (
  questionAnswers: ISurveySparrowAnswerData[],
  choices?: QuestionChoice[],
  numberOfAnswers = questionAnswers.length
): Count[] => {
  const counts = convertToCountArray(questionAnswers, choices);
  //compute percentage based on number of answers rather than number of values
  //as the percentage should really represent the percentage of respondents who
  //chose that option.
  counts.forEach(
    (count) => (count.value = Math.round((count.value / numberOfAnswers) * 100))
  );

  return counts;
};

export const distributionAverage = (counts: Counts): number => {
  const totalAnswered = Object.values(counts).reduce((a, b) => a + b, 0);
  // return 0 if totalAnswered is equal to 0 to avoid div by 0
  if (totalAnswered === 0) return 0;
  let avg = 0;
  for (const key in counts) {
    avg = avg + parseFloat(key) * counts[key];
  }
  return avg / totalAnswered;
};

/**
 * Shorten the dataArray by the cutOffPoint and condense the rest of the demographics in an others category
 *
 * @param dataArray the list of data - stored in Arrays
 * @param cutOffPoint how many demographics you want to keep unique
 * @returns shortened dataArray
 */
export function compressArrayDataSet(
  dataArray: ArrayDataObject[],
  cutOffPoint: number
) {
  // filter objects with a sum of 0, and then sort the array by the sum of the 'data' in descending order
  // we filter then sort as filter will create a new array, but sort will sort in place and we don't
  // want to mutate the passed in array
  const sortedDataArray = dataArray
    .filter((obj) => obj.data.reduce((acc, curr) => acc + curr, 0) > 0)
    .sort((a, b) => {
      const sumA = a.data.reduce((acc, curr) => acc + curr, 0);
      const sumB = b.data.reduce((acc, curr) => acc + curr, 0);
      return sumB - sumA;
    });
  // Don't aggregate to "Others" category if there's only one value past the cutOffPoint
  if (sortedDataArray.length <= cutOffPoint + 1) return sortedDataArray;
  // Slice at the cut off point
  const cutOffDataArray = sortedDataArray.slice(0, cutOffPoint);
  // Condense the rest of the data into an "Others" category
  const othersData = sortedDataArray.slice(cutOffPoint);
  if (othersData.length > 0) {
    const others = othersData.reduce(
      (acc: ArrayDataObject, curr: ArrayDataObject) => {
        curr.data.forEach((value, index) => {
          if (Array.isArray(acc.data)) {
            acc.data[index] = (acc.data[index] || 0) + value;
          }
        });

        return acc;
      },
      {
        backgroundColor: '#71809680', // Cooler grey with transparency
        borderColor: '#718096', // Cooler grey for neutrality
        borderWidth: 1,
        data: new Array(dataArray[0].data.length).fill(0),
        label: 'Others',
      }
    );
    cutOffDataArray.push(others);
  }
  return cutOffDataArray;
}

/**
 * Shorten the dataArray by the cutOffPoint and condense the rest of the demographics in an others category
 *
 * @param dataArray the list of data - stored in Counts object
 * @param cutOffPoint how many demographics you want to keep unique
 * @returns shortened dataArray
 */
export function compressCountsDataSet(
  dataArray: CountsDataObject[],
  cutOffPoint: number
) {
  // Sort the array by the sum of the 'data' in descending order, excluding objects with a sum of 0
  const sortedDataArray = dataArray
    .sort((a, b) => {
      const sumA = Object.values(a.data).reduce((acc, curr) => acc + curr, 0);
      const sumB = Object.values(b.data).reduce((acc, curr) => acc + curr, 0);
      return sumB - sumA;
    })
    .filter(
      (obj) => Object.values(obj.data).reduce((acc, curr) => acc + curr, 0) > 0
    );
  // Don't aggregate to "Others" category if there's only one value past the cutOffPoint
  if (sortedDataArray.length <= cutOffPoint + 1) return sortedDataArray;
  // Slice at the cut off point
  const cutOffDataArray = sortedDataArray.slice(0, cutOffPoint);
  // Condense the rest of the data into an "Others" category
  const othersData = sortedDataArray.slice(cutOffPoint);
  if (othersData.length > 0) {
    const others = othersData.reduce(
      (acc: CountsDataObject, curr: CountsDataObject) => {
        Object.entries(curr.data as Counts).forEach(([key, value]) => {
          const accDataCounts = acc.data as Counts;
          accDataCounts[key] = (accDataCounts[key] || 0) + value;
        });
        return acc;
      },
      {
        backgroundColor: '#71809680', // Cooler grey with transparency
        borderColor: '#718096', // Cooler grey for neutrality
        borderWidth: 1,
        data: {},
        label: 'Others',
      }
    );
    cutOffDataArray.push(others);
  }
  return cutOffDataArray;
}

/**
 * This method will calculate the absolute size of the value range, given the provided answers and question type.
 *
 * @param questionType
 * @param questionAnswers
 * @returns if the value returned is falsy, no value ranging is needed
 */
export const calculateRange = (
  questionType: QuestionTypes,
  questionAnswers: ISurveySparrowAnswerData[]
): number => {
  if (questionType === 'NumberInput') {
    //find the min and max values from the answer set
    const minMax: { min: number | null; max: number | null } = {
      min: null,
      max: null,
    };
    questionAnswers.reduce((minMax, answer) => {
      if (typeof answer.value === 'number') {
        if ((!minMax.min && minMax.min !== 0) || minMax.min > answer.value) {
          minMax.min = answer.value;
        }
        if ((!minMax.max && minMax.max !== 0) || minMax.max < answer.value) {
          minMax.max = answer.value;
        }
      }
      //we ignore any values that are not numbers
      return minMax;
    }, minMax);

    //if either are null, we don't have a range to compute
    if (minMax.min === null || minMax.max === null) {
      return 0;
    }
    //calculate the diff
    const valueDifference = minMax.max - minMax.min;

    const rangeConditions = [
      { differenceLimit: 20, range: 0 },
      { differenceLimit: 70, range: 5 },
      { differenceLimit: 100, range: 10 },
      { differenceLimit: 500, range: 25 },
      { differenceLimit: 1000, range: 100 },
      { differenceLimit: 5000, range: 500 },
      { differenceLimit: Infinity, range: 1000 }, // Default case
    ];

    //based on the definition above, retrieve the correct value range
    return (
      rangeConditions.find(
        (rangeCondition) => valueDifference <= rangeCondition.differenceLimit
      )?.range ?? 0
    );
  }
  return 0;
};
