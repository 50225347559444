import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Link, Text, useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import {
  resetPasswordApiCall,
  validateResetPasswordToken,
} from '../../api/auth.functions';
import Loading from '../../components/Loading';
import PasswordField from '../../components/PasswordField';
import PasswordStrengthBar from '../../components/PasswordStrengthBar';
import useAuth from '../../HOCs/auth/useAuth';
import usePasswordStrength from '../../hooks/usePasswordStrength';
import i18n from '../../utils/i18n';
import { LOGIN } from '../../utils/internal-routes.const';

const LinkExpired = () => (
  <Box>
    <Text fontSize="xl" mt={6}>
      {i18n.t('resetPassword.title')}
    </Text>
    <Text fontSize="md" mt={6}>
      {i18n.t('resetPassword.linkExpired')}
    </Text>
    <Box mt={10}>
      <Link href={LOGIN} color="keyops.blue" rel="noopener noreferrer">
        {i18n.t('resetPassword.backToLogin')}
      </Link>
    </Box>
  </Box>
);

const ResetPassword: React.FC = () => {
  const { loading } = useAuth();
  const toast = useToast();
  const { resetPasswordToken } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchMessage, setPasswordMatchMessage] = useState('');

  const { isLoading, isError } = useQuery({
    queryKey: ['validateResetPasswordToken', resetPasswordToken],
    queryFn: async () => {
      if (resetPasswordToken) {
        const data = await validateResetPasswordToken({ resetPasswordToken });
        return data;
      }
    },
  });

  const result = usePasswordStrength(password);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
    setPasswordMatchMessage('');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMatchMessage(i18n.t('resetPassword.passwordMatchError'));
    } else if (resetPasswordToken) {
      const response = await resetPasswordApiCall({
        resetPasswordToken,
        password,
      });
      if (response && response.status === 201) {
        toast({
          title: i18n.t('common.success'),
          description: i18n.t('resetPassword.successMsg'),
          variant: 'subtle',
          isClosable: true,
          status: 'success',
          position: 'top-right',
        });
        navigate(LOGIN);
      } else {
        toast({
          title: i18n.t('errorComponent.title'),
          description: i18n.t('errorComponent.subTitle'),
          variant: 'subtle',
          isClosable: true,
          status: 'error',
          position: 'top-right',
        });
      }
    }
  };

  if (loading || isLoading) {
    return <Loading h="100%" />;
  }

  if (!resetPasswordToken || isError) {
    return <LinkExpired />;
  }

  return (
    <Box>
      <Text fontSize="xl" mt={6}>
        {i18n.t('resetPassword.title')}
      </Text>
      <Box w={{ base: '100%', lg: '50%' }} as="form" onSubmit={handleSubmit}>
        <PasswordField
          label={i18n.t('resetPassword.passwordLabel')}
          value={password}
          handleValueChange={handlePasswordChange}
          helpText={i18n.t('resetPassword.passwordHelpText')}
        />
        <PasswordStrengthBar score={result?.score || 0} />
        <PasswordField
          label={i18n.t('resetPassword.confirmPasswordLabel')}
          value={confirmPassword}
          errorMessage={passwordMatchMessage}
          handleValueChange={handleConfirmPasswordChange}
        />
        <Button
          type="submit"
          isDisabled={password.length < 8}
          colorScheme="blue"
          mt={4}
        >
          {i18n.t('resetPassword.resetPasswordBtn')}
        </Button>
      </Box>
    </Box>
  );
};

export default ResetPassword;
