import { Chart, ChartDataset, ChartOptions } from 'chart.js';

import { CHART_COLORS } from '../../../utils/constants/visualization-constants';

import { ChartData } from '.';

interface DataPoint {
  x: number;
  y: number;
  tooltipPosition(useFinalPosition?: boolean): { x: number; y: number };
}
type GraphLocation = { x: number; y: number };
const getOffsetForConflict = (
  original: GraphLocation,
  xDistance: number,
  yDistance: number,
  labelPositions: GraphLocation[]
): GraphLocation => {
  const conflicting = labelPositions.find((position) => {
    return (
      Math.abs(original.x - position.x) < 11 &&
      Math.abs(original.y - position.y) < 4
    );
  });
  const tryOffset = true;
  if (!conflicting || !tryOffset) {
    return original;
  }
  const toOffset = 7;
  if (Math.abs(xDistance) < 15) {
    return {
      x: original.x + (xDistance < 0 ? toOffset : -toOffset),
      y: original.y,
    };
  }
  if (Math.abs(yDistance) < 15) {
    return {
      x: original.x,
      y: original.y + (xDistance < 0 ? toOffset : -toOffset),
    };
  }
  return original;
};
export const labelOnDoughnut = (data: Array<ChartData>) => {
  return {
    id: 'labelOnDoughnut',
    afterDraw(chart: Chart, args: unknown, options: ChartOptions) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;

      const padding =
        typeof chart.options.layout?.padding === 'number'
          ? chart.options.layout?.padding
          : 10;
      //center of the donut, we need to offset by 20 to account for padding
      const centerX = width / 2 + padding;
      const centerY = height / 2 + padding;

      // how far beyond the arc we want to place the label
      const labelOffset = 9;

      const labelPositions: GraphLocation[] = [];
      ctx.fillStyle = '#000000';
      chart.data.datasets.forEach((dataset: ChartDataset, i: number) => {
        chart
          .getDatasetMeta(i)
          .data.forEach((dataPoint: DataPoint, index: number) => {
            const { x, y } = dataPoint.tooltipPosition(true);

            // we have to position the text out from the center.
            // in other words if we draw a line from the center of the
            // doughnut through the middle of the arc and continue
            // past that, that's where we position the text.
            const radius = Math.sqrt(
              Math.pow(centerX - x, 2.0) + Math.pow(centerY - y, 2.0)
            );

            const xDistance = x - centerX;
            const yDistance = y - centerY;

            const xRatio = xDistance / radius;
            const yRatio = yDistance / radius;

            const textX = centerX + xRatio * (radius + labelOffset);
            const textY = centerY + yRatio * (radius + labelOffset);

            ctx.fillStyle = CHART_COLORS[index];
            const finalTextPosition = getOffsetForConflict(
              { x: textX, y: textY },
              xDistance,
              yDistance,
              labelPositions
            );
            //Uncomment below to see the location of text drawing
            //this can help determine if the problem is a (x,y) calc
            //problem or a text alignment problem.
            //ctx.fillRect(newX - 1, newY - 1, 2, 2);
            // text
            ctx.font = '12px arial';
            //coarse thresholds for alignment
            ctx.textAlign =
              xDistance > 0 ? 'left' : xDistance < -20 ? 'right' : 'center';
            ctx.textBaseline =
              yDistance > 20 ? 'top' : yDistance < -20 ? 'bottom' : 'middle';
            labelPositions.push(finalTextPosition);
            ctx.fillText(
              data[index].value + '%',
              finalTextPosition.x,
              finalTextPosition.y
            );
          });
      });
    },
  };
};
