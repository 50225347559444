import React, { useState } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  VStack,
} from '@chakra-ui/react';

import { KeyOpsLinkButton } from '../../../components/ButtonComponents';
import i18n from '../../../utils/i18n';

interface FilterSectionProps {
  filterType: string;
  questionId: string | undefined;
  filterOptions: (string | number | boolean | string[] | undefined)[];
  localFilters: string[];
  handleLocalFilters: (filter: string) => void;
  removeLocalQuestionFilter: () => void;
}

const FilterSection = ({
  filterType,
  questionId,
  filterOptions,
  localFilters,
  handleLocalFilters,
  removeLocalQuestionFilter,
}: FilterSectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <AccordionItem border={'none'}>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left" fontWeight={600}>
          {filterType}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <CheckboxGroup colorScheme={'blue'} value={localFilters}>
          <VStack spacing={3} align="stretch">
            {filterOptions.map((option, index) => {
              if (index < 6 || showMore) {
                return (
                  <Checkbox
                    key={`${option}-${index}`}
                    value={`${filterType}#${questionId}#${option}`}
                    onChange={(event) => handleLocalFilters(event.target.value)}
                  >
                    {option}
                  </Checkbox>
                );
              } else return null;
            })}
          </VStack>
        </CheckboxGroup>
        <Flex justifyContent={'space-between'}>
          {filterOptions.length > 6 ? (
            <Button
              size={'sm'}
              variant={'link'}
              colorScheme={'blue'}
              mt={4}
              _hover={{ textDecoration: 'none' }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore
                ? i18n.t('engagementDetails.filter.filterDrawer.showLess')
                : i18n.t('engagementDetails.filter.filterDrawer.showMore', {
                    count: filterOptions.length - 6,
                  })}
            </Button>
          ) : (
            <Box />
          )}
          {questionId && (
            <KeyOpsLinkButton
              size={'sm'}
              mt={4}
              onClick={removeLocalQuestionFilter}
            >
              {i18n.t('engagementDetails.questionCards.removeQueFilterBtn')}
            </KeyOpsLinkButton>
          )}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default FilterSection;
