export function formatDateMonthYear(endDate: string) {
  // Ex: formats "2024-03-07T14:00:00.000Z" -> Mar 2024
  const date = new Date(endDate);
  // Use Intl.DateTimeFormat to format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
  }).format(date);
  return formattedDate;
}
