import { Box, HStack, Tooltip } from '@chakra-ui/react';

import FilterDropdownComponent from '../../../components/FilterDropdownComponent';
import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import { DEMOGRAPHIC_NAMES } from '../../../utils/constants/visualization-constants';
import i18n from '../../../utils/i18n';

const FILTER_OPTIONS = {
  SPECIALTY: 'specialty',
  PRACTICE: 'practice',
  REGION: 'region',
  SEGMENT: 'segment',
};

interface FilterWithTooltipProps {
  filterDropdownType: string;
  filterDropdownOptions: string[];
  visualization: string;
}

const FilterWithTooltip = ({
  filterDropdownType,
  filterDropdownOptions,
  visualization,
}: FilterWithTooltipProps) => {
  const filter = () => {
    switch (filterDropdownType) {
      case DEMOGRAPHIC_NAMES.specialty:
        return FILTER_OPTIONS.SPECIALTY;
      case DEMOGRAPHIC_NAMES.practiceSetting:
        return FILTER_OPTIONS.PRACTICE;
      case DEMOGRAPHIC_NAMES.provinceOfPractice:
        return FILTER_OPTIONS.REGION;
      case DEMOGRAPHIC_NAMES.segment:
        return FILTER_OPTIONS.SEGMENT;
      default:
        return filterDropdownType;
    }
  };

  return (
    <Tooltip
      label={i18n.t('common.filterBy', {
        filterOption: filter(),
      })}
      placement="top"
      hasArrow
    >
      <Box w={'100%'}>
        <FilterDropdownComponent
          label={filter()}
          type={filterDropdownType}
          options={filterDropdownOptions}
          demographicName={filterDropdownType}
          visualization={visualization}
        />
      </Box>
    </Tooltip>
  );
};

const FilterBar = ({ visualization }: { visualization: string }) => {
  const { DEFAULT_FILTER_GROUPS } = useFilterProvider();

  if (DEFAULT_FILTER_GROUPS.length === 0) return null;
  return (
    <HStack spacing={2}>
      {DEFAULT_FILTER_GROUPS.map((filterGroup) => (
        <FilterWithTooltip
          key={filterGroup.type}
          filterDropdownType={filterGroup.type}
          filterDropdownOptions={filterGroup.options}
          visualization={visualization}
        />
      ))}
    </HStack>
  );
};

export default FilterBar;
