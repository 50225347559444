import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Center,
  Flex,
  IconButton,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';

import KeyOps from '../../../assets/images/KeyOps.png';
import KeyOpsLogo from '../../../assets/images/KeyOpsLogo';
import { DASHBOARD } from '../../../utils/internal-routes.const';

import NavItem from './NavItem';
import { LogoutSidebarNavItem, SidebarNavItems } from './SidebarNavs';

const LeftChevronIcon = () => (
  <ChevronLeftIcon boxSize={6} color={useColorModeValue('gray.700', 'white')} />
);

const RightChevronIcon = () => (
  <ChevronRightIcon
    boxSize={6}
    color={useColorModeValue('gray.700', 'white')}
  />
);

interface SidebarProps extends BoxProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar = ({ isSidebarOpen, toggleSidebar }: SidebarProps) => {
  const navigate = useNavigate();

  return (
    <Box
      data-testid={'sidebar-component'}
      bgGradient="linear-gradient(180deg, rgba(219, 228, 234, 0) 0%, #F7FAFC 60.42%)"
      display={'block'}
      w={isSidebarOpen ? 60 : 20}
      pos="fixed"
      h="full"
      p={4}
      transition={'all 0.2s ease'}
    >
      <Flex h={'100%'} direction={'column'} justifyContent={'space-between'}>
        <Box>
          <IconButton
            aria-label={'drawer-toggle'}
            rounded={'full'}
            bg={useColorModeValue('white', 'gray.700')}
            size={'sm'}
            boxShadow={'lg'}
            ml={isSidebarOpen ? '205px' : 12}
            onClick={toggleSidebar}
            icon={isSidebarOpen ? <LeftChevronIcon /> : <RightChevronIcon />}
          />
          <Center
            mt={-1}
            mb={10}
            _hover={{ cursor: 'pointer' }}
            onClick={() => navigate(DASHBOARD)}
            aria-label={'KeyOps-Logo'}
          >
            {isSidebarOpen ? (
              <KeyOpsLogo />
            ) : (
              <Image src={KeyOps} h={'46px'} alt="Physicians-Img" />
            )}
          </Center>
          <Box>
            {SidebarNavItems.map((link) => (
              <NavItem
                key={link.name}
                icon={link.icon}
                name={link.name}
                path={link.path}
                hidden={link.hidden}
                isOpen={isSidebarOpen}
              />
            ))}
          </Box>
        </Box>
        <Box>
          {LogoutSidebarNavItem.map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              name={link.name}
              path={link.path}
              isOpen={isSidebarOpen}
            />
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default Sidebar;
