import {
  AggregateAnswer,
  AggregateQuestion,
  IndividualAnswer,
  IndividualQuestion,
  QuestionChoice,
  ResponseDetails,
} from '@keyops-cep/api-client';
import { sortBy } from 'lodash';

import { ResponseFilters } from '../../HOCs/Filter/FilterProvider';
import i18n from '../i18n';

const createFilterLabels = (values: number[]): string[] => {
  const maxValue = Math.max(...values);
  let maxRange = 0;

  if (maxValue <= 10) {
    // If the max value is less than or equal to 10, show each value as a distinct option.
    return values.map((value) => `${value}`);
  } else if (maxValue <= 20) {
    maxRange = 20;
  } else if (maxValue <= 100) {
    maxRange = 100;
  } else {
    // Round up to the nearest multiple of 100 for values greater than 100.
    maxRange = Math.ceil(maxValue / 100) * 100;
  }

  const filterLabels: string[] = [];

  for (let i = 1; i <= maxRange; i += maxRange / 4) {
    const startRange = Math.floor(i);
    const endRange = Math.min(Math.floor(i + maxRange / 4 - 1), maxRange);
    const label = `${startRange} - ${endRange}`;
    filterLabels.push(label);
  }

  return filterLabels;
};

export type MultiChoiceQuestion = {
  questionId: string;
  questionType: 'MultiChoice';
  optionChoices: QuestionChoice[] | undefined;
  responses?: ResponseDetails[];
};

export type NumberQuestions = {
  questionId: string;
  questionType: 'NumberInput' | 'Slider' | 'OpinionScale' | 'YesNo';
  optionChoices?: QuestionChoice[] | undefined;
  responses: ResponseDetails[];
};

export type YesNoQuestions = {
  questionId: string;
  questionType: 'YesNo';
  optionChoices?: QuestionChoice[] | undefined;
  responses: ResponseDetails[];
};

type OptionsForQuestionFilterProps =
  | MultiChoiceQuestion
  | NumberQuestions
  | YesNoQuestions;

export const getOptionsForQuestionFilter = ({
  questionId,
  questionType,
  optionChoices,
  responses,
}: OptionsForQuestionFilterProps) => {
  switch (questionType) {
    case 'MultiChoice':
      return sortBy(optionChoices, 'order').map((choice) => choice.label);
    case 'NumberInput':
    case 'OpinionScale':
    case 'Slider': {
      const availableOptions = responses
        .map((response: ResponseDetails) =>
          response.answers.find((answer) => answer.questionId === questionId)
        )
        .filter((n: unknown) => n !== undefined) as IndividualAnswer[];
      const uniqueAvailableOptions = Array.from(
        new Set(availableOptions.map((answer) => answer.value))
      ).sort() as (string | number)[];
      const values = uniqueAvailableOptions
        .map((option) =>
          typeof option === 'string' ? parseInt(option, 10) : option
        )
        .filter((element) => !isNaN(element)) as number[];
      return createFilterLabels(values);
    }
    case 'YesNo': {
      return [
        i18n.t('visualization.histogram.yesNo.trueLabel'),
        i18n.t('visualization.histogram.yesNo.falseLabel'),
      ];
    }
    default:
      return [];
  }
};

type QueFilteredResponsesProps = {
  ans: IndividualAnswer | AggregateAnswer;
  filter: ResponseFilters;
  selectedQuestion: IndividualQuestion | AggregateQuestion;
};

const getMinAndMax = (
  filter: ResponseFilters
): { min: number; max: number } => {
  if (filter.option.includes('-')) {
    const [min, max] = filter.option.split('-').map(Number);
    return { min, max };
  } else {
    const value = parseInt(filter.option, 10);
    return { min: value, max: value };
  }
};

export const getQueFilteredResponses = ({
  ans,
  filter,
  selectedQuestion,
}: QueFilteredResponsesProps) => {
  if (
    ans.questionId !== filter.questionId ||
    !Object.keys(ans).includes('value')
  ) {
    return false;
  }

  const individualAnswer = ans as IndividualAnswer;

  switch (selectedQuestion?.type) {
    case 'NumberInput':
    case 'OpinionScale':
    case 'Slider': {
      const { min, max } = getMinAndMax(filter);
      const ansValue =
        typeof individualAnswer.value === 'string'
          ? parseInt(individualAnswer.value, 10)
          : individualAnswer.value;
      return typeof ansValue === 'number' && ansValue >= min && ansValue <= max;
    }
    case 'MultiChoice':
      if (selectedQuestion.subType === 'Single-Select') {
        return (
          (typeof individualAnswer.value === 'string' &&
            individualAnswer.value.toLowerCase() ===
              filter.option.toLowerCase()) ||
          (typeof individualAnswer.value === 'number' &&
            individualAnswer.value === parseInt(filter.option, 10))
        );
      } else if (selectedQuestion.subType === 'Multi-Select') {
        return (
          typeof individualAnswer.value === 'object' &&
          individualAnswer.value.includes(filter.option)
        );
      }
      break;
    case 'YesNo':
      if (filter.option === i18n.t('visualization.histogram.yesNo.trueLabel'))
        return individualAnswer.value === true;
      else if (
        filter.option === i18n.t('visualization.histogram.yesNo.falseLabel')
      )
        return individualAnswer.value === false;
      break;
    default:
      return false;
  }
  return false;
};
