import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';

import i18n from '../../utils/i18n';

const RowDataEngagementDetails = () => {
  return (
    <Box aria-label="row-data-engagement-details" mt={6}>
      <Alert status="info">
        <AlertIcon />
        <Box>
          <AlertTitle>
            {i18n.t('engagementDetails.insightsAlert.title')}
          </AlertTitle>
          <AlertDescription>
            {i18n.t('engagementDetails.insightsAlert.subTitle')}
          </AlertDescription>
        </Box>
      </Alert>
      <Box mt={10}>
        <Flex>
          <Box w="48%" pr={10}>
            <SkeletonText
              data-testid={'skeleton-text'}
              noOfLines={3}
              spacing="4"
            />
            <SkeletonText
              data-testid={'skeleton-text'}
              mt="4"
              noOfLines={3}
              spacing="4"
            />
          </Box>
          <Box flex="1">
            <Skeleton data-testid={'skeleton'} h={'100%'} />
          </Box>
        </Flex>
        <Flex mt={8}>
          <Box w="52%">
            <Skeleton data-testid={'skeleton'} h={'100%'} />
          </Box>
          <Box flex="1" pl={10}>
            <SkeletonText
              data-testid={'skeleton-text'}
              noOfLines={3}
              spacing="4"
            />
            <SkeletonText
              data-testid={'skeleton-text'}
              mt="4"
              noOfLines={3}
              spacing="4"
            />
          </Box>
        </Flex>
        <Flex mt={8}>
          <Box w="48%" pr={10}>
            <SkeletonText
              data-testid={'skeleton-text'}
              noOfLines={3}
              spacing="4"
            />
            <SkeletonText
              data-testid={'skeleton-text'}
              mt="4"
              noOfLines={3}
              spacing="4"
            />
          </Box>
          <Box flex="1">
            <Skeleton data-testid={'skeleton'} h={'100%'} />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default RowDataEngagementDetails;
