// External Dependencies
import React, { RefObject, useState } from 'react';
import { BiExpand } from 'react-icons/bi';
import { FiDownload } from 'react-icons/fi';
import { MdOutlineContentCopy } from 'react-icons/md';
import { IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { AggregateQuestion, IndividualQuestion } from '@keyops-cep/api-client';

import { ANALYTICS_EVENT_NAME } from '../../utils/constants/analytics-constants';
import i18n from '../../utils/i18n';

// Internal Dependencies
import { VisualizationChartRef } from '.';

type VisualizationCardOptionsProps = {
  visualizationRef: RefObject<VisualizationChartRef>;
  question: IndividualQuestion | AggregateQuestion;
  onExpand: () => void;
};

const COPY_TEXT = i18n.t('visualization.options.copy');
const COPIED_TEXT = i18n.t('visualization.options.copied');

const VisualizationCardOptions = ({
  visualizationRef,
  question,
  onExpand,
}: VisualizationCardOptionsProps): JSX.Element => {
  const [tooltipText, setTooltipText] = useState<string>(COPY_TEXT);
  const [isExpandTooltipOpen, setExpandTooltipOpen] = useState(false);

  const onVisualizationExpand = () => {
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.VISUALIZATION_EXPAND,
      {
        engagementId: question.engagementId,
        questionId: question.id,
        position: question.position,
      }
    );
    setExpandTooltipOpen(false);
    onExpand();
  };

  const onDownload = () => {
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.VISUALIZATION_DOWNLOAD,
      {
        engagementId: question.engagementId,
        questionId: question.id,
        position: question.position,
      }
    );
    if (visualizationRef.current && visualizationRef.current.onDownload) {
      visualizationRef.current.onDownload(question.label);
    }
  };

  const onCopyToClipboard = () => {
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.VISUALIZATION_COPY,
      {
        engagementId: question.engagementId,
        questionId: question.id,
        position: question.position,
      }
    );
    if (
      visualizationRef.current &&
      visualizationRef.current.onCopyToClipboard
    ) {
      visualizationRef.current.onCopyToClipboard();

      setTooltipText(COPIED_TEXT);
      setTimeout(() => setTooltipText(COPY_TEXT), 2000);
    }
  };

  const handleTooltipOpen = () => {
    if (tooltipText === COPIED_TEXT) {
      setTooltipText(COPY_TEXT);
    }
  };

  return (
    <VStack spacing={1} mt={2}>
      <Tooltip
        isOpen={isExpandTooltipOpen}
        label="Expand"
        placement="right"
        hasArrow
        color="white"
        bg="keyops.blue"
      >
        <IconButton
          size="sm"
          borderLeftRadius="0"
          color="keyops.blue"
          aria-label="chart-expand"
          icon={<BiExpand />}
          onClick={onVisualizationExpand}
          onMouseEnter={() => setExpandTooltipOpen(true)}
          onMouseLeave={() => setExpandTooltipOpen(false)}
        />
      </Tooltip>
      <Tooltip
        label={i18n.t('visualization.options.export')}
        placement="right"
        hasArrow
        color="white"
        bg="keyops.blue"
      >
        <IconButton
          size="sm"
          borderLeftRadius="0"
          color="keyops.blue"
          aria-label="chart-download"
          icon={<FiDownload />}
          onClick={onDownload}
        />
      </Tooltip>
      <Tooltip
        label={tooltipText}
        placement="right"
        hasArrow
        color="white"
        bg="keyops.blue"
        closeOnClick={false}
        onOpen={handleTooltipOpen}
      >
        <IconButton
          size="sm"
          borderLeftRadius="0"
          color="keyops.blue"
          aria-label="chart-copy"
          icon={<MdOutlineContentCopy />}
          onClick={onCopyToClipboard}
        />
      </Tooltip>
    </VStack>
  );
};

export default VisualizationCardOptions;
