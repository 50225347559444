import { ChangeEvent, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { Box, Flex } from '@chakra-ui/react';

import {
  KeyOpsLinkButton,
  KeyOpsSecondaryButton,
} from '../../../components/ButtonComponents';
import SearchInputComponent from '../../../components/SearchInputComponent';
import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import i18n from '../../../utils/i18n';

interface SearchAndFilterBarProps {
  searchQuery: string;
  handleSearchQueryChange: (event: ChangeEvent<HTMLInputElement>) => void;
  clearSearchQuery: () => void;
  toggleFilterDrawer: () => void;
}

const SearchAndFilterBar = ({
  searchQuery,
  handleSearchQueryChange,
  clearSearchQuery,
  toggleFilterDrawer,
}: SearchAndFilterBarProps): JSX.Element => {
  const { selectedFilters, clearAllFilters } = useFilterProvider();
  const [loading, setLoading] = useState<boolean>(false);

  const removeAllFilters = async () => {
    setLoading(true);
    await clearAllFilters();
    setLoading(false);
  };

  return (
    <Flex alignItems={'center'}>
      <Box w={'55%'}>
        <SearchInputComponent
          value={searchQuery}
          onChange={handleSearchQueryChange}
          onClear={clearSearchQuery}
        />
      </Box>
      <KeyOpsSecondaryButton
        leftIcon={<FiFilter />}
        size={'md'}
        ml={4}
        onClick={toggleFilterDrawer}
      >
        {i18n.t('engagementDetails.filter.filterBtn')}{' '}
        {selectedFilters.length > 0 && `(${selectedFilters.length})`}
      </KeyOpsSecondaryButton>
      <KeyOpsLinkButton ml={4} isLoading={loading} onClick={removeAllFilters}>
        {i18n.t('engagementDetails.filter.removeFilters')}
      </KeyOpsLinkButton>
    </Flex>
  );
};

export default SearchAndFilterBar;
