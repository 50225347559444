// External Dependencies
import React from 'react';
import { FiCopy, FiHelpCircle } from 'react-icons/fi';
import { Stack, Text } from '@chakra-ui/react';

// Internal Dependencies
import { KeyOpsTooltip } from '../../../../components/KeyOpsTooltip';
import i18n from '../../../../utils/i18n';

export type ChatHeaderInteractiveContentProps = {
  copyConversation: () => void;
  isCopied: boolean;
  openFAQ: () => void;
};

export const ChatHeaderInteractiveContent = ({
  copyConversation,
  isCopied,
  openFAQ,
}: ChatHeaderInteractiveContentProps) => {
  return (
    <>
      <Text color="black">Messages</Text>
      <Stack
        data-testid="engage-ai-chat-header-interactive-content"
        direction={'row'}
        spacing={4}
        align={'center'}
        justify={'flex-end'}
        color="keyops.blue"
      >
        <KeyOpsTooltip
          hasArrow
          closeOnClick={false}
          label={
            isCopied
              ? i18n.t('engagementDetails.engageAi.chatCopied')
              : i18n.t('engagementDetails.engageAi.chatCopy')
          }
          aria-label="EngageAI copy conversation tooltip"
          placement="top"
        >
          <FiCopy size="24px" onClick={copyConversation} cursor="pointer" />
        </KeyOpsTooltip>
        <KeyOpsTooltip
          hasArrow
          label={i18n.t('engagementDetails.engageAi.FAQ')}
          aria-label="EngageAI FAQ tooltip "
          placement="top"
          mr={2}
        >
          <FiHelpCircle
            size="24px"
            onClick={openFAQ}
            cursor="pointer"
            data-testid="engage-ai-faq-icon"
          />
        </KeyOpsTooltip>
      </Stack>
    </>
  );
};
