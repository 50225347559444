import React, { useEffect, useState } from 'react';
import { FiChevronUp, FiFilter } from 'react-icons/fi';
import {
  Box,
  Divider,
  Icon,
  IconButton,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import { ENGAGEMENT_STATUSES } from '../../../utils/dto/engagement.dto';

interface ScrollButtonProps {
  engagementStatus: string;
  toggleFilterDrawer: () => void;
}

const ScrollButton = ({
  engagementStatus,
  toggleFilterDrawer,
}: ScrollButtonProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const { selectedFilters } = useFilterProvider();

  // Show button when page is scrolled down by 300px
  const toggleVisibility = () => {
    if (window.scrollY > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <Box position="fixed" bottom={32} right={0}>
      {isVisible && (
        <VStack
          spacing={0}
          color={'keyops.blue'}
          borderTopLeftRadius={8}
          borderBottomLeftRadius={8}
          shadow={
            '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)'
          }
        >
          {engagementStatus === ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE && (
            <>
              <Tooltip
                hasArrow
                label="Filter"
                placement="left"
                bg={'keyops.blue'}
              >
                <IconButton
                  aria-label="filter"
                  icon={
                    <Box pos="relative">
                      <FiFilter fontSize={18} />
                      {selectedFilters.length > 0 && (
                        <Icon
                          viewBox="0 0 200 200"
                          boxSize={2.5}
                          pos="absolute"
                          color="red.500"
                          right={-1}
                          top={-1}
                        >
                          <path
                            fill="currentColor"
                            d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                          />
                        </Icon>
                      )}
                    </Box>
                  }
                  borderTopRightRadius={0}
                  borderBottomRadius={0}
                  bg={'white'}
                  onClick={toggleFilterDrawer}
                />
              </Tooltip>
              <Divider borderColor={borderColor} />
            </>
          )}
          <Tooltip
            hasArrow
            label="Back to top"
            placement="left"
            bg={'keyops.blue'}
          >
            <IconButton
              aria-label="Back to top"
              icon={<FiChevronUp />}
              fontSize={28}
              borderTopLeftRadius={0}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              bg={'white'}
              onClick={scrollToTop}
            />
          </Tooltip>
        </VStack>
      )}
    </Box>
  );
};

export default ScrollButton;
