import React, { useEffect } from 'react';

import useAuth from '../../HOCs/auth/useAuth';

const Logout: React.FC = () => {
  const { logoutFromApi } = useAuth();

  useEffect(() => {
    logoutFromApi();
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Logout;
