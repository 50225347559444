import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import i18n from '../../../utils/i18n';

const EngagementTitle = () => {
  const { engagementDetails } = useFilterProvider();
  return (
    <Box>
      <Heading
        as="h1"
        fontSize={'xl'}
        aria-label="engagement-title"
        data-testid="engagement-title"
      >
        {engagementDetails.name}
      </Heading>
      <Text fontSize="md" mt={2}>
        {engagementDetails.displayTitle && engagementDetails.displayTitle !== ''
          ? engagementDetails.displayTitle
          : i18n.t('engagementDetails.subTitle')}
      </Text>
    </Box>
  );
};

export default EngagementTitle;
