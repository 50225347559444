import i18n from '../i18n';

export enum Role {
  systemHidden = 'system-hidden',
  system = 'system',
  assistant = 'assistant',
  user = 'user',
}

export type RoleType = 'system-hidden' | 'system' | 'assistant' | 'user';

// EngageAI: First messages displayed to the customer
export const initialAssistantMessages = [
  {
    role: Role.assistant,
    content: i18n.t('engagementDetails.engageAi.firstInitialMessage'),
    createdAt: new Date(Date.now()).toLocaleDateString(),
  },
  {
    role: Role.assistant,
    content: i18n.t('engagementDetails.engageAi.lastInitialMessage'),
    createdAt: new Date(Date.now()).toLocaleDateString(),
  },
];

export enum ChatStatus {
  inProgress = 'in_progress',
  queued = 'queued',
  completed = 'completed',
  failed = 'failed',
  cancelled = 'cancelled',
  expired = 'expired',
  requiresAction = 'requires_action',
}
