import React, { MutableRefObject, useEffect, useId, useRef } from 'react';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Chart } from 'chart.js/auto';

import { CHART_COLORS } from '../../../utils/constants/visualization-constants';
import i18n from '../../../utils/i18n';

import { htmlLegendPlugin } from './html-legend-plugin';
import { labelOnDoughnut } from './label-on-doughnut-plugin';

export type ChartData = { label: string; value: number };
//number of data points to show in chart, if more than this the smaller set will be aggregated.
const maxDataPoints = 6;
//any thing at or below this percentage is omitted as being too trivial
const cutoffPercentage = 4;
//any thing at or below this percentage is omitted as being too trivial
const minDataPointsBeforeCutoff = 1;

const getChartData = (data: Record<string, number>) => {
  const flatData: ChartData[] = [];
  for (const key in data) {
    flatData.push({
      label: key,
      value: data[key],
    });
  }

  const totalSum = flatData.reduce((sum, current) => sum + current.value, 0);

  const counts = flatData
    //convert value to percentage
    .map((count) => {
      count.value = (count.value / totalSum) * 100;
      return count;
    })
    //sort from most to least frequent
    .sort((a, b) => b.value - a.value);

  //we want to aggregate stuff with a tiny percentage of the graph
  //value could be -1 if nothing hits.
  const cutoffByPercentage = counts.findIndex(
    (count) => count.value <= cutoffPercentage
  );

  //establish the final cutoff, if percentage didn't return anything or we just have
  //lots of values
  const cutoff = (() => {
    const initialCutoff =
      cutoffByPercentage === -1 || cutoffByPercentage >= maxDataPoints
        ? maxDataPoints - 1
        : cutoffByPercentage;
    if (initialCutoff < minDataPointsBeforeCutoff) {
      return minDataPointsBeforeCutoff;
    }
    return initialCutoff;
  })();

  const cutoffCounts = (() => {
    if (cutoff >= counts.length - 1) return counts;

    //grab the first maxDataPoints - 1, we'll leave those as is
    const dataPoints = counts.slice(0, cutoff);
    //grab the remaining, we're going to consolidate these
    const remaining = counts.slice(cutoff);

    //go through and sum up remaining
    const others: ChartData = remaining.reduce(
      (others, chartData) => {
        others.value += chartData.value;
        return others;
      },
      {
        label: i18n.t('engagementDetails.kpiSection.othersLabel'),
        value: 0,
      } as ChartData
    );
    //push in the "others" option
    dataPoints.push(others);
    return dataPoints;
  })();

  //do another pass to round percentage
  cutoffCounts.forEach((count) => (count.value = Math.round(count.value)));
  return cutoffCounts;
};

const ChartCard = ({
  cardLabel,
  data,
}: {
  cardLabel?: string;
  data: Record<string, number>;
}) => {
  const legendContainerId = useId();

  const chartData = getChartData(data);
  const chartContainer = useRef<HTMLCanvasElement>(null);
  const chartInstance: MutableRefObject<Chart<'doughnut', ChartData[]> | null> =
    useRef(null);
  useEffect(() => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '70%',
      layout: {
        padding: 14,
      },
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: (context: any) => {
              const parsed = context.parsed;
              if (parsed) {
                return parsed + '%';
              } else {
                return parsed;
              }
            },
          },
        },
        legend: {
          display: false,
        },
        htmlLegend: {
          containerID: legendContainerId,
        },
      },
    };
    if (chartContainer.current) {
      chartInstance.current = new Chart(chartContainer.current, {
        type: 'doughnut',
        data: {
          labels: chartData.map((data) => data.label),
          datasets: [
            {
              data: chartData,
              backgroundColor: CHART_COLORS,
              borderColor: '#ffffff',
              borderWidth: 2,
              //spacing in chartjs looks awful at small scales
              //it ends up rendering what looks like different spaces for each gap
              //using a white border instead is much more consistent.
              spacing: 0,
            },
          ],
        },
        options,
        plugins: [htmlLegendPlugin, labelOnDoughnut(chartData)],
      });
    }

    // Clean up the chart instance when the component unmounts
    return () => {
      if (chartInstance) {
        chartInstance.current?.destroy();
      }
    };
  }, [chartData, legendContainerId]);
  return (
    <Card variant={'elevated'} h={'175'}>
      <CardHeader className="kpiHeaderText" pt={5} pb={3}>
        {cardLabel}
      </CardHeader>
      <CardBody h={'100%'} p={5} pt={0} overflow={'hidden'}>
        {data.length === 0 ? (
          <Center className="kpiText" h="100%">
            {i18n.t('engagementDetails.kpiSection.emptyMessage')}
          </Center>
        ) : (
          <Grid templateColumns="repeat(2, 1fr)">
            <GridItem position="relative">
              <Center width={200} height={120} pb={2}>
                <canvas
                  id={`chart-${cardLabel}`}
                  ref={chartContainer}
                  style={data.length === 0 ? { display: 'none' } : {}}
                ></canvas>
              </Center>
            </GridItem>
            <GridItem pt={1}>
              <Box
                id={legendContainerId}
                style={{ display: 'flex', alignItems: 'center', height: 120 }}
              ></Box>
            </GridItem>
          </Grid>
        )}
      </CardBody>
    </Card>
  );
};

export default ChartCard;
