import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AggregateQuestion,
  EngagementDetailsDto,
  IndividualQuestion,
  ResponseDetails,
} from '@keyops-cep/api-client';
import { groupBy } from 'lodash';

import { ANALYTICS_EVENT_NAME } from '../../utils/constants/analytics-constants';
import { DEMOGRAPHIC_NAMES } from '../../utils/constants/visualization-constants';
import {
  getOptionsForQuestionFilter,
  getQueFilteredResponses,
} from '../../utils/functions/engagements-details-filter-utils';

import FilterContext from './FilterContext';

interface FilterProviderProps {
  engagementDetails: EngagementDetailsDto;
}

export interface ResponseFilters {
  type: string;
  questionId?: string;
  option: string;
  checked: boolean;
}
export interface FilterGroupsType {
  questionId?: string;
  type: string;
  options: string[];
}

// Returns the questionId of a filter, if it is not a demographic and if it has a questionId
export const getSelectedQuestionId = (
  filters: ResponseFilters[],
  demographicNames: string[]
) =>
  filters.find(
    (filter) => !demographicNames.includes(filter.type) && !!filter.questionId
  )?.questionId;

type GroupedFilters = {
  [key: string]: ResponseFilters[];
};

/* shouldIncludeResponse determines whether a given response matches the criteria specified by the filters.
 * Returns true if the response satisfies all the filter criteria and should be included in the final result set,
 * Returns false otherwise.
 */
export const shouldIncludeResponse = (
  response: ResponseDetails,
  groupedFilters: GroupedFilters,
  demographicNames: string[],
  selectedQuestion: IndividualQuestion | AggregateQuestion | undefined
) => {
  // 1. All responses should be included
  // groupedFilters is empty => there are no filters applied
  if (Object.keys(groupedFilters).length === 0) {
    return true;
  }

  // For each group, it checks if the response satisfies at least one filter in that group.
  return Object.entries(groupedFilters).every(([groupKey, filters]) => {
    return filters.some((filter) =>
      checkFilterType(response, filter, demographicNames, selectedQuestion)
    );
  });
};

/*
 * filterResponses filters a set of responses based on specified criteria
 * typically involving demographic and question-related filters.
 * It determines which responses from a dataset should be included or excluded based on the matching conditions defined in the filters.
 * It returns the included responses
 */
export const filterResponses = (
  engagementDetails: EngagementDetailsDto,
  newFilters: ResponseFilters[]
) => {
  const groupedFilters = groupBy(newFilters, 'type'); // Grouping filters by their type
  const demographicNamesValues = Object.values(DEMOGRAPHIC_NAMES); // All demographic values in an array
  engagementDetails?.aggregateRespondentDemographics?.customDemographics?.forEach(
    (demo) => {
      demographicNamesValues.push(demo.name);
    }
  );

  // Returns, if any, a questionId
  const selectedQuestionId = getSelectedQuestionId(
    newFilters,
    demographicNamesValues
  );
  const selectedQuestion = engagementDetails.questions.find(
    (question) => question.id === selectedQuestionId
  );

  const filteredResponses = engagementDetails.responses.filter((response) =>
    shouldIncludeResponse(
      response,
      groupedFilters,
      demographicNamesValues,
      selectedQuestion
    )
  );

  return filteredResponses;
};

// Determines if the filter is a demographic filter or a question filter based on whether the filter's type is in demographicNames.
const checkFilterType = (
  response: ResponseDetails,
  filter: ResponseFilters,
  demographicNames: string[],
  selectedQuestion: IndividualQuestion | AggregateQuestion | undefined
) => {
  if (demographicNames.includes(filter.type)) {
    return checkDemographicFilter(response, filter);
  } else {
    return checkQuestionFilter(response, filter, selectedQuestion);
  }
};

// checkDemographicFilter checks whether a given response should be included based on a specific demographic filter
export const checkDemographicFilter = (
  response: ResponseDetails,
  filter: ResponseFilters
) => {
  // Exclude this response from the filtered result
  if (!filter.checked || !response.respondentDemographics) {
    return false;
  }

  // If at least one demographic entry meets these 2 conditions, the response matches the filter criteria.
  const shouldResponseBeReturned = response.respondentDemographics.some(
    (demo) => demo.name === filter.type && demo.value === filter.option
  );

  return shouldResponseBeReturned;
};

// validate Question Filter
export const checkQuestionFilter = (
  response: ResponseDetails,
  filter: ResponseFilters,
  selectedQuestion: IndividualQuestion | AggregateQuestion | undefined
) => {
  if (!filter.checked || !response.answers || !selectedQuestion) {
    return false;
  }

  return response.answers.some((ans) =>
    getQueFilteredResponses({ ans, filter, selectedQuestion })
  );
};

export const getDefaultFilterGroups = (
  engagementDetails: EngagementDetailsDto
) => {
  if (engagementDetails.hideDemographics) return [];
  const demographicFilters = Object.keys(DEMOGRAPHIC_NAMES).filter(
    (key) => key !== 'licenseYear'
  ) as (keyof typeof DEMOGRAPHIC_NAMES)[];

  //  TODO: put formatting operation in BE to avoid issues
  const demographicFilterGroups = demographicFilters
    .filter(
      (ele): ele is Exclude<typeof ele, 'licenseYear'> => ele !== 'licenseYear'
    )
    .map((ele) => {
      const options = Object.keys(
        engagementDetails?.aggregateRespondentDemographics?.[ele] ?? {}
      );

      if (options.length > 0) {
        return {
          type: DEMOGRAPHIC_NAMES[ele],
          options: options.sort(),
        };
      }

      return null;
    })
    .filter(Boolean) as FilterGroupsType[];

  engagementDetails?.aggregateRespondentDemographics?.customDemographics?.forEach(
    (demo) => {
      const options = Object.keys(demo.value ?? {});
      if (options.length > 0) {
        demographicFilterGroups.push({
          type: demo.name,
          options: options.sort(),
        });
      }
    }
  );

  return demographicFilterGroups;
};

const FilterProvider: React.FC<{
  children: ReactNode;
  value: FilterProviderProps;
}> = ({ children, value }) => {
  const { engagementDetails } = value;
  const [engagementResponses, setEngagementResponses] = useState([
    ...engagementDetails.responses,
  ]);
  const [filterGroups, setFilterGroups] = useState<FilterGroupsType[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<ResponseFilters[]>([]);
  const [breakoutDemographic, setBreakoutDemographic] = useState<
    string | undefined
  >(undefined);

  const DEFAULT_FILTER_GROUPS = useMemo(() => {
    return getDefaultFilterGroups(engagementDetails);
  }, [engagementDetails]);

  useEffect(() => {
    setFilterGroups(DEFAULT_FILTER_GROUPS);
  }, [DEFAULT_FILTER_GROUPS]);

  const removeQuestionFilter = () => {
    setFilterGroups([...DEFAULT_FILTER_GROUPS]);
    const currentFilterGroups = DEFAULT_FILTER_GROUPS.map(
      (filterGroup) => filterGroup.type
    );
    // for global filters
    const hasExtraFilter =
      selectedFilters.filter(
        (filter) => currentFilterGroups.indexOf(filter.type) === -1
      ).length > 0;
    if (hasExtraFilter) {
      const newFilterList = selectedFilters.filter(
        (filter) => currentFilterGroups.indexOf(filter.type) !== -1
      );
      handleBulkSelectedFilters(newFilterList);
    }
  };

  const addQuestionFilter = (
    question: IndividualQuestion | AggregateQuestion
  ) => {
    const isQuestionInFilters = filterGroups.some(
      (filter) => filter.questionId === question.id
    );

    if (isQuestionInFilters) {
      removeQuestionFilter();
    } else {
      const options = getOptionsForQuestionFilter({
        questionId: question.id,
        questionType: question.type as
          | 'MultiChoice'
          | 'NumberInput'
          | 'Slider'
          | 'OpinionScale'
          | 'YesNo',
        optionChoices: question.choices,
        responses: engagementDetails.responses,
      });

      setFilterGroups([
        {
          questionId: question.id,
          type: `Question ${question.position + 1}`,
          options: question.type === 'YesNo' ? options : options?.sort(),
        },
        ...DEFAULT_FILTER_GROUPS,
      ]);
    }
  };

  const handleBulkSelectedFilters = (filters: ResponseFilters[]) => {
    setSelectedFilters(filters);
    handleFilterResponses(filters);
  };

  // handleSelectedFilters is called when filtering on the expanded view
  const handleSelectedFilters = useCallback(
    ({ type, option, checked }: ResponseFilters) => {
      // If the checkbox is checked, add it to the filters
      if (checked) {
        window.analytics.track(
          ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.ADD_FILTER,
          {
            engagementId: engagementDetails.id,
            type,
            option,
          }
        );
        setSelectedFilters((prevFilters) => [
          ...prevFilters,
          { type, option, checked },
        ]);
      }

      // If the checkbox is unchecked, remove it from the filters
      if (!checked) {
        window.analytics.track(
          ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.REMOVE_FILTER,
          {
            engagementId: engagementDetails.id,
            type,
            option,
          }
        );

        setSelectedFilters((prevFilters) =>
          prevFilters.filter((filter) => filter.option !== option)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clearAllFilters = () => {
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.CLEAR_ALL_FILTER,
      {
        engagementId: engagementDetails.id,
      }
    );
    setSelectedFilters([]);
    setBreakoutDemographic(undefined);
    handleFilterResponses([]);
  };

  /*
   * It calls setEngagementResponses with the included responses
   */
  const handleFilterResponses = useCallback((newFilters: ResponseFilters[]) => {
    const filteredResponses = filterResponses(engagementDetails, newFilters);
    setEngagementResponses([...filteredResponses]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This hook calls filterResponses anytime selectedFilters is updated to avoid asynchronous setState issues
  useEffect(() => {
    handleFilterResponses(selectedFilters);
  }, [selectedFilters, handleFilterResponses]);

  return (
    <FilterContext.Provider
      value={{
        engagementDetails,
        engagementResponses,
        selectedFilters,
        handleBulkSelectedFilters,
        handleSelectedFilters,
        clearAllFilters,
        filterGroups,
        addQuestionFilter,
        removeQuestionFilter,
        DEFAULT_FILTER_GROUPS,
        breakoutDemographic,
        setBreakoutDemographic,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
