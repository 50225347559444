import { AggregateQuestion, IndividualQuestion } from '@keyops-cep/api-client';

export function splitStringIntoChunks(
  str: string,
  maxChars: number,
  maxLines: number
) {
  //3 lines max
  const chunks: string[] = [];
  let currentChunk = '';
  let truncated = false;

  str.split(' ').forEach((word: string) => {
    if (chunks.length < maxLines) {
      if ((currentChunk + word).length <= maxChars) {
        currentChunk += word + ' ';
      } else {
        if (currentChunk) {
          chunks.push(currentChunk.trim());
        }
        currentChunk = word + ' ';
      }
    } else {
      truncated = true;
    }
  });

  if (truncated) {
    chunks[maxLines - 1] += '...';
  } else if (currentChunk && chunks.length < maxLines) {
    chunks.push(currentChunk.trim());
  }

  return chunks;
}

// get latest previous average from annotations, if it is available
export const getPrevAvgAnnotation = (
  question: IndividualQuestion | AggregateQuestion
) =>
  question.questionAnnotations?.find(
    (annotation) => annotation.name === 'PreviousAverage'
  );
