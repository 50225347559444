import { MessageDto } from '@keyops-cep/api-client';

import { ChatStatus, Role } from '../constants/engage-ai';

import { getMinutesAgoFromString } from './common-utils';

export const copyEngageAIConversation = (
  messages: MessageDto[],
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let contentToCopy = '';
  messages.forEach((message) => {
    contentToCopy += `${message.role}, ${getMinutesAgoFromString(
      message.createdAt
    )}: ${message.content} \n`;
  });

  navigator.clipboard.writeText(contentToCopy);
  setIsCopied(true);
  // Reset the tooltip text after 2 seconds
  setTimeout(() => {
    setIsCopied(false);
  }, 2000);
};

// Handle the logic to display the chat avatar and message date
export const isLastMessageOrBeforeUserMessage = (
  index: number,
  messages: MessageDto[]
) => {
  return (
    (index + 1 < messages.length && messages[index + 1].role === Role.user) ||
    index === messages.length - 1
  );
};

export const isChatStatusError = (chatStatus: ChatStatus) => {
  if (
    chatStatus === ChatStatus.failed ||
    chatStatus === ChatStatus.cancelled ||
    chatStatus === ChatStatus.requiresAction ||
    chatStatus === ChatStatus.expired
  ) {
    return true;
  }
  return false;
};
