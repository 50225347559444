import { IconType } from 'react-icons';
import { FiHome, FiLogOut, FiSettings } from 'react-icons/fi';

import i18n from '../../../utils/i18n';
import {
  DASHBOARD,
  LOGOUT,
  TEST_PAGE,
} from '../../../utils/internal-routes.const';

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
  hidden?: boolean;
}

export const SidebarNavItems: Array<LinkItemProps> = [
  {
    name: i18n.t('sidebar.sidebarNavItems.home'),
    icon: FiHome,
    path: DASHBOARD,
  },
  {
    name: i18n.t('sidebar.sidebarNavItems.settings'),
    icon: FiSettings,
    path: TEST_PAGE,
    hidden: true,
  },
];

export const LogoutSidebarNavItem: Array<LinkItemProps> = [
  {
    name: i18n.t('sidebar.sidebarNavItems.logout'),
    icon: FiLogOut,
    path: LOGOUT,
  },
];
