// External Dependencies
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { MessageDto } from '@keyops-cep/api-client';

// Internal Dependencies
import MarkdownDisplay from '../../../../components/MarkdownDisplay';
import { Role } from '../../../../utils/constants/engage-ai';
import { getMinutesAgoFromString } from '../../../../utils/functions/common-utils';
import { isLastMessageOrBeforeUserMessage } from '../../../../utils/functions/engage-ai.utils';

import { KeyOpsChatIcon } from './KeyOpsChatIcon';

export type ChatMessagesProps = {
  messages: MessageDto[];
  errorMessage?: string;
};

export interface ChatMessagesHandles {
  focusLastMessage: () => void;
}

export const ChatMessages = forwardRef<ChatMessagesHandles, ChatMessagesProps>(
  ({ messages, errorMessage }, ref) => {
    // Use a ref for the container of messages
    const endOfMessagesRef = useRef<HTMLDivElement>(null);

    // This will expose the endOfMessagesRef to the parent component
    useImperativeHandle(ref, () => ({
      focusLastMessage: () => {
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.focus();
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      },
    }));
    useEffect(() => {
      const scrollToBottom = () => {
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

      scrollToBottom();
    }, [messages]);

    return (
      <Stack
        id="engage-ai-chat-messages"
        data-testid="engage-ai-chat-messages"
        overflowY={'scroll'}
        direction={'column'}
        tabIndex={-1}
        h={'75vh'}
        spacing="8px"
        p={'16px'}
        lineHeight="normal"
      >
        {messages.map((message, i) => {
          return (
            <Stack
              id={`engage-ai-message-row-${message.role}-${i}`}
              data-testid={`engage-ai-message-row-${message.role}-${i}`}
              key={i}
              direction={'column'}
              align={message.role === Role.user ? 'end' : 'start'}
            >
              <Stack
                direction={'row'}
                align={message.role === Role.user ? 'start' : 'self-end'}
                spacing={4}
                width={'fit-content'}
              >
                {/* ChatIcon Column: We want to keep it even when the chat icon is not displayed */}
                <Stack
                  id={`engage-ai-message-chat-icon-${i}`}
                  pb={typeof message.content === 'string' ? '30px' : 0}
                  w={'50px'}
                  minW={'50px'}
                >
                  {/* Display Stethy avatar if it's the last message and if it's the last of a serie of assistant's messages  */}
                  {message.role === Role.assistant &&
                    isLastMessageOrBeforeUserMessage(i, messages) && (
                      <KeyOpsChatIcon />
                    )}
                </Stack>
                <Stack id={`engageai-${message.role}-${i}`} width={'100%'}>
                  <Flex
                    align={'center'}
                    p={'8px 16px'}
                    w={'auto'}
                    // Adjust the display when it's the loading state
                    h={
                      typeof message.content === 'string'
                        ? 'fit-content'
                        : '50px'
                    }
                    minH="50px"
                    fontSize={'16px'}
                    borderRadius={
                      message.role === Role.user
                        ? '8px 8px 0px 8px;'
                        : '8px 8px 8px 0px;'
                    }
                    background={
                      message.role === Role.user
                        ? 'keyops.lighterBlue'
                        : ' var(--black-alpha-50, rgba(0, 0, 0, 0.04));'
                    }
                    color={'black'}
                  >
                    {typeof message.content === 'string' ? (
                      <MarkdownDisplay source={message.content} />
                    ) : (
                      message.content
                    )}
                  </Flex>

                  {/* Same rule for the date than for the chatbot avatar */}
                  {message.role === Role.assistant &&
                    typeof message.content === 'string' &&
                    isLastMessageOrBeforeUserMessage(i, messages) && (
                      <Box
                        color="gray.400"
                        fontSize={'12px'}
                        data-testid={`engage-ai-message-date-${i}`}
                      >
                        {getMinutesAgoFromString(message.createdAt)}
                      </Box>
                    )}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
        <div
          data-testid={`engage-ai-last-ref`}
          ref={endOfMessagesRef}
          tabIndex={0}
        />
        {errorMessage && (
          <Box
            id="engage-ai-chat-error-message"
            data-testid="engage-ai-chat-error-message"
            p={2}
            m={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            fontSize={'12px'}
            color={'gray.500'}
          >
            {errorMessage}
          </Box>
        )}
      </Stack>
    );
  }
);

// For debugging purposes
ChatMessages.displayName = 'ChatMessages';
