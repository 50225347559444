import { extendTheme } from '@chakra-ui/react';

const colors = {
  keyops: {
    teal: '#39BDAF',
    blue: '#3376D1',
    lighterBlue: '#F5FBFF',
    darkerBlue: '#2c5282',
    darkerBlue90: '#2c5282e6',
    gray: '#B3B3B3',
    visualizationBlue: '#7FB5E9',
  },
  filterDropdown: {
    500: '#4A5568',
  },
  selectedFiltersPillBorder: {
    500: '',
  },
};

const fonts = {
  heading: `'Inter', sans-serif`,
  body: `Inter, sans-serif`,
};

const customTheme = extendTheme({ colors, fonts });

export default customTheme;
