import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { FeatureFlagNameEnum } from '@keyops-cep/api-client';

import Loading from '../../../../components/Loading';
import useFilterProvider from '../../../../HOCs/Filter/useFilterProvider';
import useBEFeatureFlag from '../../../../hooks/useBEFeatureFlag';
import { useGetComparableEngagements } from '../../../../hooks/useGetComparableEngagements';
import NotFound from '../../../NotFound';

import NoComparableEng from './components/NoComparableEng';
import NoSelectedComparableEng from './components/NoSelectedComparableEng';
import SelectComparableEngDropdown from './components/SelectComparableEngDropdown';
import ComparisonTabs from './ComparisonTabs';

const ComparativeAnalysis = () => {
  const { comparableEngagementsId } = useParams();
  const { engagementDetails } = useFilterProvider();

  // check for the feature flag
  const { data: isComparisonFlagActive, isLoading: isFlagDataLoading } =
    useBEFeatureFlag(
      engagementDetails.tenantId,
      FeatureFlagNameEnum.CustomerShowComparison
    );

  // get the list of the comparable engagements
  const {
    isLoading,
    isError,
    data: comparableEngagementsList,
  } = useGetComparableEngagements(engagementDetails.id);

  if (isFlagDataLoading || isLoading) return <Loading />;
  if (!isComparisonFlagActive || isError) return <NotFound />;

  if (comparableEngagementsList?.length === 0)
    return (
      <Suspense fallback={<Loading />}>
        <NoComparableEng />
      </Suspense>
    );

  return (
    <Suspense fallback={<Loading />}>
      <SelectComparableEngDropdown
        comparableEngagementsList={comparableEngagementsList || []}
        selectedEngagementId={comparableEngagementsId}
      />
      <Box>
        {comparableEngagementsId ? (
          <ComparisonTabs />
        ) : (
          <NoSelectedComparableEng
            currentEngagementName={engagementDetails.name}
          />
        )}
      </Box>
    </Suspense>
  );
};

export default ComparativeAnalysis;
