import React from 'react';
import { Box, keyframes } from '@chakra-ui/react';

// Define the keyframes
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

// Define the animation
const dotAnimation = (delay: string) =>
  `${bounce} 1.4s infinite ease-in-out both ${delay}`;

const Dot: React.FC<{ delay: string }> = ({ delay }) => {
  return (
    <Box
      as="span"
      display="inline-block"
      w="2"
      h="2"
      m="1"
      bgColor="currentColor"
      borderRadius="full"
      animation={dotAnimation(delay)}
      color="keyops.blue"
    />
  );
};

export const LoadingThreeDots: React.FC = () => {
  return (
    <Box as="div" display="flex" data-testid="engage-ai-loading-dots">
      <Dot delay="0s" />
      <Dot delay=".2s" />
      <Dot delay=".4s" />
    </Box>
  );
};
