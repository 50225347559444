// External Dependencies
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { AggregateQuestion } from '@keyops-cep/api-client';
import { toPng } from 'html-to-image';

// Internal Dependencies
import { ISurveySparrowAnswerData } from '../../../pages/EngagementDetails/types';
import { VisualizationChartRef } from '..';

type MatrixProps = {
  question: AggregateQuestion;
  questionAnswers: (ISurveySparrowAnswerData | undefined)[];
};

interface Cell {
  label?: string;
  value: number;
  percent: string;
}

type MatrixRow = Cell[];
type MatrixBody = MatrixRow[];

interface RowData {
  [key: string]: number; // Allow any properties with a number value
  count: number;
}

interface AnswerCounts {
  [questionLabel: string]: RowData;
}
const captureAnswerValueIncrement = (
  answerCounts: AnswerCounts,
  questionLabel: string,
  value: string | number
) => {
  if (!answerCounts[questionLabel][value]) {
    answerCounts[questionLabel][value] = 0;
  }

  answerCounts[questionLabel][value]++;
};

const captureAnswerValue = (
  answerCounts: AnswerCounts,
  questionLabel: string,
  value: string | number | string[]
) => {
  if (!answerCounts[questionLabel]) {
    answerCounts[questionLabel] = { count: 0 };
  }
  if (Array.isArray(value)) {
    value.forEach((val) =>
      captureAnswerValueIncrement(answerCounts, questionLabel, val)
    );
  } else {
    captureAnswerValueIncrement(answerCounts, questionLabel, value);
  }
  answerCounts[questionLabel].count++;
};

const Matrix = forwardRef<VisualizationChartRef, MatrixProps>(
  ({ question, questionAnswers }, ref) => {
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [headers, setHeaders] = useState<React.ReactElement[]>([]);
    const [matrix, setMatrix] = useState<MatrixBody>([]);

    const cellStyle = {
      textTransform: 'capitalize',
      fontSize: '12px',
      padding: '8px',
      maxWidth: '24px',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
    };

    useEffect(() => {
      const questionComponents = question.components || [];
      const answerComponents = questionAnswers.map(
        (answer) => answer?.components
      );

      // Create an object to hold the counts of each answer for each row
      const answerCounts: AnswerCounts = {};
      answerComponents.forEach((row, rowIndex) => {
        row?.forEach((answer) => {
          const { value, questionComponentSurveySparrowId } = answer;
          const questionLabel = questionComponents.find(
            (q) => q.surveySparrowId === questionComponentSurveySparrowId
          )?.label;
          if (!questionLabel) {
            return;
          }
          captureAnswerValue(answerCounts, questionLabel, value);
        });
      });

      // Extract the unique column labels from the choices if available or the answerCounts object if not
      // and sort them.  We sort by choice order if we're using choices, or by natural order of value
      // if we're extracting from answers
      const columnLabels = question.choices
        ? question.choices
            .sort((choiceA, choiceB) => choiceA.order - choiceB.order)
            .map((choice) => choice.label)
        : [...new Set(answerComponents.flat().map((ans) => ans?.value))].sort();
      const headers: React.ReactElement[] = columnLabels.map((label, i) => {
        return (
          <Th key={`matrix-${question.id}-header-${i}`} sx={cellStyle}>
            {label}
          </Th>
        );
      });

      headers.unshift(
        <Th key={`matrix-${question.id}-header-empty`} sx={cellStyle}></Th>
      );
      setHeaders(headers);

      const formatPercent = (value: number, total: number) => {
        if (!value || !total) {
          return '(0.00%)';
        }
        return `(${(100 * (value / total)).toFixed(2)}%)`;
      };

      // Create the matrix array with rows and totals
      const matrix: MatrixBody = questionComponents.map((q) => {
        const row = [
          {
            label: q.label,
            value: 0,
            percent: '0%',
          },
        ];
        columnLabels.forEach((col) => {
          if (typeof col === 'string') {
            const value = answerCounts[q.label]?.[col] || 0;
            if (!answerCounts[q.label]?.count) {
              //if no denominator, show "N/A"
              row.push({
                label: 'N/A',
                value: 0,
                percent: '0%',
              });
            } else {
              row.push({
                label: '',
                value,
                percent: formatPercent(value, answerCounts[q.label]?.count),
              });
            }
          }
        });
        return row;
      });

      setMatrix(matrix);
    }, [questionAnswers]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => {
      const createImageFromTable = async (): Promise<string | null> => {
        if (!tableRef.current) return null;

        // Create a clone of the table
        const clone = tableRef.current.cloneNode(true) as HTMLDivElement;

        // Get computed width of the original table
        const originalTableWidth = getComputedStyle(tableRef.current).width;

        // Make the clone fully visible and hide the scrollbars
        clone.style.height = 'auto';
        clone.style.maxHeight = 'none';
        clone.style.overflowY = 'visible';
        clone.style.overflow = 'hidden'; // Hide scrollbars
        clone.style.width = originalTableWidth; // Set width of the clone to match original table

        // Append the clone to the body
        document.body.appendChild(clone);

        try {
          // Take screenshot of the clone
          const dataUrl = await toPng(clone);
          return dataUrl;
        } catch (error) {
          console.error('oops, something went wrong!', error);
        } finally {
          // Remove the clone from the body
          document.body.removeChild(clone);
        }

        return null;
      };

      return {
        onDownload: async (name: string) => {
          const dataUrl = await createImageFromTable();

          if (dataUrl) {
            // Download the screenshot
            const link = document.createElement('a');
            link.download = `${name}.png`;
            link.href = dataUrl;
            link.click();
          }
        },

        onCopyToClipboard: async () => {
          const dataUrl = await createImageFromTable();

          if (dataUrl) {
            try {
              // Convert data URL to Blob
              const response = await fetch(dataUrl);
              const blob = await response.blob();

              const item = new ClipboardItem({ 'image/png': blob });
              await navigator.clipboard.write([item]);
              console.log('Image copied to clipboard');
            } catch (error) {
              console.error('oops, something went wrong!', error);
            }
          }
        },
      };
    });

    return (
      <TableContainer
        ref={tableRef}
        bg={useColorModeValue('white', 'gray.700')}
        borderWidth={'thin'}
        borderColor={'gray.200'}
        borderRadius={'lg'}
        maxH={'100%'}
        overflowY={'scroll'}
      >
        <Table variant="simple" key={`matrix-${question.id}-table`}>
          <Thead>
            <Tr>{headers}</Tr>
          </Thead>
          <Tbody>
            {matrix.map((row: MatrixRow, i: number) => (
              <Tr key={`matrix-${question.id}-row-${i}`}>
                {row.map((cell: Cell, j: number) => (
                  <Td
                    key={`matrix-${question.id}-cell-${i}-${j}`}
                    sx={cellStyle}
                  >
                    {cell.label ? cell.label : cell.value}{' '}
                    {cell.label ? '' : cell.percent}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
);

Matrix.displayName = 'Matrix';

export default Matrix;
