// External Dependencies
import React from 'react';
import { FiChevronsRight } from 'react-icons/fi';
import { Box, IconButton, Input, Stack, Text } from '@chakra-ui/react';
import { MessageDto } from '@keyops-cep/api-client';

// Internal Dependencies
import { Role } from '../../../../utils/constants/engage-ai';
import i18n from '../../../../utils/i18n';

export type ChatInputProps = {
  currentMessage: MessageDto;
  setCurrentMessage: React.Dispatch<React.SetStateAction<MessageDto>>;
  handleSendMessage: () => void;
};

export const ChatInput = ({
  currentMessage,
  setCurrentMessage,
  handleSendMessage,
}: ChatInputProps) => {
  return (
    <Stack
      id={'chat-input'}
      data-testid="engage-ai-chat-input-container"
      direction={'column'}
      p={2}
      borderTop={'0.5px solid gray'}
      borderColor={'gray.200'}
      background={'white'}
    >
      <Box display={'flex'} pl={3}>
        <Input
          variant="unstyled"
          data-testid="engage-ai-chat-input"
          value={currentMessage.content}
          placeholder={
            currentMessage.content === ''
              ? i18n.t('common.typeMessage')
              : currentMessage.content
          }
          _placeholder={{ color: 'gray.400' }}
          fontSize={'14px'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentMessage({
              role: Role.user,
              content: event.target.value,
              createdAt: Date.now().toLocaleString(),
            });
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleSendMessage();
          }}
        />
        <IconButton
          aria-label={'Submit your message to the EngageAI Chat'}
          onClick={handleSendMessage}
          icon={<FiChevronsRight />}
          color="keyops.blue"
          background={'none'}
          fontSize={'21px'}
        />
      </Box>
      <Box
        display={'flex'}
        justifyContent={'right'}
        color={'gray.400'}
        fontSize={'10px'}
        m={0}
      >
        <Text>{i18n.t('engagementDetails.engageAi.poweredByChatgpt')} </Text>
      </Box>
    </Stack>
  );
};
