import { useRef } from 'react';
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from '@chakra-ui/react';
import { AggregateQuestion, IndividualQuestion } from '@keyops-cep/api-client';
import { startCase } from 'lodash';

import useComponentFlag from '../../hooks/useComponentFlag';
import ClearAllFiltersBar from '../../pages/EngagementDetails/components/ClearAllFiltersBar';
import FilterBar from '../../pages/EngagementDetails/components/FilterBar';
import { AnswerDataWithDemographic } from '../../pages/EngagementDetails/types';
import { COMPONENTS } from '../../utils/componentDisplayFlags';
import { getPrevAvgAnnotation } from '../../utils/functions/visualization-utils';
import i18n from '../../utils/i18n';

import ExpandedViewOptions from './ExpandedViewOptions';
import { getVisualizationComponent, VisualizationChartRef } from '.';

export const ExpandedViewModal = ({
  isOpen,
  onClose,
  closeButtonRef,
  question,
  questionAnswers,
  displayPrevAvg,
  togglePrevAvgDisplay,
  hideFilterBar,
}: {
  isOpen: boolean;
  onClose: () => void;
  closeButtonRef: React.MutableRefObject<null>;
  question: IndividualQuestion | AggregateQuestion;
  questionAnswers: AnswerDataWithDemographic[];
  displayPrevAvg?: boolean;
  togglePrevAvgDisplay?: () => void;
  hideFilterBar?: boolean;
}) => {
  const modalVisualizationRef = useRef<VisualizationChartRef>(null);
  const questionTypeDisplayText = `${startCase(question.type)}${
    question.subType ? ' - ' + startCase(question.subType) : ''
  }`;

  const { component: ModalVisualizationComponent } = getVisualizationComponent(
    question,
    questionAnswers,
    modalVisualizationRef,
    false,
    true,
    displayPrevAvg
  );

  const prevAvgAnnotation = getPrevAvgAnnotation(question);
  const showPrevAvgToggle = useComponentFlag(COMPONENTS.PREV_AVG_TOGGLE);

  return (
    <Modal
      id="visu-expanded-view-modal"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={'6xl'}
      initialFocusRef={closeButtonRef}
    >
      <ModalOverlay />
      <ModalContent height={'95vh'} overflowY="auto" overflowX="hidden">
        <ModalHeader fontWeight="normal" position={'relative'}>
          <Text fontSize={'xs'}>
            {i18n.t('engagementDetails.questionCards.question', {
              questionNo: question.position + 1,
            })}
          </Text>
          <Heading as={'h1'} size="sm" mt={2}>
            {question.label}
          </Heading>
          <HStack spacing={6} mt={2}>
            <Text fontSize={'xs'}>
              {i18n.t('engagementDetails.questionCards.questionType', {
                type: questionTypeDisplayText,
              })}
            </Text>
            <Text fontSize={'xs'}>
              {i18n.t('engagementDetails.questionCards.questionAnswered', {
                answeredNo: questionAnswers?.length,
              })}
            </Text>
          </HStack>
          <Divider mt={2} ml={-6} width={'110%'} />
          <Flex
            wrap="wrap"
            align="center"
            justify="flex-start"
            mt={2}
            gap={2}
            hidden={!!hideFilterBar}
          >
            <FilterBar visualization={question.visualization ?? ''} />
            <ClearAllFiltersBar />
          </Flex>
        </ModalHeader>
        <ModalCloseButton ref={closeButtonRef} />
        {!!prevAvgAnnotation && showPrevAvgToggle && (
          <Flex justifyContent={'flex-end'} w={'95%'}>
            <Box>
              <Switch
                id="display-prev-avg"
                size="sm"
                isChecked={displayPrevAvg}
                onChange={togglePrevAvgDisplay}
              />{' '}
              <Text display={'inline'} fontSize="sm">
                {i18n.t('engagementDetails.questionCards.showPrevAvg')}
              </Text>
            </Box>
          </Flex>
        )}
        <ModalBody
          display={'flex'}
          alignItems={'start'}
          justifyContent={'space-between'}
          mb={1}
        >
          <Box height={'100%'} width={'95%'}>
            {ModalVisualizationComponent}
          </Box>
          <ExpandedViewOptions
            visualizationRef={modalVisualizationRef}
            question={question}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
