import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';

import { KeyOpsLinkButton } from '../../../components/ButtonComponents';
import MarkdownDisplay from '../../../components/MarkdownDisplay';
import i18n from '../../../utils/i18n';

const EngagementAnalysisIntro = ({ intro }: { intro: string }) => {
  const [showSeeMore, setShowSeeMore] = useState<boolean>(true);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const [maxLines, setMaxLines] = useState<number | undefined>(1);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      const isTruncated = textElement.scrollHeight > textElement.clientHeight;
      setIsTruncated(isTruncated);
    }
  }, [intro]);

  const toggleShowMore = () => {
    setShowSeeMore(!showSeeMore);
    setMaxLines(showSeeMore ? undefined : 1);
  };

  return (
    <Box my={4}>
      <Box ref={textRef} noOfLines={maxLines} data-color-mode="light">
        <MarkdownDisplay source={intro} />
      </Box>
      {isTruncated && (
        <Flex justifyContent={'flex-end'}>
          <KeyOpsLinkButton
            size="sm"
            onClick={toggleShowMore}
            rightIcon={
              !showSeeMore ? (
                <ChevronUpIcon fontSize={22} />
              ) : (
                <ChevronDownIcon fontSize={22} />
              )
            }
          >
            {!showSeeMore ? i18n.t('common.seeLess') : i18n.t('common.seeMore')}
          </KeyOpsLinkButton>
        </Flex>
      )}
    </Box>
  );
};

export default EngagementAnalysisIntro;
