import React from 'react';
import { chakra, HTMLChakraProps } from '@chakra-ui/react';

const BulbSvg = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    id="bulbSvg"
    version="1.1"
    width="167"
    height="145"
    viewBox="0 0 167 145"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.6336 145L48.3057 139.446L45.7715 131.982L71.1832 140.603L64.6336 145Z"
      fill="#3376D1"
    />
    <path
      opacity="0.5"
      d="M64.6336 145L48.3057 139.446L45.7715 131.982L71.1832 140.603L64.6336 145Z"
      fill="white"
    />
    <path
      d="M117.899 78.4041C116.181 83.4246 113.421 88.0257 109.802 91.9063C106.183 95.7869 101.785 98.8594 96.8959 100.923C93.6403 102.237 90.7018 104.23 88.2757 106.768C85.8495 109.306 83.9913 112.331 82.8246 115.642L81.8988 118.35L50.7359 107.762L51.65 105.054C52.7378 101.722 53.1053 98.1962 52.7283 94.7111C52.3512 91.2259 51.2382 87.8607 49.463 84.838C45.5897 78.0424 43.9253 70.2107 44.7002 62.4273C45.4751 54.6439 48.651 47.294 53.788 41.3955C58.9251 35.497 65.7691 31.3417 73.3723 29.5051C80.9755 27.6685 88.9616 28.2415 96.2248 31.1447C105.166 34.7935 112.383 41.7096 116.409 50.4877C120.434 59.2658 120.967 69.2473 117.899 78.4041Z"
      fill="#3376D1"
    />
    <path
      opacity="0.5"
      d="M117.899 78.4041C116.181 83.4246 113.421 88.0257 109.802 91.9063C106.183 95.7869 101.785 98.8594 96.8959 100.923C93.6403 102.237 90.7018 104.23 88.2757 106.768C85.8495 109.306 83.9913 112.331 82.8246 115.642L81.8988 118.35L50.7359 107.762L51.65 105.054C52.7378 101.722 53.1053 98.1962 52.7283 94.7111C52.3512 91.2259 51.2382 87.8607 49.463 84.838C45.5897 78.0424 43.9253 70.2107 44.7002 62.4273C45.4751 54.6439 48.651 47.294 53.788 41.3955C58.9251 35.497 65.7691 31.3417 73.3723 29.5051C80.9755 27.6685 88.9616 28.2415 96.2248 31.1447C105.166 34.7935 112.383 41.7096 116.409 50.4877C120.434 59.2658 120.967 69.2473 117.899 78.4041Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M98.237 92.2136C104.578 80.6029 100.306 66.0504 88.6952 59.7095C77.0845 53.3687 62.5319 57.6407 56.1911 69.2513C49.8502 80.862 54.1223 95.4146 65.7329 101.755C77.3436 108.096 91.8961 103.824 98.237 92.2136Z"
      fill="white"
    />
    <path
      d="M61.3352 111.465L65.2696 80.3597C65.3014 80.1147 65.4287 79.8921 65.6237 79.7404C65.8187 79.5887 66.0658 79.5202 66.3111 79.5497C66.432 79.5654 66.5487 79.6048 66.6543 79.6657C66.76 79.7266 66.8525 79.8077 66.9267 79.9045C67.0009 80.0013 67.0553 80.1118 67.0867 80.2296C67.118 80.3474 67.1258 80.4703 67.1095 80.5911L63.1751 111.696L61.3352 111.465Z"
      fill="#3376D1"
    />
    <path
      d="M71.4143 114.925L69.8174 113.999L85.6361 86.9209C85.7594 86.7097 85.9612 86.5559 86.1976 86.493C86.4339 86.4302 86.6855 86.4633 86.8974 86.5854C87.0047 86.6453 87.0988 86.7262 87.1741 86.8233C87.2494 86.9204 87.3044 87.0317 87.3357 87.1505C87.367 87.2693 87.3741 87.3932 87.3565 87.5148C87.3388 87.6364 87.2968 87.7532 87.233 87.8583L71.4143 114.925Z"
      fill="#3376D1"
    />
    <path
      d="M75.7079 97.3009L74.354 89.7213L68.649 94.8939L64.6221 85.0347L66.3347 84.3289L69.3665 91.7464L75.5806 86.0878L77.0733 94.3616L83.9817 90.3231L84.9191 91.92L75.7079 97.3009Z"
      fill="#3376D1"
    />
    <path
      d="M82.9524 123.719C82.5941 124.767 81.8354 125.631 80.8422 126.121C79.849 126.611 78.7021 126.688 77.6524 126.335L73.6255 124.981C73.3059 124.955 72.9909 124.889 72.6881 124.784L49.4171 116.927C48.8952 116.757 48.4117 116.487 47.9942 116.13C47.5768 115.774 47.2337 115.339 46.9844 114.85C46.7351 114.361 46.5845 113.828 46.5413 113.281C46.4981 112.734 46.5631 112.184 46.7325 111.662C46.9019 111.14 47.1725 110.656 47.5288 110.239C47.8851 109.821 48.3202 109.478 48.8091 109.229C49.298 108.979 49.8312 108.829 50.3783 108.786C50.9254 108.742 51.4757 108.807 51.9977 108.977H52.0902L56.1173 110.331C56.4047 110.355 56.6883 110.413 56.962 110.504H57.0546L80.3256 118.362C80.8522 118.538 81.3386 118.816 81.7565 119.182C82.1745 119.547 82.5156 119.992 82.76 120.491C83.0044 120.989 83.1473 121.532 83.1803 122.086C83.2133 122.64 83.1359 123.195 82.9524 123.719Z"
      fill="#3376D1"
    />
    <path
      d="M79.0061 130.871C78.8551 131.377 78.6055 131.849 78.2717 132.259C77.9378 132.669 77.5263 133.009 77.0608 133.26C76.5953 133.51 76.0849 133.666 75.5588 133.719C75.0328 133.772 74.5016 133.72 73.9955 133.567L70.2462 132.306C69.9511 132.283 69.6597 132.224 69.3783 132.132L47.7621 124.784C46.7836 124.385 45.9921 123.631 45.5462 122.672C45.1004 121.714 45.0333 120.623 45.3582 119.617C45.6832 118.612 46.3763 117.766 47.2985 117.25C48.2207 116.734 49.304 116.585 50.3311 116.834H50.4237L54.1614 118.107C54.4321 118.129 54.6997 118.18 54.9598 118.257H55.0408L76.6918 125.652C77.6865 126.042 78.4873 126.809 78.9205 127.786C79.3537 128.763 79.3845 129.871 79.0061 130.871Z"
      fill="#3376D1"
    />
    <path
      d="M75.0144 138.011C74.7554 139.005 74.114 139.858 73.2299 140.382C72.3457 140.907 71.2903 141.061 70.2931 140.811L66.8215 139.654C66.5457 139.643 66.2728 139.592 66.0115 139.503L46.0269 132.676C45.0759 132.263 44.325 131.493 43.9354 130.533C43.5459 129.572 43.549 128.497 43.944 127.538C44.2036 126.563 44.8303 125.725 45.6929 125.201C46.5556 124.677 47.5874 124.507 48.5727 124.726H48.6537L52.1253 125.883C52.3763 125.897 52.6248 125.939 52.8659 126.011H52.9469L72.9314 132.85C73.8881 133.262 74.6434 134.036 75.0334 135.002C75.4233 135.968 75.4165 137.049 75.0144 138.011Z"
      fill="#3376D1"
    />
    <path
      d="M44.1741 134.389L44.8915 132.225L71.6225 141.239L70.905 143.403L44.1741 134.389Z"
      fill="#3376D1"
    />
    <path
      d="M51.0992 106.693L50.3826 108.845L81.6177 119.248L82.3344 117.096L51.0992 106.693Z"
      fill="#3376D1"
    />
    <g opacity="0.4">
      <path
        d="M52.7144 49.2199C51.5545 50.8203 50.7641 52.6581 50.4 54.6008C50.3597 55.1269 50.4901 55.6521 50.7719 56.0982C51.0536 56.5444 51.4718 56.8878 51.9642 57.0775C52.4566 57.2672 52.9971 57.2931 53.5053 57.1512C54.0136 57.0094 54.4627 56.7075 54.7857 56.2903C57.2441 53.2405 60.3603 50.7862 63.9012 49.1109C67.4422 47.4356 71.3161 46.5828 75.2332 46.6162C81.2042 46.7435 87.9622 48.7801 91.9082 42.6008C92.5463 41.4599 92.8284 40.1541 92.7182 38.8515C92.7182 38.8515 92.7182 38.8515 92.7182 38.7705C91.4106 28.0203 63.129 32.4176 52.7144 49.2199Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.4"
      d="M96.1312 42.2536C95.5733 43.1044 95.2495 44.0873 95.1926 45.1031C95.1358 46.1189 95.3477 47.1318 95.8072 48.0395C96.7605 49.3872 98.1374 50.377 99.7185 50.8515C100.483 51.2205 101.317 51.4217 102.165 51.4416C103.013 51.4616 103.856 51.2998 104.637 50.9672C106.419 49.9489 106.951 47.5651 106.638 45.5284C105.794 39.2912 100.066 35.9933 96.1312 42.2536Z"
      fill="white"
    />
    <path d="M49.6484 1L62.8056 19.4108L49.6484 1Z" fill="white" />
    <path
      d="M49.6484 1L62.8056 19.4108"
      stroke="#3376D1"
      strokeWidth="1.73578"
      strokeMiterlimit="10"
    />
    <path d="M1 29.3741L32.0936 44.2208L1 29.3741Z" fill="white" />
    <path
      d="M1 29.3741L32.0936 44.2208"
      stroke="#3376D1"
      strokeWidth="1.73578"
      strokeMiterlimit="10"
    />
    <path d="M4.49414 84.7917L27.742 85.567L4.49414 84.7917Z" fill="white" />
    <path
      d="M4.49414 84.7917L27.742 85.567"
      stroke="#3376D1"
      strokeWidth="1.73578"
      strokeMiterlimit="10"
    />
    <path d="M145.208 33.54L123.534 40.0781L145.208 33.54Z" fill="white" />
    <path
      d="M145.208 33.54L123.534 40.0781"
      stroke="#3376D1"
      strokeWidth="1.73578"
      strokeMiterlimit="10"
    />
    <path d="M166.431 85.7059L132.733 78.5082L166.431 85.7059Z" fill="white" />
    <path
      d="M166.431 85.7059L132.733 78.5082"
      stroke="#3376D1"
      strokeWidth="1.73578"
      strokeMiterlimit="10"
    />
    <path d="M129.853 127.469L110.968 113.895L129.853 127.469Z" fill="white" />
    <path
      d="M129.853 127.469L110.968 113.895"
      stroke="#3376D1"
      strokeWidth="1.73578"
      strokeMiterlimit="10"
    />
  </chakra.svg>
);

export default BulbSvg;
