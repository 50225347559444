import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  setTokens,
} from '../utils/get-tokens';

import { apiClient } from './swagger-codegen-api-config';

export const azureLoginApiCall = async ({
  idToken,
}: {
  idToken: string | null;
}) => {
  const response = await apiClient.authenticationApi.authControllerAzureLogin({
    headers: { Authorization: `Bearer ${idToken}` },
  });
  if (response?.status === 200) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const googleLoginApiCall = async ({
  idToken,
}: {
  idToken: string | null;
}) => {
  const response = await apiClient.authenticationApi.authControllerGoogleLogin({
    headers: { Authorization: `Bearer ${idToken}` },
  });
  if (response?.status === 200) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const emailPasswordLoginApiCall = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response =
    await apiClient.authenticationApi.authControllerEmailPasswordLogin({
      email,
      password,
    });
  if (response?.status === 201) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const azureSignupApiCall = async ({
  idToken,
  signupToken,
}: {
  idToken: string | null;
  signupToken: string;
}) => {
  const response = await apiClient.authenticationApi.authControllerAzureSignup(
    signupToken,
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );
  if (response?.status === 201) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const emailPasswordSignupApiCall = async ({
  signupToken,
  password,
}: {
  signupToken: string;
  password: string;
}) => {
  const response =
    await apiClient.authenticationApi.authControllerEmailPasswordSignup(
      { password },
      signupToken
    );
  if (response?.status === 201) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const forgotPasswordApiCall = async ({ email }: { email: string }) => {
  const response =
    await apiClient.authenticationApi.authControllerEmailPasswordResetInitiate({
      email,
    });
  return response;
};

export const resetPasswordApiCall = async ({
  resetPasswordToken,
  password,
}: {
  resetPasswordToken: string;
  password: string;
}) => {
  const response =
    await apiClient.authenticationApi.authControllerEmailPasswordResetComplete(
      { password },
      resetPasswordToken
    );
  return response;
};

export const validateSignupToken = async ({
  signupToken,
}: {
  signupToken: string;
}) => {
  const response =
    await apiClient.authenticationApi.authControllerValidateSignupToken(
      signupToken
    );
  return response;
};

export const validateResetPasswordToken = async ({
  resetPasswordToken,
}: {
  resetPasswordToken: string;
}) => {
  const response =
    await apiClient.authenticationApi.authControllerValidateResetPasswordToken(
      resetPasswordToken
    );
  return response;
};

export const logoutApiCall = async () => {
  return await apiClient.authenticationApi.authControllerLogout({
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'x-refresh-token': getRefreshToken(),
    },
  });
};

export async function setNewTokens() {
  try {
    const response = await getNewTokens();
    if (response?.status === 201) {
      setTokens(response.data.accessToken, response.data.refreshToken);
      return response;
    }
  } catch (error) {
    removeTokens();
    throw error;
  }
}

export const getNewTokens = async () => {
  return await apiClient.authenticationApi.authControllerRefreshTokens({
    headers: { 'x-refresh-token': getRefreshToken() },
  });
};
