import React from 'react';
import {
  createSearchParams,
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Loading from '../../components/Loading';
import GoogleButton from '../../HOCs/auth/GoogleButton';
import useAuth from '../../HOCs/auth/useAuth';
import { DASHBOARD } from '../../utils/internal-routes.const';

const AdminLogin: React.FunctionComponent = () => {
  const { isLoggedInFromApi, isGoogleAuthenticated, loading } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { redirectUrl } = useParams();

  if (loading) {
    return <Loading />;
  }

  return isLoggedInFromApi && isGoogleAuthenticated ? (
    redirectUrl ? (
      <Navigate
        to={{ pathname: redirectUrl }}
        state={Object.assign({}, location.state)}
        replace
      />
    ) : (
      <Navigate
        to={{
          pathname: DASHBOARD,
          search: `?${createSearchParams(searchParams)}`,
        }}
        state={Object.assign({}, location.state)}
        replace
      />
    )
  ) : (
    <>
      <GoogleButton />
    </>
  );
};

export default AdminLogin;
