import React from 'react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, useColorMode } from '@chakra-ui/react';

import { COLOR_MODE } from '../../utils/constants/common-constants';
import i18n from '../../utils/i18n';

const ThemeButton: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack>
      <Button colorScheme="blue" onClick={toggleColorMode}>
        {i18n.t('common.toggleTheme')}
      </Button>
      <IconButton
        aria-label="color-mode"
        rounded={'full'}
        onClick={toggleColorMode}
        icon={colorMode === COLOR_MODE.LIGHT ? <MoonIcon /> : <SunIcon />}
      />
    </HStack>
  );
};

export default ThemeButton;
