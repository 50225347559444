import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useToast } from '@chakra-ui/react';

import {
  azureLoginApiCall,
  azureSignupApiCall,
  emailPasswordLoginApiCall,
  emailPasswordSignupApiCall,
  googleLoginApiCall,
  logoutApiCall,
  setNewTokens,
} from '../../api/auth.functions';
import { ANALYTICS_EVENT_NAME } from '../../utils/constants/analytics-constants';
import { parseJwt } from '../../utils/functions/common-utils';
import { isErrorWithStatus } from '../../utils/functions/error-typing';
import { identifyUser } from '../../utils/functions/segment-utils';
import {
  getAccessToken,
  getRefreshToken,
  getSignupToken,
  removeAccessToken,
  removeRefreshToken,
  removeSignupToken,
  setSignupToken,
} from '../../utils/get-tokens';
import i18n from '../../utils/i18n';

import AuthContext from './AuthContext';
import { loginRequest } from './msal-config';

const isLocalStorageHaveTokens = () => {
  return getAccessToken() && getRefreshToken();
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { instance, accounts } = useMsal();
  const isAzureAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [isLoggedInFromApi, setIsLoggedInFromApi] = useState(false);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      async (message: EventMessage) => {
        if (message.eventType === EventType.HANDLE_REDIRECT_START) {
          setLoading(true);
        }
        if (message.eventType === EventType.HANDLE_REDIRECT_END) {
          setLoading(false);
        }
        // This will be run every time an event is emitted after registering this callback
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          const result = message.payload;
          if (!getSignupToken()) {
            setLoading(true);
            await azureLoginToApi({
              idToken: (result as AuthenticationResult).idToken,
            });
            setLoading(false);
          }
        }
      }
    );

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  useEffect(() => {
    if (isLoggedInFromApi) {
      identifyUser();
    }
  }, [isLoggedInFromApi]);

  // FIXME: only called if the url changes, do not take into account the token expiration
  // hence the usage of axios interceptor
  // TODO: see how it can work with the axios interceptor
  useEffect(() => {
    if (isLocalStorageHaveTokens()) {
      authCheck();
    } else {
      setIsLoggedInFromApi(false);
    }
  }, [location?.pathname]);

  const authCheck = async () => {
    setLoading(true);
    const accessToken = getAccessToken();
    const decodedJwt = parseJwt(accessToken);
    const isExpired = decodedJwt && decodedJwt.exp * 1000 < Date.now();
    const isAdmin = decodedJwt && decodedJwt.role === 'admin';

    if (isExpired) {
      try {
        const response = await setNewTokens();
        // if user gets new tokens, replace them in local storage and log user in
        // else log user out
        if (response?.status === 201) {
          setIsLoggedInFromApi(true);
          if (isAdmin) setIsGoogleAuthenticated(true);
        }
      } catch (error) {
        console.error(error);
        setIsLoggedInFromApi(false);
      }
    } else {
      setIsLoggedInFromApi(true);
      if (isAdmin) setIsGoogleAuthenticated(true);
    }
    setLoading(false);
  };

  const getAzureTokenSilent = async (idToken: string | null) => {
    if (!idToken) {
      const silentResponse = await instance.acquireTokenSilent({
        account: accounts[0],
        scopes: ['openid', 'profile'],
      });
      return silentResponse.idToken;
    }
    return idToken;
  };

  const azureLoginToApi = async ({ idToken }: { idToken: string | null }) => {
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.AZURE_API_LOGIN);
    try {
      const fetchedIdToken = await getAzureTokenSilent(idToken);
      const response = await azureLoginApiCall({ idToken: fetchedIdToken });
      if (response?.status === 200) {
        window.analytics.track(
          ANALYTICS_EVENT_NAME.AUTH.AZURE_API_LOGIN_SUCCESS
        );
        setIsLoggedInFromApi(true);
      }
    } catch (error) {
      console.error('********** Error in azureLoginToApi **********');
      console.error(error);
      window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.AZURE_API_LOGIN_ERROR);
      toast({
        title: 'Something went wrong.',
        description: 'We might have to log you back in again',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const emailPasswordLoginToApi = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_API_LOGIN);
    try {
      const response = await emailPasswordLoginApiCall({
        email,
        password,
      });
      if (response?.status === 201) {
        window.analytics.track(
          ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_API_LOGIN_SUCCESS
        );

        setIsLoggedInFromApi(true);
      }
    } catch (error) {
      window.analytics.track(
        ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_API_LOGIN_ERROR
      );
      console.error(
        '********** Error in emailPasswordLoginToApi **********',
        error
      );
      if (isErrorWithStatus(error) && error?.statusCode === 401) {
        toast({
          title: i18n.t('login.badCredentials.title'),
          description: i18n.t('login.badCredentials.description'),
          status: 'error',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
      } else {
        toast({
          title: i18n.t('login.catchAllError.title'),
          description: i18n.t('login.catchAllError.description'),
          status: 'error',
          duration: 4000,
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const azureSignupToApi = async ({
    idToken,
    signupToken,
  }: {
    idToken: string | null;
    signupToken: string;
  }) => {
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.AZURE_API_SIGNUP);
    try {
      const fetchedIdToken = await getAzureTokenSilent(idToken);

      const response = await azureSignupApiCall({
        idToken: fetchedIdToken,
        signupToken,
      });
      removeSignupToken();
      if (response?.status === 201) {
        window.analytics.track(
          ANALYTICS_EVENT_NAME.AUTH.AZURE_API_SIGNUP_SUCCESS
        );
        setIsLoggedInFromApi(true);
      }
    } catch (error) {
      window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.AZURE_API_SIGNUP_ERROR);
      if (isErrorWithStatus(error) && error.statusCode === 409) {
        //it's a 409 from a signup attempt, user already signed up, just login
        azureLoginToApi({ idToken });
      } else {
        console.error('********** Error in signupToApi **********');
        console.error(error);
        toast({
          title: 'Something went wrong.',
          description: 'We might have to sign you back up again',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    }
  };

  const emailPasswordSignupToApi = async ({
    signupToken,
    password,
  }: {
    signupToken: string;
    password: string;
  }) => {
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_API_SIGNUP);
    try {
      const response = await emailPasswordSignupApiCall({
        signupToken,
        password,
      });
      if (response?.status === 201) {
        window.analytics.track(
          ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_API_SIGNUP_SUCCESS
        );
        setIsLoggedInFromApi(true);
      }
    } catch (error) {
      window.analytics.track(
        ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_API_SIGNUP_ERROR
      );
      if (isErrorWithStatus(error) && error.statusCode === 409) {
        // TODO: I don't think we should just log user in
        // TODO: we should say user that this url is already used and
        // TODO: ask user to login or go through forgot password flow.
        //it's a 409 from a signup attempt, user already signed up, just login
        // emailPasswordLoginToApi({ email, password });
      } else {
        console.error('********** Error in signupToApi **********');
        console.error(error);
        toast({
          title: 'Something went wrong.',
          description: 'We might have to sign you back up again',
          status: 'error',
          duration: 3000,
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const handleAzureRedirect = async () => {
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.AZURE_AD_LOGIN);
    await instance.handleRedirectPromise();
    await instance.loginRedirect(loginRequest);
  };

  // call this function when you want to authenticate the user using MS
  const handleAzureLogin = async () => {
    setLoading(true);
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.AZURE_LOGIN_CLICK);

    if (!isAzureAuthenticated && !isLoggedInFromApi) {
      await handleAzureRedirect();
    } else if (isAzureAuthenticated && !isLoggedInFromApi) {
      await azureLoginToApi({ idToken: null });
    }

    setLoading(false);
  };

  const handleAzureSignup = async (signupToken: string) => {
    setLoading(true);
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.AZURE_SIGNUP_CLICK);

    if (!isAzureAuthenticated && !isLoggedInFromApi) {
      setSignupToken(signupToken);
      await handleAzureRedirect();
    } else if (isAzureAuthenticated && !isLoggedInFromApi) {
      await azureSignupToApi({ idToken: null, signupToken });
    }

    setLoading(false);
  };

  const handleEmailPasswordSignup = async (
    signupToken: string,
    password: string
  ) => {
    setLoading(true);
    window.analytics.track(
      ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_SIGNUP_CLICK
    );

    if (!isLoggedInFromApi) {
      await emailPasswordSignupToApi({ signupToken, password });
    }

    setLoading(false);
  };

  const handleEmailPasswordLogin = async (email: string, password: string) => {
    setLoading(true);
    window.analytics.track(
      ANALYTICS_EVENT_NAME.AUTH.EMAIL_PASSWORD_LOGIN_CLICK
    );

    if (!isLoggedInFromApi) {
      await emailPasswordLoginToApi({ email, password });
    }

    setLoading(false);
  };

  const logoutFromApi = async () => {
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.LOGOUT);
    try {
      // call api to remove tokens from BE
      await logoutApiCall();
    } catch (error) {
      console.error(error);
    } finally {
      // remove stored tokens from local storage
      removeAccessToken();
      removeRefreshToken();
      setIsLoggedInFromApi(false);
    }
  };

  // call this function to sign out logged in user
  const handleAzureLogout = async () => {
    setLoading(true);
    // wrapped in try/catch/finally as sometimes if the tokens are invalid
    // server throws an error, but on FE we still need to log user out.

    try {
      // call api to remove tokens from BE
      await logoutFromApi();
    } catch (error) {
      console.error(
        '**********Error in handleAzureLogout/logoutFromApi**********'
      );
      console.error(error);
    } finally {
      // log user out from azure/microsoft account
      await instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
    setLoading(false);
  };

  // TODO somewhat copy paste from admin-ui
  const handleGoogleLogin = async (idToken: string | undefined) => {
    setLoading(true);
    window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.GOOGLE_LOGIN_CLICK);
    try {
      window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.GOOGLE_API_LOGIN);
      const response = await googleLoginApiCall({ idToken: idToken ?? null });
      if (response?.status === 200) {
        window.analytics.track(
          ANALYTICS_EVENT_NAME.AUTH.GOOGLE_API_LOGIN_SUCCESS
        );

        setIsLoggedInFromApi(true);
        setIsGoogleAuthenticated(true);
      }
    } catch (error) {
      window.analytics.track(ANALYTICS_EVENT_NAME.AUTH.GOOGLE_API_LOGIN_ERROR);
      console.error('**********Error in loginToApi**********');
      console.error(error);
      setTimeout(() => {
        logoutFromApi();
      }, 3000);
      setIsGoogleAuthenticated(false);
    }
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedInFromApi,
        isAzureAuthenticated,
        loading,
        handleAzureLogin,
        handleAzureSignup,
        handleAzureLogout,
        logoutFromApi,
        handleGoogleLogin,
        handleEmailPasswordLogin,
        handleEmailPasswordSignup,
        isGoogleAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
