import React from 'react';
import { Outlet } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import customTheme from './assets/custom-theme';
import ScrollToTopButton from './components/ScrollToTopButton';
import AuthProvider from './HOCs/auth/AuthProvider';
import { msalConfig } from './HOCs/auth/msal-config';
import usePageViews from './hooks/usePageViews';

import './App.css';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

const App = () => {
  usePageViews();
  return (
    <>
      <ChakraProvider theme={customTheme}>
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Outlet />
              <ScrollToTopButton />
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </MsalProvider>
      </ChakraProvider>
    </>
  );
};

export default App;
