// External Dependencies
import React from 'react';
import { Stack } from '@chakra-ui/react';

// Internal Dependencies
import { ChatHeaderInitialContent } from './ChatHeaderInitialContent';
import { ChatHeaderInteractiveContent } from './ChatHeaderInteractiveContent';

export type ChatHeaderProps = {
  copyConversation: () => void;
  hasInteractedYet: boolean;
  isCopied: boolean;
  openFAQ: () => void;
};

export const ChatHeader = ({
  copyConversation,
  hasInteractedYet,
  isCopied,
  openFAQ,
}: ChatHeaderProps) => {
  return (
    <Stack
      data-testid="engage-ai-chat-header"
      direction={'row'}
      justify={hasInteractedYet ? 'space-between' : 'center'}
      align={'flex-start'}
      background={'white'}
      borderRadius="8px 8px 0px 0px"
      borderBottom={'1px solid var(--gray-100, #EDF2F7);'}
      padding="24px 24px 16px 24px;"
    >
      {hasInteractedYet ? (
        <ChatHeaderInteractiveContent
          copyConversation={copyConversation}
          isCopied={isCopied}
          openFAQ={openFAQ}
        />
      ) : (
        <ChatHeaderInitialContent />
      )}
    </Stack>
  );
};
