import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import useFilterProvider from '../../HOCs/Filter/useFilterProvider';
import i18n from '../../utils/i18n';

const BREAKOUT_VISUALIZATIONS = [
  'HorizontalHistogram',
  'Distribution',
  'HorizontalBarGraphOfAverages',
  'Histogram',
];

const FilterDropdownComponent = ({
  label = 'Select',
  type,
  options = [],
  demographicName,
  visualization,
}: {
  label: string;
  options: string[];
  type: string;
  demographicName: string;
  visualization: string;
}) => {
  const {
    selectedFilters,
    handleSelectedFilters,
    breakoutDemographic,
    setBreakoutDemographic,
  } = useFilterProvider();
  const sortedOptions = [...options].sort();
  const bgColor = useColorModeValue('white', 'gray.700');
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          rightIcon={<ChevronDownIcon fontSize={'18px'} />}
          bg={bgColor}
          borderRadius={'full'}
          justifyContent={'space-between'}
          size={'xs'}
          w={'100%'}
          border="1px"
          borderColor="#4A5568"
        >
          <Text
            fontSize={'xs'}
            fontWeight={'normal'}
            textTransform={'capitalize'}
          >
            {label}
          </Text>
        </Button>
      </PopoverTrigger>
      <Box position={'relative'} zIndex={'dropdown'}>
        <PopoverContent maxW={'max-content'} maxH="400px" overflowY="auto">
          <VStack
            mt={3}
            mb={BREAKOUT_VISUALIZATIONS.includes(visualization) ? 0 : 3}
            spacing={3}
            align="stretch"
          >
            {sortedOptions.map((option, i) => (
              <Checkbox
                key={i}
                px={3}
                data-testid={`checkbox-${option}`}
                value={option}
                colorScheme="filterDropdown"
                isChecked={selectedFilters.some(
                  (filter) =>
                    filter.type === type &&
                    filter.option === option &&
                    filter.checked
                )}
                onChange={(event) =>
                  handleSelectedFilters({
                    type,
                    option,
                    checked: event.target.checked,
                  })
                }
              >
                {option}
              </Checkbox>
            ))}
            {BREAKOUT_VISUALIZATIONS.includes(visualization) ? (
              <Box position="sticky" bottom="0" bg={bgColor}>
                <Divider />
                <Checkbox
                  p={3}
                  data-testid={`breakout-demographic`}
                  value={`${i18n.t(
                    'engagementDetails.filter.breakout'
                  )} ${demographicName.toLowerCase()}`}
                  colorScheme="filterDropdown"
                  isChecked={breakoutDemographic === demographicName}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setBreakoutDemographic(demographicName);
                    } else {
                      setBreakoutDemographic(undefined);
                    }
                  }}
                >
                  {`${i18n.t(
                    'engagementDetails.filter.breakout'
                  )} ${demographicName.toLowerCase()}`}
                </Checkbox>
              </Box>
            ) : (
              <></>
            )}
          </VStack>
        </PopoverContent>
      </Box>
    </Popover>
  );
};

export default FilterDropdownComponent;
