import { apiClient } from './swagger-codegen-api-config';

export const getUserProfile = async () => {
  let userProfileResponse;
  let tenantProfileResponse;
  try {
    userProfileResponse =
      await apiClient.authenticationApi.authControllerGetUserProfile();
    if (userProfileResponse?.data?.userProfile.tenantId)
      tenantProfileResponse =
        await apiClient.tenantsApi.tenantControllerFindOne(
          userProfileResponse.data.userProfile.tenantId
        );

    return {
      ...userProfileResponse?.data?.userProfile,
      tenant: tenantProfileResponse?.data,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};
