import { Chart } from 'chart.js';
import { toBlob } from 'html-to-image';

export const downloadGraph = (
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartRef: { current: Chart<any, any, any> | null }
) => {
  if (chartRef.current) {
    const imageDataUrl = chartRef.current.toBase64Image();
    const link = document.createElement('a');
    link.download = `${name}.png`;
    link.href = imageDataUrl;
    link.click();
  }
};
export const copyGraph = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartRef: { current: Chart<any, any, any> | null }
) => {
  if (chartRef.current) {
    const canvas = chartRef.current.canvas;
    if (canvas) {
      toBlob(canvas)
        .then(async (blob) => {
          try {
            if (blob) {
              const item = new ClipboardItem({ 'image/png': blob });
              await navigator.clipboard.write([item]);
              console.log('Image copied to clipboard');
            }
          } catch (error) {
            console.error(error);
          }
        })
        .catch((error) => {
          console.error('oops, something went wrong!', error);
        });
    }
  }
};
