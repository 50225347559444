import {
  Box,
  CloseButton,
  Collapse,
  Tag,
  TagLabel,
  useColorModeValue,
} from '@chakra-ui/react';

import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import i18n from '../../../utils/i18n';

const ClearAllFiltersBar = () => {
  const {
    selectedFilters,
    handleSelectedFilters,
    clearAllFilters,
    breakoutDemographic,
    setBreakoutDemographic,
  } = useFilterProvider();

  const filterTagBgColors = useColorModeValue('white', 'gray.700');
  const filterTextColors = useColorModeValue('gray.700', 'white');

  return (
    <>
      {selectedFilters.map((filter) => (
        <Box
          mt={1}
          display="inline-flex"
          alignItems="center"
          borderRadius="full"
          border="1px solid #E2E8F0"
          pl={3}
          color={filterTextColors}
          bg={filterTagBgColors}
          _hover={{ bg: 'gray.200', cursor: 'pointer' }}
          fontSize="xs"
          key={`${filter.type}-${filter.option}`}
        >
          <Box ml={2}>{filter.option}</Box>
          <CloseButton
            size={'sm'}
            ml={4}
            onClick={() =>
              handleSelectedFilters({
                type: filter.type,
                questionId: filter.questionId,
                option: filter.option,
                checked: !filter.checked,
              })
            }
          />
        </Box>
      ))}
      {breakoutDemographic ? (
        <Box
          mt={1}
          display={'inline-flex'}
          alignItems="center"
          borderRadius="full"
          border="1px solid #E2E8F0"
          pl={3}
          color={filterTextColors}
          bg={filterTagBgColors}
          _hover={{ bg: 'gray.200', cursor: 'pointer' }}
          fontSize="xs"
        >
          <Box fontSize="xs" ml={2}>{`${i18n.t(
            'engagementDetails.filter.breakout'
          )} ${breakoutDemographic.toLowerCase()}`}</Box>
          <CloseButton
            ml={4}
            size={'sm'}
            onClick={() => setBreakoutDemographic(undefined)}
          />
        </Box>
      ) : (
        <></>
      )}
      <Collapse
        in={selectedFilters.length > 0 || !!breakoutDemographic}
        animateOpacity
      >
        <Tag
          mt={'5px'}
          size={'md'}
          key={'clear-all'}
          borderRadius="full"
          variant="outline"
          colorScheme="gray"
          bg={filterTagBgColors}
          color={filterTextColors}
          _hover={{ bg: 'gray.200', cursor: 'pointer' }}
          onClick={clearAllFilters}
        >
          <TagLabel fontSize={'sm'}>{i18n.t('common.clearAll')}</TagLabel>
        </Tag>
      </Collapse>
    </>
  );
};

export default ClearAllFiltersBar;
