type EnvironmentVariables = {
  REACT_APP_AZURE_CLIENT_ID: string;
  REACT_APP_AZURE_TENANT_ID: string;
  REACT_APP_AZURE_REDIRECT_URI: string;
  REACT_APP_API_URL: string;
  REACT_APP_API_URL_SWAGGER: string;
  REACT_APP_GOOGLE_CLIENT_ID: string;
};
type RequiredEnvironmentVariables = Record<
  (typeof ENVIRONMENT_VARIABLE_NAMES)[number],
  string
>;

const ENVIRONMENT_VARIABLE_NAMES = [
  'REACT_APP_AZURE_CLIENT_ID',
  'REACT_APP_AZURE_TENANT_ID',
  'REACT_APP_AZURE_REDIRECT_URI',
  'REACT_APP_API_URL',
  'REACT_APP_API_URL_SWAGGER',
  'REACT_APP_GOOGLE_CLIENT_ID',
];

export function getEnvironmentVariables(): EnvironmentVariables {
  const env = process.env as RequiredEnvironmentVariables;
  for (const name of ENVIRONMENT_VARIABLE_NAMES) {
    if (!env[name]) {
      console.warn(`Environment variable ${name} is undefined`);
    }
  }

  return {
    REACT_APP_AZURE_CLIENT_ID: env.REACT_APP_AZURE_CLIENT_ID,
    REACT_APP_AZURE_TENANT_ID: env.REACT_APP_AZURE_TENANT_ID,
    REACT_APP_AZURE_REDIRECT_URI: env.REACT_APP_AZURE_REDIRECT_URI,
    REACT_APP_API_URL: env.REACT_APP_API_URL,
    REACT_APP_API_URL_SWAGGER: env.REACT_APP_API_URL_SWAGGER,
    REACT_APP_GOOGLE_CLIENT_ID: env.REACT_APP_GOOGLE_CLIENT_ID,
  };
}
