// External Dependencies
import { useEffect, useRef, useState } from 'react';
import { Heading, useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { FeatureFlagNameEnum } from '@keyops-cep/api-client';

// Internal Dependencies
import { FilterGroupsType } from '../../../../HOCs/Filter/FilterProvider';
import useFilterProvider from '../../../../HOCs/Filter/useFilterProvider';
import useBEFeatureFlag from '../../../../hooks/useBEFeatureFlag';
import useIsAdmin from '../../../../hooks/useIsAdmin';
import { ENGAGEMENT_STATUSES } from '../../../../utils/dto/engagement.dto';
import i18n from '../../../../utils/i18n';
import AnalysisConclusion from '../../components/AnalysisConclusion';
import Chat from '../../components/chat/Chat';
import EngagementAnalysisIntro from '../../components/EngagementAnalysisIntro';
import FiltersDrawer from '../../components/FiltersDrawer';
import ScrollButton from '../../components/ScrollButton';
import InsightsEngagementDetails from '../../InsightsEngagementDetails';
import KPISection from '../../KPISection';
import RowDataEngagementDetails from '../../RowDataEngagementDetails';

const EngagementDetailsResults = () => {
  const isAdmin = useIsAdmin();
  const { engagementDetails, filterGroups } = useFilterProvider();
  const { id: engagementId, tenantId } = engagementDetails;

  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [localFilterGroups, setLocalFilterGroups] = useState<
    FilterGroupsType[]
  >([]);

  useEffect(() => {
    setLocalFilterGroups(filterGroups);
  }, [filterGroups]);

  const toggleFilterDrawer = () => setFilterDrawerOpen((prev) => !prev);

  // Handle EngageAI chat logic
  const {
    isOpen: isChatOpen,
    onOpen: onChatOpen,
    onClose: onChatClose,
  } = useDisclosure();
  const chatRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: chatRef,
    handler: () => {
      onChatClose();
    },
  });

  const { data: isEngageAIActive, isLoading: isFlagDataLoading } =
    useBEFeatureFlag(tenantId, FeatureFlagNameEnum.EngageAIChat);

  return (
    <>
      <div id="engagement-details-results">
        <KPISection engagementDetails={engagementDetails} />
        <Heading
          as="h2"
          fontSize={'xl'}
          color={'gray.500'}
          textTransform={'capitalize'}
          aria-label="insights-title"
          mt={8}
          mb={4}
        >
          {i18n.t('engagementDetails.insights')}
        </Heading>
        {engagementDetails.analysis?.intro && (
          <EngagementAnalysisIntro intro={engagementDetails.analysis?.intro} />
        )}
        {!isAdmin &&
          engagementDetails.status ===
            ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE && (
            <RowDataEngagementDetails />
          )}
        {(isAdmin ||
          engagementDetails.status ===
            ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE) && (
          <InsightsEngagementDetails
            toggleFilterDrawer={toggleFilterDrawer}
            localFilterGroups={localFilterGroups}
          />
        )}
        <AnalysisConclusion engagementDetails={engagementDetails} />
      </div>
      {/* CHAT */}
      {engagementId &&
        engagementDetails.status === ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE &&
        isEngageAIActive &&
        !isFlagDataLoading && (
          <Chat
            engagementId={engagementId}
            engagementStatus={engagementDetails.status}
            isEngageAIActive={isEngageAIActive}
            isChatOpen={isChatOpen}
            onClose={onChatClose}
            onOpen={onChatOpen}
            chatRef={chatRef}
          />
        )}
      <FiltersDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        toggleFilterDrawer={toggleFilterDrawer}
        localFilterGroups={localFilterGroups}
        setLocalFilterGroups={setLocalFilterGroups}
      />
      <ScrollButton
        engagementStatus={engagementDetails.status}
        toggleFilterDrawer={toggleFilterDrawer}
      />
    </>
  );
};

export default EngagementDetailsResults;
