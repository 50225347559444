// External Dependencies
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Center } from '@chakra-ui/react';
import { AggregateQuestion } from '@keyops-cep/api-client';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
// Internal Dependencies
import {
  AnswerDataWithDemographic,
  Respondent,
} from '../../../pages/EngagementDetails/types';
import {
  CHART_COLORS,
  CHART_COLORS_WITH_ALPHA,
} from '../../../utils/constants/visualization-constants';
import { horizontalBarGraphDatasets } from '../../../utils/functions/breakout-demographics-datasets';
import { Counts } from '../../../utils/functions/stats';
import {
  copyGraph,
  downloadGraph,
} from '../../../utils/functions/visualization-actions';
import { splitStringIntoChunks } from '../../../utils/functions/visualization-utils';
import { VisualizationChartRef } from '..';

type CountAggregates = { [key: string]: { total: number; count: number } };
type HorizontalBarGraphProps = {
  question: AggregateQuestion;
  questionAnswers: AnswerDataWithDemographic[];
  maintainAspectRatio?: boolean;
  isExpanded?: boolean;
};

interface AnswersType {
  value: string | string[] | number;
  aggregateQuestionSurveySparrowId: string;
  questionComponentSurveySparrowId: string;
  demographics?: Respondent[];
}

type AnswerArrayType = AnswersType[];

const parseValueAsInteger = (value: string | number | string[]): number => {
  const numberValue = parseInt(value.toString());
  return isNaN(numberValue) ? 0 : numberValue;
};

export function horizontalBarGraphCounts(
  question: AggregateQuestion,
  questionAnswers: AnswerDataWithDemographic[],
  answerLabels?: string[]
) {
  const countAggregates: CountAggregates = {};
  const collectedAnswers: AnswerArrayType = [];

  // Helper function to match ids to question labels aka answers
  const getAnswerLabel = (id: string): string => {
    let label = '';
    if (question && question.components) {
      question.components.forEach((question) => {
        if (question.surveySparrowId === id) {
          label = question.label;
        }
      });
    }

    return label;
  };

  // We have to aggregate certain questions that don't have a flat structure
  if (questionAnswers[0] && questionAnswers[0].components) {
    // Aggregate to one array fist
    questionAnswers.forEach((answers) => {
      if (answers && answers.components) {
        answers.components.forEach((answer) => {
          collectedAnswers.push(answer);
        });
      }
    });

    if (answerLabels) {
      // if we have answer labels, we have to prime the aggregates in the order provided.
      // this is used for breakouts
      answerLabels.forEach(
        (answerLabel) => (countAggregates[answerLabel] = { total: 0, count: 0 })
      );
    }

    collectedAnswers.forEach((component) => {
      const answerLabel = getAnswerLabel(
        component.questionComponentSurveySparrowId
      );

      if (component && component.value) {
        const parsedValue = parseValueAsInteger(component.value);
        // We've already seen this answer
        if (countAggregates[answerLabel]) {
          countAggregates[answerLabel].total += parsedValue;
          countAggregates[answerLabel].count++;
          // New Answer
        } else {
          countAggregates[answerLabel] = { total: parsedValue, count: 1 };
        }
      }
    });
  }

  //calculate the averages
  const counts: Counts = Object.entries(countAggregates).reduce(
    (counts, entry) => {
      const key = entry[0];
      const countAggregate = entry[1];
      counts[key] =
        countAggregate.count > 0
          ? countAggregate.total / countAggregate.count
          : 0;
      return counts;
    },
    {} as Counts
  );

  const useWholeNumbers =
    question.type === 'ConstantSum' &&
    Object.values(counts).some((value) => value >= 10);
  //if it's an uncapped constant sum, we just use the averages
  Object.entries(counts).forEach(([key, value]) => {
    if (useWholeNumbers) {
      counts[key] = Math.round(value);
    } else {
      counts[key] = Number((Math.round(value * 100) / 100).toFixed(2));
    }
  });
  return counts;
}

const labelLimit = 20;
const expandedLabelLimit = 50;
const expandedLineLimit = 2;
const HorizontalBarGraph = forwardRef<
  VisualizationChartRef,
  HorizontalBarGraphProps
>(
  (
    {
      question,
      questionAnswers,
      maintainAspectRatio = false,
      isExpanded = false,
    },
    ref
  ) => {
    const { DEFAULT_FILTER_GROUPS, breakoutDemographic } = useFilterProvider();

    const usePercentages = !(
      question.type === 'ConstantSum' && question.subType === 'Uncapped'
    );

    const counts = horizontalBarGraphCounts(question, questionAnswers);
    const breakoutDatasets = horizontalBarGraphDatasets(
      breakoutDemographic,
      DEFAULT_FILTER_GROUPS,
      questionAnswers,
      question,
      Object.keys(counts)
    );
    const chartRef: { current: Chart<'bar', number[], string> | null } =
      useRef(null);
    const chartContainer = useRef<HTMLCanvasElement>(null);

    useImperativeHandle(ref, () => ({
      onDownload: (name: string) => {
        downloadGraph(name, chartRef);
      },

      onCopyToClipboard: async () => {
        await copyGraph(chartRef);
      },
    }));
    useEffect(() => {
      if (chartContainer.current) {
        chartRef.current = new Chart<'bar', number[], string>(
          chartContainer.current,
          {
            type: 'bar',
            data: {
              labels: Object.keys(counts),
              datasets: breakoutDemographic
                ? breakoutDatasets
                : [
                    {
                      data: Object.values(counts),
                      backgroundColor: CHART_COLORS_WITH_ALPHA[2],
                      borderColor: CHART_COLORS[2],
                      borderWidth: 2,
                    },
                  ],
            },
            options: {
              responsive: true,
              maintainAspectRatio,
              layout: {
                padding: {
                  right: 30,
                },
              },
              plugins: {
                legend: {
                  display: breakoutDemographic ? true : false,
                  position: 'bottom',
                  labels: {
                    boxWidth: Chart.defaults.font.size,
                  },
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      const formattedValue = context.formattedValue;
                      if (formattedValue && usePercentages) {
                        return `${formattedValue}%`;
                      } else {
                        return formattedValue;
                      }
                    },
                  },
                },
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                  formatter: (value) => {
                    if (value === 0) return null;
                    return value + (usePercentages ? '%' : '');
                  },
                },
              },
              indexAxis: 'y', // Set the index axis to 'y' for horizontal bars
              scales: {
                x: {
                  grid: {
                    color: 'rgba(0, 0, 0, 0.05)',
                  },
                },
                y: {
                  ticks: {
                    callback: function (value) {
                      if (value || value === 0) {
                        const label: string = this.getLabelForValue(
                          parseInt(value as string)
                        );
                        return label.length > labelLimit
                          ? isExpanded
                            ? splitStringIntoChunks(
                                label,
                                expandedLabelLimit,
                                expandedLineLimit
                              )
                            : `${label.substring(0, labelLimit)}...`
                          : label;
                      }
                      return '';
                    },
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            },
            plugins: [...[ChartDataLabels]],
          }
        );
      }

      // Clean up the chart instance when the component unmounts
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counts]);
    return (
      <Center h={'100%'}>
        <canvas ref={chartContainer}></canvas>
      </Center>
    );
  }
);

HorizontalBarGraph.displayName = 'HorizontalBarGraph';
export default HorizontalBarGraph;
