import { Progress } from '@chakra-ui/react';

const PasswordStrengthBar = ({ score }: { score: number }) => {
  const percentage = (() => (100 * score) / 4)();
  const progressColor = (() => {
    switch (score) {
      case 0:
      case 1:
        return 'red';
      case 2:
      case 3:
        return 'yellow';
      case 4:
        return 'green';
    }
  })();
  return (
    <Progress
      aria-label={`progressbar-${score}`}
      value={percentage}
      colorScheme={progressColor}
      mt={2}
    />
  );
};

export default PasswordStrengthBar;
