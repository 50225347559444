import React from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  CloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';

import i18n from '../../utils/i18n';

interface SearchInputProps extends InputProps {
  onClear?: () => void;
}

const defaultSearchPlaceholder = i18n.t('common.search');

const SearchInputComponent = ({
  onClear,
  ...rest
}: SearchInputProps): JSX.Element => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.400" />
      </InputLeftElement>
      <Input
        data-testid="search-input"
        size="md"
        bg={useColorModeValue('white', 'gray.700')}
        placeholder={defaultSearchPlaceholder}
        {...rest}
      />
      {typeof onClear === 'function' && rest.value && (
        <InputRightElement>
          <CloseButton
            data-testid="clear-search-input"
            size="sm"
            onClick={onClear}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default SearchInputComponent;
