import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { Box, Card, CardBody, Heading } from '@chakra-ui/react';
import { debounce, isEmpty, sortBy } from 'lodash';

import useFilterProvider from '../../HOCs/Filter/useFilterProvider';
import { ANALYTICS_EVENT_NAME } from '../../utils/constants/analytics-constants';
import i18n from '../../utils/i18n';

import AnalysisAndVisualizationCard from './components/AnalysisAndVisualizationCard';
import SearchAndFilterBar from './components/SearchAndFilterBar';
import { AnswerDataWithDemographic } from './types';
import { FilterType } from '.';

interface InsightsEngagementDetailsProps {
  toggleFilterDrawer: () => void;
  localFilterGroups: FilterType[];
}

const InsightsEngagementDetails = ({
  toggleFilterDrawer,
  localFilterGroups,
}: InsightsEngagementDetailsProps) => {
  const { engagementDetails, engagementResponses } = useFilterProvider();

  const [engagementQuestions, setEngagementQuestions] = useState([
    ...engagementDetails.questions,
  ]);

  // search string
  const [searchQuery, setSearchQuery] = useState<string>('');

  const searchQuestions = useCallback((value: string) => {
    window.analytics.track(ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.SEARCH, {
      engagementId: engagementDetails.id,
      name: engagementDetails.name,
      status: engagementDetails.status,
      searchTerm: value,
    });
    const filteredQuestions = engagementDetails.questions.filter((question) => {
      if (!value) return true;
      return (
        question.label.toLowerCase().includes(value.toLowerCase()) ||
        question.type.toLowerCase().includes(value.toLowerCase()) ||
        engagementDetails.analysis?.questionAnalyses
          .find((analysis) => analysis.questionId === question.id)
          ?.content.toLowerCase()
          .includes(value.toLowerCase())
      );
    });
    setEngagementQuestions([...filteredQuestions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearchSearchQueryChange = useMemo(() => {
    return debounce(searchQuestions, 500);
  }, [searchQuestions]);

  const clearSearchQuery = () => {
    setSearchQuery('');
    searchQuestions('');
  };

  const renderQuestionAnalysisCards = useCallback(() => {
    return sortBy(engagementQuestions, 'position').map((question, index) => {
      const questionAnalysis =
        engagementDetails.analysis?.questionAnalyses.find(
          (analysis) => analysis.questionId === question.id
        );
      const questionAnswers = engagementResponses
        .map((response) => {
          const answer = response.answers.find(
            (answer) => answer.questionId === question.id
          );
          return answer
            ? { ...answer, demographics: response.respondentDemographics }
            : undefined;
        })
        .filter((n) => n !== undefined) as AnswerDataWithDemographic[];

      const isQuestionAddedToFilter = localFilterGroups.some(
        (filterGroup) => filterGroup.questionId === question.id
      );

      return (
        <AnalysisAndVisualizationCard
          key={question.id || index}
          question={question}
          questionAnalysis={questionAnalysis}
          questionAnswers={questionAnswers}
          isQuestionAddedToFilter={isQuestionAddedToFilter}
          toggleFilterDrawer={toggleFilterDrawer}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementQuestions, engagementResponses, localFilterGroups]);

  return (
    <Box
      key={'insights-engagement-details'}
      aria-label="insights-engagement-details"
    >
      <SearchAndFilterBar
        searchQuery={searchQuery}
        handleSearchQueryChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(event.target.value);
          debouncedSearchSearchQueryChange(event.target.value);
        }}
        clearSearchQuery={clearSearchQuery}
        toggleFilterDrawer={toggleFilterDrawer}
      />
      <Box mt={8}>
        {!isEmpty(engagementQuestions) ? (
          <>{renderQuestionAnalysisCards()}</>
        ) : (
          <Card mt={4}>
            <CardBody>
              <Heading fontSize={'lg'}>
                {i18n.t('engagementDetails.questionCards.noSearchResults')}
              </Heading>
            </CardBody>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default memo(InsightsEngagementDetails);
