// External Dependencies
import React, { memo, useState } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Switch,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import {
  AggregateQuestion,
  IndividualQuestion,
  QuestionAnalysis,
} from '@keyops-cep/api-client';
import { startCase } from 'lodash';

// Internal Dependencies
import { KeyOpsGhostButton } from '../../../components/ButtonComponents';
import MarkdownDisplay from '../../../components/MarkdownDisplay';
import Visualization from '../../../components/Visualization';
import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import useComponentFlag from '../../../hooks/useComponentFlag';
import { COMPONENTS } from '../../../utils/componentDisplayFlags';
import { ANALYTICS_EVENT_NAME } from '../../../utils/constants/analytics-constants';
import { VISUALIZATION_TYPES } from '../../../utils/constants/visualization-constants';
import { getPrevAvgAnnotation } from '../../../utils/functions/visualization-utils';
import i18n from '../../../utils/i18n';
import { AnswerDataWithDemographic } from '../types';

const COPY_TEXT = i18n.t('visualization.options.copy');
const COPIED_TEXT = i18n.t('visualization.options.copied');

const FILTER_QUESTION_TYPES = [
  'MultiChoice',
  'NumberInput',
  'Slider',
  'OpinionScale',
  'YesNo',
];

type AnalysisOnlyCardProps = {
  question: IndividualQuestion | AggregateQuestion;
  questionAnalysis: QuestionAnalysis;
};

const AnalysisOnly = memo(
  ({ question, questionAnalysis }: AnalysisOnlyCardProps) => {
    const [isAnalysisHovered, setIsAnalysisHovered] = useState(false);
    const content = questionAnalysis?.content ?? '';
    const { onCopy } = useClipboard(content);
    const [tooltipText, setTooltipText] = useState(COPY_TEXT);

    const handleMouseEnter = () => {
      setIsAnalysisHovered(true);
    };

    const handleMouseLeave = () => {
      setIsAnalysisHovered(false);
    };

    const handleCopy = () => {
      window.analytics.track(
        ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.ANALYSIS_COPY,
        {
          engagementId: question.engagementId,
          questionId: question.id,
          position: question.position,
        }
      );
      if (content.length > 0) onCopy();

      setTooltipText(COPIED_TEXT);
    };

    const handleTooltipOpen = () => {
      if (tooltipText === COPIED_TEXT) {
        setTooltipText(COPY_TEXT);
      }
    };

    return (
      <VStack
        align="start"
        h={'100%'}
        p={{ base: 1, md: 4 }}
        borderRadius={'md'}
        _hover={{ bg: 'gray.100' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Flex w={'100%'} justify="space-between">
          <Heading visibility={{ base: 'hidden', md: 'visible' }}>
            {i18n.t('engagementDetails.questionCards.analysisHeading')}
          </Heading>
          {isAnalysisHovered && (
            <Tooltip
              label={tooltipText}
              placement="top"
              hasArrow
              color="white"
              bg="keyops.blue"
              closeOnClick={false}
              onOpen={handleTooltipOpen}
            >
              <Box _hover={{ cursor: 'pointer' }} onClick={handleCopy}>
                <Icon
                  aria-label="Copy"
                  color="keyops.blue"
                  as={MdOutlineContentCopy}
                />
              </Box>
            </Tooltip>
          )}
        </Flex>
        <MarkdownDisplay source={questionAnalysis?.content} />
      </VStack>
    );
  }
);
AnalysisOnly.displayName = 'AnalysisOnly';

export type AnalysisAndVisualizationProps = {
  question: IndividualQuestion | AggregateQuestion;
  questionAnalysis: QuestionAnalysis;
  questionAnswers: AnswerDataWithDemographic[];
};

const largeVisualizationTypes = [
  VISUALIZATION_TYPES.MATRIX,
  VISUALIZATION_TYPES.TEXT_GRID,
];

const isLargeVisualization = (
  question: IndividualQuestion | AggregateQuestion
): boolean => {
  return question.visualization
    ? largeVisualizationTypes.includes(question.visualization)
    : false;
};

const AnalysisAndVisualization = memo(
  ({
    question,
    questionAnalysis,
    questionAnswers,
  }: AnalysisAndVisualizationProps) => {
    const widthAnalysis = isLargeVisualization(question) ? '25%' : '100%';
    const widthVisualization = isLargeVisualization(question) ? '75%' : '100%';

    return (
      <Flex w={'100%'} h={'100%'} justifyContent="space-between">
        <Box w={widthAnalysis} paddingRight="8px">
          <AnalysisOnly
            question={question}
            questionAnalysis={questionAnalysis}
          />
        </Box>
        <Box w={widthVisualization} h={'100%'} mx={'auto'}>
          {question.visualization && (
            <VisualizationDisplay
              question={question}
              questionAnswers={questionAnswers}
            />
          )}
        </Box>
      </Flex>
    );
  }
);
AnalysisAndVisualization.displayName = 'AnalysisAndVisualization';

export type VisualizationOnlyProps = {
  question: IndividualQuestion | AggregateQuestion;
  questionAnswers: AnswerDataWithDemographic[];
};

const VisualizationDisplay = memo(
  ({ question, questionAnswers }: VisualizationOnlyProps) => {
    const [displayPrevAvg, setDisplayPrevAvg] = useState(true);
    const togglePrevAvgDisplay = () => setDisplayPrevAvg((prev) => !prev);
    const prevAvgAnnotation = getPrevAvgAnnotation(question);
    const showPrevAvgToggle = useComponentFlag(COMPONENTS.PREV_AVG_TOGGLE);

    return (
      <>
        {question.visualization && (
          <Box
            p={{ base: 1, md: 4 }}
            h={'100%'}
            mx={'auto'}
            justifyContent={'space-between'}
          >
            <Flex
              alignItems={'center'}
              visibility={{ base: 'hidden', md: 'visible' }}
              mb={2.5}
            >
              <Heading>
                {i18n.t('engagementDetails.questionCards.visualizationHeading')}
              </Heading>
              {!!prevAvgAnnotation && showPrevAvgToggle && (
                <>
                  <Spacer />
                  <Box mr={8}>
                    <Switch
                      id="display-prev-avg"
                      size="sm"
                      isChecked={displayPrevAvg}
                      onChange={togglePrevAvgDisplay}
                    />{' '}
                    <Text display={'inline'} fontSize="sm">
                      {i18n.t('engagementDetails.questionCards.showPrevAvg')}
                    </Text>
                  </Box>
                </>
              )}
            </Flex>
            <Visualization
              question={question}
              questionAnswers={questionAnswers}
              displayPrevAvg={displayPrevAvg}
              togglePrevAvgDisplay={togglePrevAvgDisplay}
            />
          </Box>
        )}
      </>
    );
  }
);
VisualizationDisplay.displayName = 'VisualizationDisplay';

const VisualizationOnly = memo(
  ({ question, questionAnswers }: VisualizationOnlyProps) => {
    return (
      <Box
        w={{ base: '95%', md: '70%', lg: '100%' }}
        transition={'all 0.2s ease'}
        h={'100%'}
        mx={'auto'}
      >
        <VisualizationDisplay
          question={question}
          questionAnswers={questionAnswers}
        />
      </Box>
    );
  }
);
VisualizationOnly.displayName = 'VisualizationOnly';

export type AnalysisAndVisualizationCardProps = {
  question: IndividualQuestion | AggregateQuestion;
  questionAnalysis?: QuestionAnalysis;
  questionAnswers: AnswerDataWithDemographic[];
  isQuestionAddedToFilter: boolean;
  toggleFilterDrawer: () => void;
};

const AnalysisAndVisualizationCard = ({
  question,
  questionAnalysis,
  questionAnswers,
  isQuestionAddedToFilter,
  toggleFilterDrawer,
}: AnalysisAndVisualizationCardProps): JSX.Element => {
  const { addQuestionFilter } = useFilterProvider();
  const questionTypeDisplayText = `${startCase(question.type)}${
    question.subType ? ' - ' + startCase(question.subType) : ''
  }`;

  return (
    <Card h={'100%'} mt={4} className="questionCard">
      <CardHeader pb={2}>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Text fontSize={'xs'}>
            {i18n.t('engagementDetails.questionCards.question', {
              questionNo: question.position + 1,
            })}
          </Text>
          {FILTER_QUESTION_TYPES.includes(question.type) && (
            <KeyOpsGhostButton
              size={'sm'}
              fontSize={'xs'}
              textTransform={'uppercase'}
              onClick={() => {
                addQuestionFilter(question);
                if (!isQuestionAddedToFilter) toggleFilterDrawer();
              }}
            >
              {isQuestionAddedToFilter
                ? i18n.t('engagementDetails.questionCards.removeQueFilterBtn')
                : i18n.t('engagementDetails.questionCards.addQueFilterBtn')}
            </KeyOpsGhostButton>
          )}
        </Flex>
        <Heading as={'h1'} size="md" mt={2}>
          {question.label}
        </Heading>
      </CardHeader>
      <CardBody aspectRatio={{ base: 3.4 }} minH={'350px'} pt={0} pb={0}>
        {questionAnalysis?.content && question.visualization && (
          <AnalysisAndVisualization
            question={question}
            questionAnalysis={questionAnalysis}
            questionAnswers={questionAnswers}
          />
        )}
        {!questionAnalysis?.content && question.visualization && (
          <Box
            width={isLargeVisualization(question) ? '75%' : '50%'}
            marginLeft={isLargeVisualization(question) ? '12.5%' : '25%'}
            height="100%"
          >
            <VisualizationOnly
              question={question}
              questionAnswers={questionAnswers}
            />
          </Box>
        )}
        {questionAnalysis?.content && !question.visualization && (
          <AnalysisOnly
            question={question}
            questionAnalysis={questionAnalysis}
          />
        )}
      </CardBody>
      <CardFooter>
        <HStack spacing={6}>
          <Text fontSize={'xs'}>
            {i18n.t('engagementDetails.questionCards.questionType', {
              type: questionTypeDisplayText,
            })}
          </Text>
          <Text fontSize={'xs'}>
            {i18n.t('engagementDetails.questionCards.questionAnswered', {
              answeredNo: questionAnswers?.length,
            })}
          </Text>
        </HStack>
      </CardFooter>
    </Card>
  );
};

export default memo(AnalysisAndVisualizationCard);
