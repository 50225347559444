// External Dependencies
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { AggregateQuestion } from '@keyops-cep/api-client';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { toPng } from 'html-to-image';

// Internal Dependencies
import { ISurveySparrowAnswerData } from '../../../pages/EngagementDetails/types';
import i18n from '../../../utils/i18n';
import ColumnHeader from '../../TableComponents/ColumnHeader';
import { VisualizationChartRef } from '..';

type TextGridProps = {
  question: AggregateQuestion;
  questionAnswers: (ISurveySparrowAnswerData | undefined)[];
};

interface TextGridData {
  [key: string]: string;
}

const columnHelper = createColumnHelper<TextGridData>();

const cellStyle = {
  fontSize: 'xs',
  padding: '8px',
  maxWidth: '32px',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
};
const TextGrid = forwardRef<VisualizationChartRef, TextGridProps>(
  ({ question, questionAnswers }, ref) => {
    const [data, setData] = React.useState<TextGridData[]>([]);
    const [columns, setColumns] = React.useState<
      ColumnDef<TextGridData, string>[]
    >([]);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const tableRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => {
      const createImageFromTable = async (): Promise<string | null> => {
        if (!tableRef.current) return null;

        // Create a clone of the table
        const clone = tableRef.current.cloneNode(true) as HTMLDivElement;

        // Get computed width of the original table
        const originalTableWidth = getComputedStyle(tableRef.current).width;

        // Make the clone fully visible and hide the scrollbars
        clone.style.height = 'auto';
        clone.style.maxHeight = 'none';
        clone.style.overflowY = 'visible';
        clone.style.overflow = 'hidden'; // Hide scrollbars
        clone.style.width = originalTableWidth; // Set width of the clone to match original table

        // Append the clone to the body
        document.body.appendChild(clone);

        try {
          // Take screenshot of the clone
          const dataUrl = await toPng(clone);
          return dataUrl;
        } catch (error) {
          console.error('oops, something went wrong!', error);
        } finally {
          // Remove the clone from the body
          document.body.removeChild(clone);
        }

        return null;
      };

      return {
        onDownload: async (name: string) => {
          const dataUrl = await createImageFromTable();

          if (dataUrl) {
            // Download the screenshot
            const link = document.createElement('a');
            link.download = `${name}.png`;
            link.href = dataUrl;
            link.click();
          }
        },

        onCopyToClipboard: async () => {
          const dataUrl = await createImageFromTable();

          if (dataUrl) {
            try {
              // Convert data URL to Blob
              const response = await fetch(dataUrl);
              const blob = await response.blob();

              const item = new ClipboardItem({ 'image/png': blob });
              await navigator.clipboard.write([item]);
              console.log('Image copied to clipboard');
            } catch (error) {
              console.error('oops, something went wrong!', error);
            }
          }
        },
      };
    });

    useEffect(() => {
      const data = questionAnswers.map((answer) => {
        const formattedAnswers =
          answer?.components?.map((component) => ({
            ['' + component.questionComponentSurveySparrowId]: component.value,
          })) || [];

        return Object.assign({}, ...formattedAnswers);
      });
      setData(data);
      setColumns(generateColumns());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionAnswers]);

    const generateColumns = useCallback(() => {
      return (
        question.components?.map((component) =>
          columnHelper.accessor('' + component.surveySparrowId, {
            header: ({ column }) => (
              <ColumnHeader
                headerText={component.label}
                sorted={column.getIsSorted()}
              />
            ),
            cell: (info) => info.getValue(),
          })
        ) || []
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const table = useReactTable({
      data: data,
      columns: columns,
      getCoreRowModel: getCoreRowModel(),
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      state: {
        sorting,
      },
    });

    const tableTextColor = useColorModeValue('gray.700', 'white');
    const tableRowHoverBgColor = useColorModeValue('gray.200', 'gray.800');

    return (
      <TableContainer
        ref={tableRef}
        bg={useColorModeValue('white', 'gray.700')}
        borderWidth={'thin'}
        borderColor={'gray.200'}
        borderRadius={'lg'}
        maxH={'100%'}
        overflowY={'scroll'}
      >
        <Table variant="simple">
          <Thead
            position={'sticky'}
            top={0}
            zIndex={'docked'}
            bg={useColorModeValue('white', 'gray.700')}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      cursor={'pointer'}
                      fontSize={'xs'}
                      onClick={header.column.getToggleSortingHandler()}
                      textTransform={'capitalize'}
                      color={tableTextColor}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  fontSize={'sm'}
                  _hover={{ bg: tableRowHoverBgColor }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} sx={cellStyle}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td
                  colSpan={columns.length}
                  textAlign={'center'}
                  color={'blue.700'}
                  py={20}
                >
                  {i18n.t('visualization.noData')}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
);

TextGrid.displayName = 'TextGrid';

export default TextGrid;
