import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { chakra, Icon } from '@chakra-ui/react';
import { SortDirection } from '@tanstack/react-table';

const ColumnHeader = ({
  headerText,
  sorted,
}: {
  headerText: string;
  sorted: SortDirection | false;
}) => {
  const [hovered, setHovered] = useState(false);
  const showSortIcon = sorted || hovered;
  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {headerText}
      <chakra.span pl={2}>
        {showSortIcon ? (
          sorted === 'desc' ? (
            <ChevronDownIcon aria-label="sorted descending" boxSize={4} />
          ) : (
            <ChevronUpIcon aria-label="sorted ascending" boxSize={4} />
          )
        ) : (
          <Icon viewBox="0 0 0 0" boxSize={4} />
        )}
      </chakra.span>
    </span>
  );
};

export default ColumnHeader;
