import React from 'react';
import {
  createSearchParams,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

const RedirectToPath: React.FC<{ path: string; keepQueryParams?: boolean }> = ({
  path,
  keepQueryParams = true,
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  return (
    <Navigate
      to={{
        pathname: path,
        search: keepQueryParams
          ? `?${createSearchParams(searchParams)}`
          : undefined,
      }}
      state={Object.assign({}, location.state)}
      replace
    />
  );
};

export default RedirectToPath;
