import React from 'react';
import { Box } from '@chakra-ui/react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

interface MarkdownDisplayProps {
  source: string;
}

const MarkdownDisplay: React.FC<MarkdownDisplayProps> = (props) => {
  const { source } = props;
  return (
    <Box data-color-mode="light" overflowY={'auto'}>
      <MDEditor.Markdown
        style={{
          backgroundColor: 'transparent',
          color: '#1A242B',
          fontWeight: 400,
          fontFamily: "'Inter', sans-serif",
        }}
        source={source}
        rehypePlugins={[rehypeSanitize]}
      />
    </Box>
  );
};

export default MarkdownDisplay;
