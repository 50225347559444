import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';

import KeyOpsLogo from '../../assets/images/KeyOpsLogo';
import PhysiciansImg from '../../assets/images/PhysiciansImg.png';
import { customerSupportEmail } from '../../utils/constants/common-constants';
import i18n from '../../utils/i18n';
import { DASHBOARD } from '../../utils/internal-routes.const';

const PublicRouteLayout = () => {
  const navigate = useNavigate();

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}>
      <GridItem h="100vh" px={16} py={8}>
        <Flex h={'100%'} direction={'column'} justifyContent={'space-between'}>
          <Box
            _hover={{ cursor: 'pointer' }}
            onClick={() => navigate(DASHBOARD)}
            alignSelf={{ base: 'center', md: 'flex-start' }}
            aria-label={'KeyOps Logo'}
          >
            <KeyOpsLogo />
          </Box>
          <Box mt={10}>
            <Box
              boxSize={'xs'}
              mx={'auto'}
              display={{ base: 'flex', md: 'none' }}
            >
              <Image src={PhysiciansImg} alt="Physicians Img" />
            </Box>
            <Heading
              as="h3"
              size="2xl"
              lineHeight={{ base: '50px', md: '56px' }}
              mt={{ base: 8, md: 0 }}
              fontFamily="Poppins"
              fontWeight={'extrabold'}
            >
              {i18n.t('publicRouteLayout.title1')}
            </Heading>
            <Heading
              as="h3"
              size="2xl"
              lineHeight={{ base: '50px', md: '56px' }}
              fontFamily="Poppins"
              fontWeight={'extrabold'}
            >
              {i18n.t('publicRouteLayout.title2')}
            </Heading>
            <Outlet />
          </Box>
          <Box>
            <Text
              aria-label={'learnMore'}
              fontSize="md"
              mt={{ base: 4, lg: 10 }}
            >
              {i18n.t('publicRouteLayout.learnMore')}{' '}
              <Link
                href="https://www.keyops.io/"
                color="keyops.blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.t('publicRouteLayout.here')}
              </Link>
              .
            </Text>
            <Text fontSize="md" mt={2}>
              <Link
                href={`mailto:${customerSupportEmail}`}
                color="keyops.blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.t('publicRouteLayout.contactUs')}
              </Link>
            </Text>
          </Box>
        </Flex>
      </GridItem>
      <Center
        w="100%"
        h="100vh"
        bg="keyops.blue"
        display={{ base: 'none', md: 'flex' }}
      >
        <Box boxSize="md">
          <Image src={PhysiciansImg} alt="Physicians Img" />
        </Box>
      </Center>
    </Grid>
  );
};

export default PublicRouteLayout;
