// External Dependencies
import React from 'react';
import { Center, CircularProgress } from '@chakra-ui/react';

const Loading = ({ h = '100vh' }: { h?: string }): JSX.Element => {
  return (
    <Center h={h}>
      <CircularProgress
        aria-label="progress"
        isIndeterminate
        size="120px"
        color="keyops.blue"
        thickness={8}
        data-testid="loading"
      />
    </Center>
  );
};

export default Loading;
