// External Dependencies
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Center } from '@chakra-ui/react';
import { AggregateQuestion } from '@keyops-cep/api-client';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import useFilterProvider from '../../../HOCs/Filter/useFilterProvider';
import { AnswerDataWithDemographic } from '../../../pages/EngagementDetails/types';
import {
  CHART_COLORS,
  CHART_COLORS_WITH_ALPHA,
} from '../../../utils/constants/visualization-constants';
import { getBreakoutDemographicsDatasetsPercentages } from '../../../utils/functions/breakout-demographics-datasets';
import { convertToPercentageCountArray } from '../../../utils/functions/stats';
import {
  copyGraph,
  downloadGraph,
} from '../../../utils/functions/visualization-actions';
import { splitStringIntoChunks } from '../../../utils/functions/visualization-utils';
import i18n from '../../../utils/i18n';
// Internal Dependencies
import { VisualizationChartRef } from '..';

type HorizontalHistogramProps = {
  question: AggregateQuestion;
  questionAnswers: AnswerDataWithDemographic[];
  maintainAspectRatio?: boolean;
  isExpanded?: boolean;
};

// This is the limit of characters for the label. If the labels get too long,
// the visualization can get messed up.
const HorizontalHistogram = forwardRef<
  VisualizationChartRef,
  HorizontalHistogramProps
>(
  (
    {
      question,
      questionAnswers,
      maintainAspectRatio = false,
      isExpanded = false,
    },
    ref
  ) => {
    const { DEFAULT_FILTER_GROUPS, breakoutDemographic } = useFilterProvider();

    const counts = convertToPercentageCountArray(
      questionAnswers,
      question.choices
    );
    const labelLimit = 20;
    const expandedLabelLimit = 50;
    const expandedLineLimit = 2;

    const chartRef: { current: Chart<'bar', number[], string> | null } =
      useRef(null);
    const chartContainer = useRef<HTMLCanvasElement>(null);

    useImperativeHandle(ref, () => ({
      onDownload: (name: string) => {
        downloadGraph(name, chartRef);
      },

      onCopyToClipboard: async () => {
        await copyGraph(chartRef);
      },
    }));

    const datasets = getBreakoutDemographicsDatasetsPercentages(
      breakoutDemographic,
      DEFAULT_FILTER_GROUPS,
      questionAnswers,
      question
    );

    useEffect(() => {
      if (chartContainer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chartRef.current = new Chart<'bar', number[], string>(
          chartContainer.current,
          {
            type: 'bar',

            data: {
              labels: counts.map((count) => count.label),
              datasets: breakoutDemographic
                ? datasets
                : [
                    {
                      data: counts.map((count) => count.value),
                      backgroundColor: CHART_COLORS_WITH_ALPHA[2],
                      borderColor: CHART_COLORS[2],
                      borderWidth: 2,
                    },
                  ],
            },
            options: {
              responsive: true,
              maintainAspectRatio,
              layout: {
                padding: {
                  right: 35,
                },
              },
              indexAxis: 'y',
              plugins: {
                legend: {
                  display: breakoutDemographic ? true : false,
                  position: 'bottom',
                  labels: {
                    boxWidth: Chart.defaults.font.size,
                  },
                },
                tooltip: {
                  enabled: true,
                  footerFont: {
                    size: 11,
                    weight: 'normal',
                  },
                  callbacks: {
                    label: (context) => {
                      const formattedValue = context.formattedValue;
                      if (formattedValue) {
                        return formattedValue + '%';
                      } else {
                        return formattedValue;
                      }
                    },
                    footer: (context) => {
                      return counts[context[0].dataIndex].notes;
                    },
                  },
                },
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                  formatter: (value) => {
                    if (value === 0) return null;
                    return value + '%';
                  },
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: i18n.t(
                      'visualization.horizontalHistogram.percentageAxisLabel'
                    ) as string,
                  },
                  min: 0,
                  grid: {
                    color: 'rgba(0, 0, 0, 0.05)',
                  },
                },
                y: {
                  ticks: {
                    callback: function (value) {
                      if (value || value === 0) {
                        const label: string = this.getLabelForValue(
                          parseInt(value as string)
                        );
                        return label.length > labelLimit
                          ? isExpanded
                            ? splitStringIntoChunks(
                                label,
                                expandedLabelLimit,
                                expandedLineLimit
                              )
                            : `${label.substring(0, labelLimit)}...`
                          : label;
                      }
                      return '';
                    },
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            },
            plugins: [...[ChartDataLabels]],
          }
        );
      }

      // Clean up the chart instance when the component unmounts
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counts]);

    return (
      <Center h={'100%'}>
        <canvas
          id={`chart-${Math.floor(Math.random() * 100)}`}
          ref={chartContainer}
        ></canvas>
      </Center>
    );
  }
);

HorizontalHistogram.displayName = 'HorizontalHistogram';

export default HorizontalHistogram;
