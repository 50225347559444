import React, { ChangeEvent, UIEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Heading,
  ListItem,
  Select,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import i18n from '../../../../utils/i18n';

export const ChatFAQ = ({
  handleScroll,
  hasReachedBottom,
  FAQPosition,
}: {
  handleScroll: (e: UIEvent) => void;
  hasReachedBottom: boolean;
  FAQPosition: number;
}) => {
  // Triggers a scroll to the previous FAQ position so the customers can go back where they were before
  useEffect(() => {
    if (FAQPosition) {
      const element = document.getElementById('engage-ai-chat-faq-content');
      if (element) {
        element.scrollTo({
          top: FAQPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [FAQPosition]);

  // Handle table of content logic
  const [sectionIdToFocusOn, setSectionIdToFocusOn] = useState<string>('');
  const handleNavigateToFAQTitle = (event: ChangeEvent<HTMLSelectElement>) => {
    setSectionIdToFocusOn(event.target.value);
  };
  useEffect(() => {
    if (sectionIdToFocusOn) {
      const element = document.getElementById(sectionIdToFocusOn);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [sectionIdToFocusOn]);

  // Access the FAQ content
  const { t } = useTranslation();
  const faqSections = t('engagementDetails.engageAi.faq.content', {
    returnObjects: true,
  });

  return (
    <Stack
      id={'engage-ai-chat-faq-content'}
      data-testid={'engage-ai-chat-faq-content'}
      overflowY={'scroll'}
      direction={'column'}
      h={'75vh'}
      p={'0px 36px'}
      m={0}
      sx={{
        WebkitMaskImage: ` ${
          hasReachedBottom
            ? ''
            : '-webkit-linear-gradient(top, rgba(0, 0, 0, 1) 70%, rgba(255, 253, 253, 0.5) 90%, rgba(255, 253, 253, 0.10) 100%)'
        }`,
      }}
      onScroll={(e) => handleScroll(e)}
    >
      <Select
        size="sm"
        onChange={(event) => handleNavigateToFAQTitle(event)}
        defaultValue={i18n.t('engagementDetails.engageAi.faq.table')}
      >
        {/* Instead of putting the value in 'placeholder', we use this trick to hide the placeholder in the dropdown */}
        <option
          value={i18n.t('engagementDetails.engageAi.faq.table')}
          disabled
          hidden
        >
          {i18n.t('engagementDetails.engageAi.faq.table')}
        </option>

        {faqSections.map((section) => (
          <option key={section.title} value={section.title}>
            {section.title}
          </option>
        ))}
      </Select>

      {faqSections.map((section) => (
        <Box
          key={section.title}
          p={'24px 0 16px 0'}
          borderBottom="0.5px solid #CBD5E0"
          m={0}
          id={section.title}
        >
          <Heading
            variant={'h3'}
            color="#1A242B"
            fontSize={'18px'}
            fontWeight={500}
            lineHeight={'24px'}
            key={section.title}
          >
            {section.title}
          </Heading>
          {/* In the lang file, the FAQ content is divided in 'shortText' and 'longText' sections. The latest follow this structure: intro lines/examples/conclusion */}
          {section.shortText ? (
            <Text pt={'8px'} lineHeight="24px" fontSize={'14px'}>
              {section.shortText}
            </Text>
          ) : (
            <>
              {section?.longText?.map((subsection, i) => (
                <Box m={0} key={i}>
                  {subsection?.intro.map((introPart: string, i) => (
                    <Text
                      key={i}
                      pt={'16px'}
                      lineHeight="24px"
                      fontSize={'14px'}
                    >
                      {introPart}
                    </Text>
                  ))}
                  {subsection?.examples.length > 0 ? (
                    <UnorderedList p={'16px 0'} fontSize={'14px'}>
                      {subsection.examples.map((example, index) => (
                        <ListItem key={index}>{example.toString()}</ListItem>
                      ))}
                    </UnorderedList>
                  ) : (
                    <></>
                  )}
                  {subsection?.conclu?.map((concluPart: string, i) => (
                    <Text
                      key={i}
                      pt={'16px'}
                      lineHeight="24px"
                      fontSize={'14px'}
                    >
                      {concluPart}
                    </Text>
                  ))}
                </Box>
              ))}
            </>
          )}
        </Box>
      ))}
    </Stack>
  );
};
