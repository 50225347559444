// External Dependencies
import React, { RefObject, UIEvent, useEffect, useRef, useState } from 'react';
import { Box, Collapse, Flex, Slide } from '@chakra-ui/react';
import { MessageDto } from '@keyops-cep/api-client';

import { useEngageAI } from '../../../../hooks/useEngageAI';
import { ANALYTICS_EVENT_NAME } from '../../../../utils/constants/analytics-constants';
import { Role } from '../../../../utils/constants/engage-ai';
import { EngagementStatusValues } from '../../../../utils/dto/engagement.dto';
import { copyEngageAIConversation } from '../../../../utils/functions/engage-ai.utils';

import ChatButton from './ChatButton';
import { ChatFAQ } from './ChatFAQ';
import { ChatHeader } from './ChatHeader';
import { ChatHeaderFAQ } from './ChatHeaderFAQ';
import { ChatInput } from './ChatInput';
import { ChatMessages } from './ChatMessages';

export type ChatProps = {
  engagementId: string;
  engagementStatus: EngagementStatusValues;
  isEngageAIActive: boolean;
  isChatOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  chatRef: RefObject<HTMLDivElement>;
};

const Chat = ({
  engagementId,
  isEngageAIActive,
  engagementStatus,
  isChatOpen,
  onOpen,
  onClose,
  chatRef,
}: ChatProps) => {
  const {
    messages,
    hasInteractedYet,
    isSendingMessage,
    isCheckingStatus,
    errorMessage,
    startChatSession,
    sendEngageAIMessage,
  } = useEngageAI({
    engagementId,
  });

  // startChatSession if the tenant flag is enable, and with the right enggt status
  useEffect(() => {
    if (
      engagementId &&
      engagementStatus === 'Insights available' &&
      isEngageAIActive
    ) {
      startChatSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementId, engagementStatus, isEngageAIActive]);

  // To focus on the last message when the chat opens
  const chatMessagesRef = useRef<{ focusLastMessage: () => void }>(null);
  const handleOpenChat = () => {
    onOpen();
    if (chatMessagesRef.current) {
      chatMessagesRef.current.focusLastMessage();
    }
    window.analytics.track(ANALYTICS_EVENT_NAME.ENGAGE_AI.OPEN_CHAT, {
      engagementId: engagementId,
    });
  };
  useEffect(() => {
    // Ensure chatMessagesRef is available and the chat is open
    if (chatMessagesRef.current && isChatOpen) {
      chatMessagesRef.current.focusLastMessage();
    }
  }, [isChatOpen, chatMessagesRef]);

  // Copy conversation feature
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const handleCopyConversation = () => {
    copyEngageAIConversation(messages, setIsCopied);
  };

  // handleSendMessage sends message to ChatGPT while preventing to spam messages
  const [currentMessage, setCurrentMessage] = useState<MessageDto>({
    role: Role.user,
    content: '',
    createdAt: Date.now().toLocaleString(),
  });
  const handleSendMessage = () => {
    // Prevent new message
    if (
      isSendingMessage ||
      isCheckingStatus ||
      currentMessage.content.trim() === ''
    ) {
      return;
    }
    sendEngageAIMessage(currentMessage);
    // Empty the current message now it's sent
    setCurrentMessage({
      role: Role.user,
      content: '',
      createdAt: Date.now().toLocaleString(),
    });
    window.analytics.track(ANALYTICS_EVENT_NAME.ENGAGE_AI.SEND_MESSAGE, {
      engagementId: engagementId,
      message: currentMessage,
    });
  };

  // Handle FAQ logic
  const [isFAQOpen, setIsFAQOpen] = useState<boolean>(false);
  const [FAQPosition, setFAQPosition] = useState<number>(0);
  const handleOpenFAQ = () => {
    setIsFAQOpen(true);

    window.analytics.track(ANALYTICS_EVENT_NAME.ENGAGE_AI.OPEN_FAQ, {
      engagementId: engagementId,
    });
  };
  const handleReturnToMessages = () => {
    setIsFAQOpen(false);
    setFAQPosition(scrollPosition);
  };

  //  Handle the scroll logic in FAQ (determining whether the blur gradient and the table of contents should be displayed on the FAQ)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const handleScroll = (event: UIEvent) => {
    // To know whether the customer reaches the end of FAQ (not an exact match, hence the marginOfError)
    const targetValue = event.currentTarget.clientHeight;
    const calculatedValue =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop;
    const marginOfError = 10;

    if (Math.abs(targetValue - calculatedValue) <= marginOfError) {
      setHasReachedBottom(true);
    } else {
      setHasReachedBottom(false);
    }

    setScrollPosition(event.currentTarget.scrollTop);
  };

  return (
    <>
      <ChatButton
        openChat={handleOpenChat}
        closeChat={onClose}
        isChatOpen={isChatOpen}
        hasInteractedYet={hasInteractedYet}
      />
      <Collapse in={isChatOpen} animateOpacity>
        <Box
          ref={chatRef}
          id="engage-ai-drawer"
          data-testid="engage-ai-drawer"
          bg={'white'}
          display={isChatOpen ? 'block' : 'none'}
          w={`${isFAQOpen ? '40vw' : '30vw'} `}
          pos="fixed"
          h={`${isFAQOpen ? '80vh' : '75vh'} `}
          transition={'all 0.2s ease'}
          borderRadius="8px 8px 0px 0px"
          position={'fixed'}
          bottom={{
            base: '18vh',
            sm: '18vh',
            md: '18vh',
            lg: '18vh',
            xl: '16vh',
            '2xl': '13vh',
          }}
          right={'24px'}
          zIndex={'toast'}
          filter={
            'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10));'
          }
        >
          {isFAQOpen ? (
            <Slide
              direction="right"
              in={isFAQOpen === true}
              style={{ zIndex: 10 }}
            >
              <Flex h={'100%'} direction={'column'}>
                <ChatHeaderFAQ
                  handleReturnToMessages={handleReturnToMessages}
                  scrollPosition={scrollPosition}
                />
                <ChatFAQ
                  handleScroll={handleScroll}
                  hasReachedBottom={hasReachedBottom}
                  FAQPosition={FAQPosition}
                />
              </Flex>
            </Slide>
          ) : (
            <Slide
              direction="right"
              in={isFAQOpen === false}
              style={{ zIndex: 10 }}
            >
              <Flex h={'100%'} direction={'column'}>
                <ChatHeader
                  copyConversation={handleCopyConversation}
                  hasInteractedYet={hasInteractedYet}
                  isCopied={isCopied}
                  openFAQ={handleOpenFAQ}
                />
                <ChatMessages
                  messages={messages}
                  errorMessage={errorMessage}
                  ref={chatMessagesRef}
                />
                <ChatInput
                  currentMessage={currentMessage}
                  setCurrentMessage={setCurrentMessage}
                  handleSendMessage={handleSendMessage}
                />
              </Flex>
            </Slide>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default Chat;
