import { useEffect, useMemo, useState } from 'react';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';

import { ANALYTICS_EVENT_NAME } from '../../../../utils/constants/analytics-constants';
import { getUrl } from '../../../../utils/functions/common-utils';
import i18n from '../../../../utils/i18n';
import {
  ENGAGEMENT_COMPARATIVE_ANALYSIS_OVERVIEW,
  ENGAGEMENT_COMPARATIVE_ANALYSIS_QUESTION_COMPARISON,
  ENGAGEMENT_COMPARATIVE_ANALYSIS_TAB,
  ENGAGEMENT_DETAILS,
} from '../../../../utils/internal-routes.const';

const ComparisonTabs = () => {
  const { comparableEngagementsId } = useParams();
  const navigate = useNavigate();
  const match = useMatch(
    `${ENGAGEMENT_DETAILS}/${ENGAGEMENT_COMPARATIVE_ANALYSIS_TAB}/*`
  );

  const [tabIndex, setTabIndex] = useState(0);
  const tabs = useMemo(
    () => [
      {
        label: i18n.t('engagementDetails.comparativeAnalysis.tabs.overview'),
        route: getUrl(ENGAGEMENT_COMPARATIVE_ANALYSIS_OVERVIEW, {
          comparableEngagementsId: comparableEngagementsId ?? '',
        }),
      },
      {
        label: i18n.t(
          'engagementDetails.comparativeAnalysis.tabs.questionComparison'
        ),
        route: getUrl(ENGAGEMENT_COMPARATIVE_ANALYSIS_QUESTION_COMPARISON, {
          comparableEngagementsId: comparableEngagementsId ?? '',
        }),
      },
    ],
    [comparableEngagementsId]
  );

  useEffect(() => {
    const pathname = match?.params['*'];
    const activeTabIndex =
      tabs.findIndex((tab) => pathname?.includes(tab.route)) || 0;
    setTabIndex(activeTabIndex);
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGEMENTS_COMPARISON
        .TOGGLE_COMPARATIVE_ANALYSIS_TAB,
      {
        engagementId: tabs[activeTabIndex].route.split('/')[0],
        tab: tabs[activeTabIndex].label,
      }
    );
  }, [match, tabs]);

  const onChange = (index: number) => {
    const route =
      tabs[index]?.route || ENGAGEMENT_COMPARATIVE_ANALYSIS_OVERVIEW;
    navigate(
      getUrl(route, {
        comparableEngagementsId: comparableEngagementsId ?? '',
      })
    );
    setTabIndex(index);
  };

  return (
    <Tabs
      variant={'unstyled'}
      isLazy
      index={tabIndex}
      onChange={onChange}
      mt={4}
    >
      <TabList
        display={'inline-flex'}
        px={3}
        py={1.5}
        gap={1.5}
        border={'2px'}
        borderColor={'gray.100'}
        borderRadius={'lg'}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            display={'inline'}
            borderRadius={'lg'}
            _selected={{
              color: 'keyops.blue',
              bg: 'gray.100',
              fontWeight: 600,
            }}
            _hover={{ bg: 'gray.100' }}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <Box my={6}>
        <Outlet />
      </Box>
    </Tabs>
  );
};

export default ComparisonTabs;
