// External Dependencies
import React, {
  ForwardedRef,
  forwardRef,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  SubTitle,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';

import { VisualizationChartRef } from '..';

// Internal Dependencies
Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

export type ChartJSProps = {
  type: string;
  visualizationRef?: RefObject<HTMLElement>;
};

export type ChartJSProps1 = {
  type: string;
};

const ChartJS = forwardRef<VisualizationChartRef, ChartJSProps1>(
  (props, ref: ForwardedRef<VisualizationChartRef>) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    let chartInstance: Chart<'bar', number[]> | null = null;

    useEffect(() => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chartInstance = new Chart(chartRef.current, {
          type: 'bar',
          data: {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
              {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                borderWidth: 1,
              },
            ],
          },
        });
      }

      // Clean up the chart instance when the component unmounts
      return () => {
        if (chartInstance) {
          chartInstance.destroy();
        }
      };
    }, []);

    const dlChartAsImage = () => {
      const strWindowFeatures =
        'location=yes,height=570,width=520,scrollbars=yes,status=yes';
      const URL = chartRef.current?.toDataURL('image/png', 1);
      window.open(URL, '_blank', strWindowFeatures);
    };

    React.useImperativeHandle(ref, () => {
      return {
        onDownload: dlChartAsImage,
        onCopyToClipboard: () => 'not implemented',
      };
    });

    return (
      <>
        <canvas ref={chartRef}></canvas>
      </>
    );
  }
);

ChartJS.displayName = 'ChartJS';
export default ChartJS;
