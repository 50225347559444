import { getUserProfile } from '../../api/user.functions';

export const identifyUser = async () => {
  const userProfileData = await getUserProfile();
  window.analytics.identify(userProfileData.id, {
    name: userProfileData.displayName,
    email: userProfileData.email,
    tenantId: userProfileData.tenantId,
  });
  if (userProfileData.tenantId) {
    window.analytics.group(userProfileData.tenantId, {
      name: userProfileData.tenant?.name,
    });
  }
};
